import useGetByExplorer from './useGetByExplorer'

const useActivity = () => {
    const {getByExplorer, data: getByExplorerData, loading: getByExplorerLoading, error: getByExplorerError} = useGetByExplorer()

    return {
        getByExplorer,
        getByExplorerData,
        getByExplorerLoading,
        getByExplorerError
    }
}

export default useActivity