import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'

const EditableSpan = ({
    onChange = () => {},
    onClick = () => {},
    onOutsideClick = () => {},
    choose = () => {},
    type = 'input',
    placeholder = '',
    error = '',
    suggestions = [],
    style = {},
    dropdDown = false,
    bordered = true,
    value: v
}) => {
    const search = useRef(null)
    const [focused, setFocused] = useState(false)
    const [value, setValue] = useState('')

    const click = () => {
        setFocused(true)
        onClick()
    }

    const blur = (e) => {
        if (!e.currentTarget.textContent && v) {
            search.current.innerText = v
            return
        }
        setFocused(false)
    }

    useEffect(() => {
        if (v && !focused && search.current) {
            search.current.innerText = v
        }
    }, [v, search, focused])

    return (
        <>
            <span
                ref={search}
                data-type={type}
                data-bordered={bordered}
                onBlur={blur}
                className={styles.search}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onInput={(e) => onChange(e.currentTarget.textContent)}
                onClick={click}>
                    {!focused && placeholder}
            </span>
            {error && !focused && (
                <span className={styles.error}>
                    {error}
                </span>
            )}
        </>
    )
}

const editableSpanPropsAreEqual = (prevProps, nextProps) => {
    return prevProps.value === nextProps.value &&
        prevProps.clear === nextProps.clear &&
        prevProps.error === nextProps.error &&
        prevProps.suggestions === nextProps.suggestions
}

export const MemoizedEditableSpan = React.memo(EditableSpan, editableSpanPropsAreEqual)