import React, { useState, useEffect } from 'react'
import { Input, Icon } from '../../../components/theme'
import styles from './index.module.scss'

const Hashtags = ({
    activity,
    onChange
}) => {
    const [hashtag, setHashtag] = useState(null)
    const [hashtags, setHashtags] = useState([])
    const [ac, setAc] = useState({})

    const update = (info = null) => {
        const data = {}

        if (info.hashtags !== undefined) data.tags = info.hashtags

        onChange(data)
    }

    const toggleHashtag = (string = '', add = 1, removeHashtag = '') => {
        let newHashtags = [...hashtags]
        if (add > 0 && string.length > 0) {
            newHashtags.unshift(string)
        } else {
            newHashtags = newHashtags.filter(t => t !== removeHashtag)
        }
        setHashtag('')
        setHashtags([...newHashtags])
        update({hashtags: newHashtags})
    }

    const tagChange = (e) => {
        const re = /^[0-9a-zA-Z]+$/
        if (re.test(e.target.value) || e.target.value.length === 0) setHashtag(e.target.value)
    }

    useEffect(() => {
        if (hashtags.length === 0 || ac.name !== activity.name) setHashtags(activity.tags ? activity.tags : [])
        setAc(activity)
    }, [activity])

    return (
        <div className={styles.hashtags}>
            {hashtags && hashtags.map((tag, i) => (
                <div key={`${tag}-${i}`}>
                    <span>
                        {tag}
                    </span>
                    <Icon
                        type="close"
                        width={10}
                        height={10}
                        onClick={() => toggleHashtag('', -1, tag)}/>
                </div>
            ))}
            <div>
                <Input
                    value={hashtag}
                    placeholder="Add your hashtags here"
                    clearPlaceholder={true}
                    onChange={tagChange}
                    onKeyUp={(e) => { if (e.keyCode === 13) toggleHashtag(e.target.value) }}/>
                {hashtag && (
                    <Icon
                        type="plus"
                        width={10}
                        height={10}
                        onClick={() => toggleHashtag(hashtag, 1)}/>
                )}
            </div>
        </div>
    )
}

export default Hashtags