import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import Card from '../../Card'
import { useActivity } from '../../../hooks'
import styles from './index.module.scss'

const ActivitiesSection = ({
    explorer,
    guest,
    share = () => {},
}) => {
    const {getByExplorer, getByExplorerData} = useActivity()
    const [activities, setActivities] = useState({all: [], limit: 3})

  const remove = (id) => setActivities({ ...activities, all: activities.all.filter(d => d._id !== id) })

  useEffect(() => {
    if (explorer._id) getByExplorer({ explorerId: explorer._id, sortDirection: -1 })
  }, [explorer])

  useEffect(() => {
    if (!getByExplorerData) return
    setActivities({ ...activities, all: getByExplorerData.data.activities })
  }, [getByExplorerData])

  return (
    <>
      <div className={styles.activitiesSectionHeading}>
        <h3 className="sm">
          {guest ? `${explorer.full_name}’s` : 'Your'} experiences
        </h3>

        <span className={`lg ${styles.count}`}>
          ({activities.all.length} experiences)
        </span>
      </div>

      <div className="mb-5">
        <div className={styles.scrollerParent}>
          <InfiniteScroll
            pageStart={0}
            className="row"
            loadMore={() => setActivities({ ...activities, limit: activities.limit + 4 })}
            hasMore={activities.limit < activities.all.length}
            useWindow={true}
          >
            <div
              style={{ marginBottom: 30 }}
              className="col-md-6"
            >
              <Card
                create={true}
                type={'activity'}
              />
            </div>

            {activities.all.slice(0, activities.limit).map(((activity, index) => (
              <div
                className="col-md-6"
                style={{ marginBottom: 30 }}
                key={`card-${index}`}
              >
                <Card
                  data={activity}
                  share={share}
                  type="activity"
                  remove={remove}
                  imageFromCloudinary={false}
                />
              </div>
            )))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  )
}

export default ActivitiesSection