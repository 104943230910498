import useByEmail from './useByEmail'
import useUpdate from './useUpdate'
import useVerify from './useVerify'

const useExplorer = () => {
    const {getByEmail, loading: getByEmailLoading, error: getByEmailError, data: getByEmailData} = useByEmail()
    const {update, loading: updateLoading, error: updateError, data: updateData} = useUpdate()
    const {verify, loading: verifyLoading, error: verifyError, data: verifyData} = useVerify()

    return {
        getByEmailLoading,
        getByEmailError,
        getByEmail,
        getByEmailData,

        updateLoading,
        updateError,
        update,
        updateData,

        verifyLoading,
        verifyError,
        verify,
        verifyData,
    }
}

export default useExplorer