import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withAlert  } from '../../hocs'
import { useAuth, useNavigate } from '../../hooks'
import { Button, Icon } from '../../components/theme'
import EmailSection from './EmailSection'
import PasswordSection from './PasswordSection'
import http from '../../http'
import { SIGN_UP } from '../../content'
import styles from './index.module.scss'

const SignUp = ({
    nextRoute,
    explorer = {},
    Alert
}) => {
    const {user, login} = useAuth()
    const [info, setInfo] = useState({})
    const navigate = useNavigate()
    const history = useHistory()

    const submit = async (data) => {
        try {
            await http.post('/api/register', {...data, ...explorer, ...info})
            Alert({title: 'Please check your email'})
            setTimeout(() => {
                if (nextRoute) {
                    navigate(nextRoute)
                    return
                }
                navigate('/')
            }, 4000)
        } catch (e) {
            if (e.response && e.response.data.message) {
                alert(e.response.data.message)
                return
            }
            Alert({title: 'Network Error'})
        }
    }

    if (user._id) {
        navigate(`/e/${user._id}`)
    }

    return (
        <section className={styles.section}>
            <Helmet>
                <title>Curator | RAD | Sign Up</title>
            </Helmet>
            <Button
                bordered={false}
                icon={true}
                height="4.33rem"
                width="4.33rem"
                onClick={() => history.goBack()}>
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className={`col-lg-6 ${styles.textInfo}`}>
                        <div>
                            <h1 className="mb-1 mt-0">{SIGN_UP.title}</h1>
                            <p className="margin-0">{SIGN_UP.description}</p>
                        </div>
                    </div>
                    <div className="col-lg-5 text-center">
                        <div className={styles.card}>    
                            {!info.email ? (
                                <EmailSection
                                    login={login}
                                    onChange={setInfo}/>
                            ) : (
                                <PasswordSection
                                    submit={submit}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withAlert(SignUp)