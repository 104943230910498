export default {
    production: {
        baseUrl: 'https://api.curator.rad.travel',
        JWTDecode: '^532KGa#!%#sfUCsD}FdeV,fgEU/bu',
        googleApiKey: 'AIzaSyCrhYTLNm1uNCtleV6KeL-nqUivoLC7clo',
        aws: {
            region: 'us-east-1',
            identityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5',
            s3: {
                apiVersion: '2006-03-01',
                bucket: 'rad-public'
            }
        }
    },
    staging: {
        baseUrl: 'https://api.curator.stage.rad.travel',
        JWTDecode: '^532KGa#!%#sfUCsD}FdeV,fgEU/bu',
        googleApiKey: 'AIzaSyCrhYTLNm1uNCtleV6KeL-nqUivoLC7clo',
        aws: {
            region: 'us-east-1',
            identityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5',
            s3: {
                apiVersion: '2006-03-01',
                bucket: 'rad-public'
            }
        }
    },
    development: {
        baseUrl: 'http://localhost:4006',
        JWTDecode: '^532KGa#!%#sfUCsD}FdeV,fgEU/bu',
        googleApiKey: 'AIzaSyCrhYTLNm1uNCtleV6KeL-nqUivoLC7clo',
        aws: {
            region: 'us-east-1',
            identityPoolId: 'us-east-1:7051260e-f680-4cbd-b571-11346bfea1d5',
            s3: {
                apiVersion: '2006-03-01',
                bucket: 'rad-public'
            }
        }
    },
}[process.env.REACT_APP_ENV]