import React from 'react'
import { Helmet } from 'react-helmet'
import { useAuth, useNavigate } from '../../hooks'
import Nav from '../../components/Nav'
import Header from './Header'
import HowItWorks from './HowItWorks'
import Clients from './Clients'
import OurBeliefs from './OurBeliefs'
import Impact from './Impact'
import Footer from './Footer'

const Home = ({
    toggleMenu
}) => {
    const {user} = useAuth()
    const navigate = useNavigate()

    if (user._id) {
        navigate(`/e/${user.email}`)
    }
    return (
        <main>
            <Helmet>
                <title>Curator | RAD | Welcome</title>
            </Helmet>
            <Nav toggleMenu={toggleMenu}/>
            <Header/>
            <HowItWorks/>
            <Clients/>
            <OurBeliefs/>
            {/* <GuidesExplorers/> */}
            <Impact/>
            <Footer/>
        </main>
    )
}

export default Home