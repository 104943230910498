import React from 'react'
import styles from './index.module.scss'

const Loading = ({
    status,
    style
}) => {
    return (
        <div
            className={styles.transition}
            data-status={status}
            style={style}
        />
    )
}

export default Loading