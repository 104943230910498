import React from 'react'
import { Icon } from '../theme'
import styles from './index.module.scss'

const Loading = ({
    style
}) => {
    return (
        <div
            className={styles.loading}
            style={style}>
                <Icon
                    type="loader"
                />
        </div>
    )
}

export default Loading