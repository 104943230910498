import React, { useState } from 'react'
import Swiper from 'react-id-swiper'
import Loader from '../Loader'
import { Button } from '../theme'
import { useWindowSize } from '../../hooks'
import 'swiper/css/swiper.css'
import styles from './index.module.scss'

const ClientsSlider = () => {
    const [swiper, setSwiper] = useState(null)
    const [nextProgress, setNextProgress] = useState(0)
    const [prevProgress, setPrevProgress] = useState(100)
    const { width } = useWindowSize()

    const params = {
        slidesPerView: width > 720 ? 6 : 2.5,
        wrapperClass: styles.swiperWrapper
    }

    const images = [
        '/images/clients/singapore.png',
        '/images/clients/proper.png',
        '/images/clients/easyjet.png',
        '/images/clients/panasonic.png',
        '/images/clients/intelity.png',
        '/images/clients/cheapqair.png',
        '/images/clients/hawaiian.png'
    ]

    const goNext = () => {
        if (swiper !== null) {
            const realIndex = swiper.realIndex + 1
            const nextImagesCount = images.length - 6
            const oneSector = 100 / nextImagesCount

            if (nextImagesCount - realIndex > 0) {
                setNextProgress(100 - (oneSector * (nextImagesCount - realIndex)))
                setPrevProgress(prevProgress - oneSector)
            } else if (nextImagesCount - realIndex === 0) {
                setPrevProgress(0)
                setNextProgress(100)
            }

            swiper.slideNext()
        }
    }
     
    const goPrev = () => {
        const realIndex = swiper.realIndex - 1
        const nextImagesCount = images.length - 6
        const oneSector = 100 / nextImagesCount

        if (nextImagesCount - realIndex > 0 && realIndex > 0) {
            setPrevProgress(oneSector * (nextImagesCount - realIndex))
            setNextProgress(nextProgress - oneSector)
        } else if (nextImagesCount - realIndex === nextImagesCount) {
            setNextProgress(0)
            setPrevProgress(100)
        }
        swiper.slidePrev()
    }

    return (
        <div className="position-relative">
            {width && (
                <Swiper {...params} getSwiper={setSwiper}>
                    {images.map((image, i) => (
                        <div
                            key={`partner-logo-${image}-${i}`}
                            className={`${styles.slide}`}>
                                <img
                                    key={`client-logo-${image}`}
                                    src={image}
                                    alt="Partner"/>
                        </div>
                    ))}
                </Swiper>
            )}
            {swiper && ([
                <Button
                    key={`swiper-navigation-0`}
                    className={styles.navigation}
                    bordered={false}
                    type="secondary"
                    icon={true}
                    circle={false}
                    onClick={goPrev}>
                        <Loader
                            progress={prevProgress}/>
                </Button>,
                <Button
                    key={`swiper-navigation-1`}
                    className={styles.navigation}
                    bordered={false}
                    type="secondary"
                    icon={true}
                    circle={false}
                    onClick={goNext}>
                        <Loader
                            progress={nextProgress}/>
                </Button>
            ])}
        </div>
    )
}

export default ClientsSlider