import React, { useRef } from 'react'
import http from '../http'

const withPlace = Component => ({
    handleErrors,
    ...props
}) => {
    const throttling = useRef()
    const urlSearchParams = new URLSearchParams(window.location.search)

    const placeChange = (value, city = true, data = {}) => {
        return new Promise ((resolve, reject) => {
            if (throttling.current) {
                clearTimeout(throttling.current)
                throttling.current = null
            }
            throttling.current = setTimeout(() => {
                if (!value) {
                    return resolve([])
                }
                const params = {
                    query: value,
                    limit: 100,
                    radius: 10000,
                }
                if (data.loc && data.loc.coordinates) params.location = `${data.loc.coordinates[1]},${data.loc.coordinates[0]}`
                if (data.city && data.city.location) params.location = `${data.city.location[1]},${data.city.location[0]}`
                params.types = city ? '(cities)' : 'establishment'
                if (params.location) params.strictbounds = '|'
                http
                    .get('/api/places', {params})
                    .then(response => resolve(response.data.suggestions))
                    .catch(e => reject(e))
            }, 500)
        })
    }

    const choosePlace = (data) => {
        return new Promise (async (resolve, reject) => {
            try {
                const response = await http.get('/api/place', {
                    params: {
                        id: data.id,
                        type: data.type
                    }
                })
    
                const place = {}
    
                response.data.place.address_components.forEach(address => {
                    if (address.types.indexOf("locality") > -1) {
                        place.name = address.long_name;
                    }
                    if (address.types.indexOf("sublocality") > -1) {
                        place.name = address.long_name;
                    }
                    if (address.types.indexOf("country") > -1) {
                        place.country = address.long_name;
                        place.country_short = address.short_name;
                    }
                    if (address.types.indexOf("administrative_area_level_1") > -1) {
                        place.state = address.long_name;
                        place.state_short = address.short_name;
                    }
                })
    
                place.location = [response.data.place.geometry.location.lng, response.data.place.geometry.location.lat]
                const cityResponse = await http.post('/api/city', place)
                return resolve({
                    placeName: response.data.place.name,
                    loc: {
                        type: 'Point',
                        coordinates: place.location
                    },
                    _city: cityResponse.data.city._id,
                    city: {
                        country_short: cityResponse.data.city.country_short,
                        country: cityResponse.data.city.country,
                        state_short: cityResponse.data.city.state_short,
                        state: cityResponse.data.city.state,
                        name: cityResponse.data.city.name,
                        location: place.location
                    }
                })
            } catch (e) {
                return reject(e)
            }
        })
    }

    return (
        <Component
            {...props}
            choosePlace={choosePlace}
            placeChange={placeChange}/>
    )
}

export default withPlace