import React, { useEffect, useState } from 'react'
import http from '../../../http'
import Card from '../../Card'
import { Button } from '../../theme'
import { useGuide } from '../../../hooks'
import styles from './index.module.scss'

const GuidesSection = ({
    explorer,
    guest,
    share = () => {},
}) => {
    const {getByExplorer, getByExplorerData} = useGuide()
    const [guides, setGuides] = useState({all: [], limit: 3})
    const remove = id => setGuides({...guides, all: guides.all.filter(d => d._id !== id)})

    useEffect(() => {
        if (explorer._id) getByExplorer({explorerId: explorer._id, sortDirection: -1})
    }, [explorer])

    useEffect(() => {
        if (!getByExplorerData) return
        setGuides({...guides, all: getByExplorerData.data.guides})
    }, [getByExplorerData])

    return (
        <>
            <div className={styles.guidesSectionHeading}>
                <h3 className="sm">
                    {guest ? `${explorer.full_name}’s` : 'Your'} guides
                </h3>
                <span className={`lg ${styles.count}`}>({guides.all.length} guides)</span>
            </div>
            <div className="mb-5">
                <div className="row">
                    <div
                        style={{ marginBottom: 30 }}
                        className="col-md-6">
                            <Card
                                create={true}
                                type="guide"
                            />
                    </div>
                    {guides.all.slice(0, guides.limit).map(((guide, index) => (
                        <div
                            className="col-md-6"
                            style={{ marginBottom: 30 }}
                            key={`card-${index}`}>
                                <Card
                                    data={guide}
                                    share={share}
                                    type={'guide'}
                                    remove={remove}
                                    imageFromCloudinary={false}
                                />
                        </div>
                    )))}
                </div>
                {guides.limit < guides.all.length && (
                    <div className="d-flex justify-content-center">
                        <Button
                            type="secondary"
                            width="9rem"
                            onClick={() => setGuides({...guides, limit: guides.limit + 4})}>
                                Load More
                        </Button>
                    </div>
                )}
            </div>
        </>
    )
}

export default GuidesSection