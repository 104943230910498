import React, { useEffect, useRef } from 'react'
import { Button, Icon } from '../theme'
import Modal from '../Modal'
import styles from './index.module.scss'

const Alert = ({
    title = '',
    description = '',
    close = () => {},
    confirmation,
    onSuccess = () => {},
    onCancel = () => {},
}) => {
    const alertBox = useRef(null)

    const alertOutsideClick = (e) => {
        if (e.target === alertBox.current || e.target === document.querySelector('h2') || alertBox.current.contains(e.target)) {
            return
        }

        setTimeout(() => {
            close()
            onCancel()
        }, 100)
    }

    useEffect(() => {
        const body = document.body
        body.style.overflowY = 'hidden'
        window.addEventListener('click', alertOutsideClick)
        window.addEventListener('touchend', alertOutsideClick)
        
        return () => {
            window.removeEventListener('click', alertOutsideClick)
            window.removeEventListener('touchend', alertOutsideClick)
            body.style.overflowY = ''
        }
    }, [])
    
    return (
        <Modal>
            <div className={styles.overlay}>
                <div
                    ref={alertBox}
                    className={`${styles.alertBox} fadeIn`}>
                        <Button
                            type="transparent"
                            width={50}
                            icon={true}
                            height={50}
                            hoverEffect={false}
                            onClick={() => {
                                close()
                                onCancel()
                            }}>
                                <Icon
                                    type="close-dark"
                                    width={25}
                                />
                        </Button>
                        <span>{title}</span>
                        {description && <p>{description}</p>}
                        {confirmation && (
                            <div className={styles.footerButtons}>
                                <Button
                                    type="primary"
                                    hoverEffect={false}
                                    width="8rem"
                                    bordered={false}
                                    onClick={onCancel}>
                                        Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    hoverEffect={false}
                                    className="ml-2"
                                    width="8rem"
                                    bordered={false}
                                    onClick={onSuccess}>
                                        Submit
                                </Button>
                            </div>
                        )}
                </div>
            </div>
        </Modal>
    )
}

export default Alert