import React from 'react'
import { useAuth } from '../../../hooks'
import Link from '../../../components/Link'
import ClientsSlider from '../../../components/ClientsSlider'
import { Button } from '../../../components/theme'
import styles from './index.module.scss'

const Clients = () => {
    const {user} = useAuth()
    return (
        <section className={styles.about}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 p-0">
                        <h2>
                            Create great guides,
                            <br/>
                            Get big exposure
                        </h2>
                    </div>
                </div>
                <div
                    className="row justify-content-center position-relative align-items-center"
                    style={{ marginBottom: '3.9rem' }}>
                        <div className="col-md-10">
                            <ClientsSlider/>
                        </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <p>
                            If your content is selected to feature on our platform for our airline and
                            hospitality partners, you will earn a commission. The more cities you
                            contribute content to, the more likely your content will be licensed to
                            airlines both domestic and international—giving you the opportunity to
                            organically build your personal brand.
                        </p>
                    </div>
                </div>
                <Link href={`/${user.email ? '#' : 'sign-up'}`}>
                    <Button
                        type="primary"
                        borderDark={true}
                        className={styles.join}>
                            {user.email ? (
                                'Create a guide'
                            ) : (
                                'Join our tribe'
                            )}
                    </Button>
                </Link>
            </div>
        </section>
    )
}

export default Clients