import React, { useState, useRef, useEffect } from 'react'
import { Button, Icon } from '../../../components/theme'
import { CATEGORIES_NAMES } from '../../../constants'
import styles from './index.module.scss'

const CategoryModal = ({
    activeCategoryIndex = 0,
    setActiveCategoryIndex = () => {}
}) => {
    const categorySection = useRef(null)
    const [modalVisible, toggleModal] = useState(false)

    const categorySectionOutsideClick = (e) => {
        if (
            e.target === categorySection.current ||
            e.target === document.querySelector('h2') ||
            categorySection.current.contains(e.target)
        ) {
            return
        }

        setTimeout(() => {
            toggleModal(false)
        }, 100)
    }

    useEffect(() => {
        window.addEventListener('click', categorySectionOutsideClick)
        window.addEventListener('touchend', categorySectionOutsideClick)

        return () => {
            window.removeEventListener('click', categorySectionOutsideClick)
            window.removeEventListener('touchend', categorySectionOutsideClick)
        }
    }, [])

    return (
        <div className={styles.heading}>
            <label>
                Category
            </label>
            <div
                ref={categorySection}
                data-changable={modalVisible}
                onClick={() => toggleModal(!modalVisible)}>
                    <span>
                        {CATEGORIES_NAMES[activeCategoryIndex]}
                    </span>
                    <Button
                        type={`${modalVisible ? 'primary' : 'secondary'}`}
                        icon={true}
                        width={50}
                        height={50}
                        innerStyle={{ padding: '0 15px' }}>
                            <Icon
                                type="arrow-bottom"
                                width="15"
                                height="18"
                            />
                    </Button>
                    {modalVisible && (
                        <div className={styles.categoryDropDown}>
                            {CATEGORIES_NAMES.map((category, i) => (
                                <div key={`select-category-${i}-${category}`}>
                                    <span
                                        data-active={i === activeCategoryIndex}
                                        onClick={() => {
                                            setActiveCategoryIndex(i)
                                            toggleModal(false)
                                        }}>
                                            {category}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default CategoryModal