import React from 'react'
import Link from '../Link'
import { Button, Icon } from '../theme'
import { useAuth, useOrganization } from '../../hooks'
import styles from './index.module.scss'

const Nav = ({
    toggleMenu
}) => {
    const {user} = useAuth()
    const {organization} = useOrganization()
    return (
        <nav className={`container-fluid ${styles.nav}`}>
            <div className="row justify-content-between">
                <div className="col">
                    <Link href={`${user._id ? `/e/${user.email}` : '/'}`}>
                        <img
                            className={styles.logo}
                            src="/logo.svg"
                            alt="Logo"/>
                    </Link>
                </div>
                {!organization && (
                    <div className={`col ${styles.linksSection}`}>
                        {!user.email && ([
                            <Link
                                href="/sign-up"
                                key={0}>
                                    <Button
                                        type="secondary"
                                        className="mr-2"
                                        innerStyle={{ padding: '0 2rem' }}>
                                            Join Our Tribe
                                    </Button>
                            </Link>,
                            <Link
                                href="/sign-in"
                                key={1}>
                                    <Button
                                        type="primary"
                                        className="mr-2"
                                        innerStyle={{ padding: '0 2rem' }}>
                                            Sign In
                                    </Button>
                            </Link>
                        ])}
                        <Button
                            type="secondary"
                            circle={true}
                            icon={true}
                            onClick={() => toggleMenu(true)}>
                                <Icon
                                    type="hamburger"
                                    width={22}/>
                        </Button>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Nav