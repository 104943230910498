import React, { useState, useEffect } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useLocation
} from 'react-router-dom'
import { FirstStep, SecondStep, ThirdStep, FourthStep, FifthStep, SeventhStep, Finish } from '../../components/OnBoarding'
import { useAuth, useNavigate } from '../../hooks'
import Link from '../../components/Link'
import Signup from '../Signup'
import { Button, Icon } from '../../components/theme'
import styles from './index.module.scss'

const OnBoarding = () => {
    const {user} = useAuth()
    const { path } = useRouteMatch()
    const location = useLocation()
    const navigate = useNavigate()
    const routes = [
        { path: `${path}/first-step`, nav: true, component: FirstStep },
        { path: `${path}/second-step`, nav: true, component: SecondStep },
        { path: `${path}/third-step`, nav: true, component: ThirdStep },
        { path: `${path}/fourth-step`, nav: true, component: FourthStep },
        { path: `${path}/fifth-step`, nav: true, component: FifthStep },
        { path: `${path}/sixth-step`, nav: true, component: SeventhStep },
        { path: `${path}/join-us`, nav: false, component: Signup },
        { path: `${path}/finish`, nav: false, component: Finish },
    ]
    const [activeRoute, setActiveRoute] = useState(routes[0].path)
    const [explorer, setExplorer] = useState({})
    const [navDark, setNavDark] = useState(false)

    useEffect(() => {
        setActiveRoute(location.pathname)
        setTimeout(() => {
            setNavDark(location.pathname !== routes[0].path && location.pathname !== routes[5].path)
        }, 100)
    }, [location])

    if (user._id) {
        navigate(`/e/${user.email}`)
    }

    return (
        <div>
            {activeRoute !== `${path}/join-us` && (
                <header className={styles.header}>
                    {activeRoute === `${path}/finish` && (
                        <Button
                            bordered={false}
                            icon={true}
                            width={80}
                            height={80}
                            hoverEffect={false}
                            className={styles.closeButton}
                            onClick={() => navigate('/sign-in')}>
                                <Icon
                                    type="close"
                                    width={24}
                                    height={24}/>
                        </Button>
                    )}
                    <div className="container position-relative">
                        <Button
                            type={navDark ? 'primary' : 'secondary'}
                            bordered={false}
                            transparent={true}
                            hoverEffect={false}
                            className={styles.back}
                            // onClick={() => history.goBack()}
                            >
                                Back
                        </Button>
                        <Button
                            type={`${navDark ? 'primary' : 'secondary'}`}
                            className={styles.skip}
                            borderDark={false}
                            width="9rem"
                            onClick={() => navigate(`${path}/join-us`)}>
                                Skip onboarding
                        </Button>
                        <nav>
                            <ul>
                                {routes.map((route, index) => (
                                    <>
                                        {route.nav && (
                                            <Link
                                                key={`nav-item-${index}`}
                                                href={route.path}>
                                                    <li
                                                        data-filled={activeRoute === route.path}
                                                        data-dark={navDark}
                                                    />
                                            </Link>
                                        )}
                                    </>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </header>
            )}
            <Switch>
                {routes.map((route, i) => (
                    <Route
                        key={`route-${i}`}
                        path={route.path}>
                            <route.component
                                explorer={explorer}
                                nextRoute={routes[i + 1] ? routes[i + 1].path : route.path}
                                makeNavLight={() => setNavDark(false)}
                                onChange={(data) => setExplorer({...explorer, ...data})}
                            />
                    </Route>
                ))}
            </Switch>
        </div>
    )
}

export default OnBoarding