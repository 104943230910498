import React from 'react'
import ReactDOM from 'react-dom'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.ModalRoot = document.querySelector('#root')
        this.el = document.createElement('div')
    }
  
    componentDidMount() {
        this.ModalRoot.appendChild(this.el)
    }
  
    componentWillUnmount() {
        this.ModalRoot.removeChild(this.el)
    }
  
    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        )
    }
}
  
export default Modal