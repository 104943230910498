import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'

const Editable = ({
    tag,
    id,
    editable = false,
    defaultValue = '',
    placeholder,
    placeholderTransparent = true,
    style = {},
    innerStyles = {},
    extendStyle = false,
    bordered = false,
    error = '',
    errorAlignment = 'cetner',
    className = '',
    innerId = '',
    clear = false,
    disableEnter,
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
}) => {
    const [showPlaceholder, togglePlaceholder] = useState(false)
    const input = useRef()
    const Tag = `${tag}`

    const onInput = (e) => {
        let value = e.currentTarget.textContent
        if (e.keyCode === 13 && disableEnter && input.current) {
            value += ' '
            input.current.innerText = value.substring(0, value.length - 1)
            return
        }
        onChange(value)
        togglePlaceholder(value.length === 0)
    }

    const blur = (e) => {
        if (clear) {
            togglePlaceholder(true)
            input.current.innerText = ''
        }
        onBlur(e.currentTarget.textContent)
    }

    const focus = () => {
        onChange(defaultValue)
        if (!input || !input.current) {
            return
        }

        input.current.focus()
    }

    useEffect(() => {
        togglePlaceholder(defaultValue.length === 0)
    }, [defaultValue])

    return (
        <Tag
            id={id}
            style={style}
            className={`${styles.editable} ${className}`}
            data-extend-style={extendStyle}
            data-placeholder-transparent={showPlaceholder && placeholderTransparent}
            data-bordered={bordered}>
                <span
                    id={innerId}
                    className={styles.content}
                    contentEditable={editable}
                    suppressContentEditableWarning={true}
                    style={showPlaceholder ? {} : innerStyles}
                    onKeyUp={onInput}
                    onBlur={blur}
                    onFocus={onFocus}
                    onClick={() => {
                        if (!editable) return
                        onChange(defaultValue)
                    }}
                    ref={input}>
                        {defaultValue}
                </span>
                {showPlaceholder && editable && (
                    <i
                        id={innerId}
                        onClick={focus}
                        style={innerStyles}
                        data-transparent={placeholderTransparent}>
                            {placeholder}
                    </i>
                )}
                {error && (
                    <span className={`${styles.error} text-${errorAlignment}`}>
                        {error}
                    </span>
                )}
        </Tag>
    )
}

export default Editable