import AWS from 'aws-sdk'
import randomstring from 'randomstring'
import config from '../config'

AWS.config.update({
    region: config.aws.region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws.identityPoolId
    })
})

const useUploadImage = () => {
    const upload = (file) => {
        return new Promise(
            (resolve, reject) => {
                const s3 = new AWS.S3({
                    apiVersion: config.aws.s3.apiVersion,
                    params: {
                        Bucket: config.aws.s3.bucket
                    }
                })
        
                if (Math.round(file.size / 1024) > 5000) {
                    return reject('File too Big, please select a file less than 5mb')
                }
        
                const fileType = file.type.split('/').pop().toLowerCase()
                if (fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png' && fileType !== 'bmp' && fileType !== 'gif') {
                    return reject('Please select a valid image file')
                }
        
                const random = randomstring.generate({length: 12})
                const photoKey = `radmin/${file.name}-${random}.${file.type.split('/')[1]}`
        
                s3.upload(
                    {
                        Key: photoKey,
                        Body: file,
                        ACL: 'public-read'
                    },
                    (err, data) => {
                        if (err) {
                            return reject('Error')
                        }
        
                        return resolve(data.Location)
                    }
                )
            }
        )
    }

    return upload
}

export default useUploadImage