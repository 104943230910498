import React from 'react'
import Link from '../../Link'
import { Button } from '../../theme'
import styles from './index.module.scss'

const FooterButtons = ({
    nextRoute,
    style = {},
    showSkip = true,
    nextLight = false
}) => {
    return (
        <div
            className={styles.footerButtons}
            style={style}>
                <Link href={nextRoute}>
                    {showSkip && (
                        <Button
                            type={`${nextLight ? 'primary' : 'secondary'}`}
                            width="8.9rem"
                            className="mr-2">
                                Skip The Question
                        </Button>
                    )}
                </Link>
                <Link href={nextRoute}>
                    <Button
                        type={`${nextLight ? 'secondary' : 'primary'}`}
                        width="8.9rem">
                            Next
                    </Button>
                </Link>
        </div>
    )
}

export default FooterButtons