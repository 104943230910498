/*global google*/
import React, { useEffect, useState } from 'react'
import { useScript } from '../../../hooks'
import { Input } from '../../../components/theme'
import './style.scss'

const GoogleAddressSearch = ({
    onChange = () => {},
    placeholder,
    value: defaultValue = '',
    location
}) => {
    const googleLoaded = useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCrhYTLNm1uNCtleV6KeL-nqUivoLC7clo&libraries=places')
    const [value, setValue] = useState(defaultValue)
    const [autocomplete, setAutocomplete] = useState(null)

    const fillInAddress = () => {
        onChange(autocomplete.getPlace())
    }
    
    useEffect(() => {
        if (googleLoaded) {
            const coordinates = location.split(',')
            var circle = new google.maps.Circle({ radius: 80000, center: new google.maps.LatLng(coordinates[0], coordinates[1]) })
            const auto = new google.maps.places.Autocomplete(
                document.getElementById('google-search-autocomplete'), {
                    types: ['geocode'],
                    bounds: circle.getBounds(),
                    strictBounds: true
                }
            )
            setAutocomplete(auto)
        }
    }, [googleLoaded])

    useEffect(() => {
        if (autocomplete) autocomplete.addListener('place_changed', fillInAddress)
    }, [autocomplete])

    return (
        <div id="locationField">
            <Input
                id="google-search-autocomplete"
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}

export default GoogleAddressSearch