import React from 'react'
import styles from './index.module.scss'

const TermsContent = () => {
    return (
        <section className={styles.section}>
            <h1 className="sm text-center">Terms of service</h1>
            <h2 className="sm text-center">RAD Contributor Agreement</h2>
            <div className="container pt-3">
                <span>The following agreement (&ldquo;the Agreement&rdquo;) is between Raleigh &amp; Drake (&ldquo;Company&rdquo;) and The User &nbsp;(&ldquo;you,&rdquo; &ldquo;RAD Contributor,&rdquo; or &ldquo;Contributor&rdquo;).</span>
                <ol>
                    <li>
                        <strong>
                            Age.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; You must be 18 or older to submit your name and any content.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Identity.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; The name and likeness you are submitting as being you are your actual name and likeness.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Truthful Statements.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; All statements you make about yourself and your experiences are true and accurate.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Your Work is Really Yours.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; All written content and all photographic images, videos and other content you submit to us as being your own work product is in fact your own work product. By submitting such content you represent and warrant that (a) the content is your own and not plagiarized or copied from any other source, and (b) you are not aware of any claims from third parties that they own or have any rights of any kind in any or all of such content.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            You&rsquo;re Not Breaking the Law. 
                        </strong>
                        <br/>
                        <span>
                            &nbsp; You represent that all of your submissions (&ldquo;Content&rdquo; or &ldquo;Contributions,&rdquo; as defined in &nbsp; Exhibit A) does not violate any third party&rsquo;s privacy, publicity or any other rights and does not violate any laws.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            License to Us and Waiver.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; For the purposes of this Agreement, your Content (or &ldquo;Contributions&rdquo;) means text, graphics, images, music, software, audio, video, works of authorship of any kind, and information or other materials that are submitted by you for the Company&rsquo;s travel recommendation platform. You hereby give and forever grant to Raleigh &amp; Drake, its affiliates, partners and their respective successors and assigns, the irrevocable, transferable, royalty-free right to use, edit, reorganize, publish,
                        </span>
                        <br/>
                        <span>
                            &nbsp; Republish, display, perform, incorporate, create derivative works based on, distribute, display, perform and transmit your Content, in whole or part, including alterations, modifications, derivations, and composites thereof, for use on all media platforms (website, airline and hotel platforms, etc.) and in all media and channels, now known or hereafter developed, throughout the world. This right shall include the right to combine Your Content with that of others for guides and to alter or add booking links to your content by digital means or otherwise, for any purpose. You also waive any right of any kind, including moral rights, you may have to review any content, including any advertising or promotion by Raleigh &amp; Drake.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Termination &amp; Agency.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; In the event that the Company seeks to terminate this Agreement for any reason, the Company shall provide notice of the planned termination to you by email. Upon termination, Company will pay you for all completed work to date (10-15 recommendations for any given city constitutes &ldquo;completed work&rdquo;). Further, this Agreement does not create an employment, partnership, franchise, joint venture, or principal-agent relationship between the parties.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            No Promises Made.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; By being a RAD Contributor, we are not representing, guaranteeing, or promising that you will benefit in any way.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Our liability is limited.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; In no event will Raleigh &amp; Drake, or any of its affiliates and partners and their respective directors, officers, employees, agents or representatives, be liable to you for any direct, special, incidental, exemplary, punitive, or consequential damages (including loss of use, data, business, or &nbsp; profits) arising out of or in connection with this agreement or the use or performance of the services, whether such liability arises from any claim based upon contract, warranty, tort (including negligence), strict liability or otherwise, and whether or not Raleigh &amp; Drake has been advised of the possibility of such loss or damage.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Governing Law.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; This Agreement shall be interpreted and governed in accordance with the laws of the State of California. Exclusive venue for any dispute regarding this Agreement shall reside in the U.S. District Court for Los Angeles.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Entire Agreement.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; This Agreement constitutes the entire agreement between the parties. Any prior negotiations, discussions, or agreements are merged into this Agreement and shall not operate to alter, modify, impair, or affect this Agreement or the interpretation of this Agreement, and are inadmissible as evidence in any legal proceeding. No provision of this Agreement may be waived, altered, or modified except in writing executed by all the parties to this Agreement.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Severability.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; If any provision of this Agreement is held by a court of competent jurisdiction to be invalid, void, or unenforceable, the remainder of this Agreement shall remain in full force and effect and such determination shall in no way affect the validity or enforceability of any other provision of this Agreement.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <li>
                        <strong>
                            Services and fee.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; You agree to provide the Company city recommendations (&ldquo;Content&rdquo; or &ldquo;Contributions&rdquo;) as described in
                        </span>
                        <strong>
                            Exhibit A.
                        </strong>
                        <br/>   
                        <span>
                            &nbsp; In exchange for the Contributions, the Company agrees to pay you the project rate as outlined below.
                        </span>
                        <div className={styles.br}/>
                    </li>
                    <ol>
                        <li>
                            The rate will be
                            <strong>&nbsp;$150</strong>
                            <span>&nbsp; in exchange for completion of the Contributor&rsquo;s task list, as detailed in </span>
                            <strong>&nbsp; Exhibit A.</strong>
                            <div className={styles.br}/>
                        </li>
                        <li>
                            The fee will be paid per the schedule in
                            <strong>&nbsp; Exhibit B.</strong>
                            <div className={styles.br}/>
                        </li>
                    </ol>
                </ol>
                <p>
                    <em>
                        I have read, understand, and accept the terms of this letter agreement
                    </em>
                </p>
                <p>
                    <strong>
                        EXHIBIT A
                    </strong>
                </p>
                <p>
                    <strong>
                        Project/Task Description:
                    </strong> 
                </p>
                <p>
                You will identify, describe, and provide insider tips for unique city experiences to include on RAD&rsquo;s travel recommendation platform. You will share thoughts and expertise on the cultural landscape of assigned cities by providing 3-4 sentences for place/experience description, 1 insider tip, photos where indicated, and appropriate category tags and hashtags. 
                </p>
                <p>
                    <strong>
                        Scope of Work:
                    </strong>
                </p>
                <ol>
                    <li>
                        To earn the project rate, Contributors must submit a minimum of 10-15 city experiences that include:
                        <div className={styles.br}/>
                    </li>
                    <ol>
                        <li>
                            A 3-4 sentence description of the place or experience that explains a) what the place or experience is and b)
                            <em>how</em>
                            <span>
                                the place or experience is differentiated from others in the city Criteria for RAD experiences:
                            </span>
                            <div className={styles.br}/>
                        </li>
                        <ul>
                            <li>
                                Unique, unexpected, and grounded in &ldquo;doing&rdquo; (vs. a laundry list of bars/restaurants, although those can be included if supplemented by other experiences) 
                                <div className={styles.br}/>
                            </li>
                            <li>
                                Beyond just fun, an experience that is meaningful
                                <div className={styles.br}/>
                            </li>
                            <li>
                                Somewhere you have enjoyed as a local or would enjoy s a traveler
                                <div className={styles.br}/>
                            </li>
                        </ul>
                        <ol>
                            <li>
                                1-2 insider tips that move beyond generic recommendation territory (meaning tips that convey exciting, unexpected insider knowledge of the place or experience)
                                <div className={styles.br}/>
                            </li>
                            <li>
                                2-3 photos of the place or experience you are including (if you do not have personal photos of the place or experience, then selected photos from RAD&rsquo;s curator platform) 
                                <div className={styles.br}/>
                            </li>
                            <li>
                                Appropriate category tag(s) depending on experience
                                <div className={styles.br}/>
                            </li>
                            <li>
                                And if, upon review, RAD notifies the Contributor of invalid experiences, the Contributor must submit new experience(s) to replace the invalid submission(s) until the minimum number (10) for payment is met
                                <div className={styles.br}/>    
                            </li>
                        </ol>
                    <li>
                        An experience would be &ldquo;invalid&rdquo; if it does not meet, in any way, the above stated criteria 
                        <div className={styles.br}/>
                    </li>
                    <div className={styles.br}/>
                    <li>Contributors must sign the Contributor Agreement</li>
                    <div className={styles.br}/>
                    </ol>
                </ol>
                <strong>EXHIBIT B: Payment Schedule</strong>
                <p>
                    The full fee for the agreed-upon deadline in this agreement is 
                    <strong>
                        &nbsp;$150
                    </strong>
                    <span>
                        for completion of the task. The deadline will be agreed-upon via email. Please contact
                    </span>
                    <a href="mailto:nicole@rad.travel">&nbsp;nicole@rad.travel</a>
                        
                    <span>&nbsp;if you have questions regarding the Payment Schedule.</span>
                    <p>
                        &nbsp;Company agrees to pay Contributor on time at agreed upon payment schedule listed below:
                    </p>
                </p>
                <ul>
                    <li>
                        Final payment (<strong>$150</strong>) for submitting all required content
                        <div className={styles.br}/>
                    </li>
                    <li>
                        Payment will be tendered within 48 hours of content submission approval via Venmo or PayPal
                        <div className={styles.br}/>
                    </li> 
                </ul>
            </div>
        </section>
    )
}

export default TermsContent