import React, { useState } from 'react'
import styles from './index.module.scss'

const Input = ({
    id = '',
    placeholder,
    type = 'text',
    secondary = false,
    value = '',
    error = false,
    border = true,
    clearPlaceholder = false,
    className = '',
    name = '',
    disabled = false,
    textarea = false,
    rows = 2,
    onClick = () => {},
    onChange = () => {},
    onBlur = () => {},
    onKeyUp = () => {}
}) => {
    const [focused, setFocused] = useState(false)

    if (textarea) {
        return (
            <textarea
                id={id}
                type={type}
                placeholder={placeholder}
                data-secondary={secondary}
                data-error={error}
                disabled={disabled}
                data-border={border}
                autoComplete="off"
                rows={rows}
                value={value}
                name={name}
                className={`${styles.input} ${className}`}
                onClick={onClick}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={onKeyUp}/>
        )
    }

    return (
        <input
            type={type}
            id={id}
            placeholder={focused && clearPlaceholder ? '' : placeholder}
            data-secondary={secondary}
            data-error={error}
            data-border={border}
            autoComplete="off"
            value={value}
            disabled={disabled}
            name={name}
            className={`${styles.input} ${className}`}
            onClick={onClick}
            onChange={onChange}
            onBlur={() => {
                setFocused(false)
                onBlur()
            }}
            onFocus={() => setFocused(true)}
            onKeyUp={onKeyUp}/>
    )
}

export default Input