import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import Slide from '../../../components/Slide'
import Loader from '../../../components/Loader'
import { useWindowSize } from '../../../hooks'
import { Button } from '../../../components/theme'
import styles from './index.module.scss'

const Slider = ({
    medias = []
}) => {
    const [swiper, setSwiper] = useState(null)
    const [nextProgress, setNextProgress] = useState(0)
    const [prevProgress, setPrevProgress] = useState(100)
    const [images, setImages] = useState([])
    const { width } = useWindowSize()
    const params = {
        centeredSlides: width > 720 ? true : false,
        slidesPerView: 1.2,
        loop: false,
        slidesOffsetBefore: width > 720 ? 0 : 15,
        slidesOffsetAfter: width > 720 ? 0 : 15,
    }

    const goNext = () => {
        if (swiper !== null) {
            const realIndex = swiper.realIndex + 1
            const nextImagesCount = images.length - 1
            const oneSector = 100 / nextImagesCount

            if (nextImagesCount - realIndex > 0) {
                setNextProgress(100 - (oneSector * (nextImagesCount - realIndex)))
                setPrevProgress(prevProgress - oneSector)
            } else if (nextImagesCount - realIndex === 0) {
                setPrevProgress(0)
                setNextProgress(100)
            }

            swiper.slideNext()
        }
    }
     
    const goPrev = () => {
        const realIndex = swiper.realIndex - 1
        const nextImagesCount = images.length - 1
        const oneSector = 100 / nextImagesCount

        if (nextImagesCount - realIndex > 0 && realIndex > 0) {
            setPrevProgress(oneSector * (nextImagesCount - realIndex))
            setNextProgress(nextProgress - oneSector)
        } else if (nextImagesCount - realIndex === nextImagesCount) {
            setNextProgress(0)
            setPrevProgress(100)
        }
        swiper.slidePrev()
    }

    useEffect(() => {        
        if (swiper) {
            if (images.length === 0) {
                swiper.destroy()
            }
            swiper.update()
            if (images.length > 0) {
                setNextProgress(0)
                setPrevProgress(100)
            }
        }
    }, [images, swiper])

    useEffect(() => {
        if (medias.length === 0) {
            setImages([])
            return
        }

        const newImages = [...medias]
        const hasCover = newImages.find(i => i.cover)
        if (hasCover) {
            setImages(newImages.filter(i => !i.cover))
            return
        }
        setImages(medias.filter((media, i) => i !== 0))
    }, [medias])
        
    return (
        <section>
            {images.length > 0 && (
                <div className="slider row justify-content-center w-100 m-0">
                    <Swiper {...params} getSwiper={setSwiper}>
                        {images.map((media, index) => (
                            <div
                                className="slide-container"
                                key={`activity-image-${index}-${media.caption}`}>
                                    {swiper && (
                                        <Slide
                                            index={index}
                                            lastIndex={medias.length}
                                            image={media}
                                            swiper={swiper}/>
                                    )}
                            </div>
                        ))}
                    </Swiper>
                    {swiper && images.length > 1 && ([
                        <Button
                            key={`swiper-navigation-0`}
                            className={styles.navigation}
                            bordered={false}
                            icon={true}
                            type="secondary"
                            circle={false}
                            onClick={goPrev}>
                                <Loader
                                    progress={prevProgress}/>
                        </Button>,
                        <Button
                            key={`swiper-navigation-1`}
                            className={styles.navigation}
                            bordered={false}
                            type="secondary"
                            icon={true}
                            circle={false}
                            onClick={goNext}>
                                <Loader
                                    progress={nextProgress}/>
                        </Button>
                    ])}
                </div>
            )}
        </section>
    )
}

export default Slider