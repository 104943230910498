import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import { useAuth, useOrganization, useNavigate } from '../../hooks'
import { DEFAULT_GUIDE_COVER } from '../../constants'
import Info from './Info'
import http from '../../http'
import styles from './index.module.scss'

const CreateGuide = () => {
    const urlParams = (new URL(document.location)).searchParams
    const navigate = useNavigate()
    const {id} = useParams()
    const {user, logout} = useAuth()
    const [hasPermission, togglePermission] = useState()
    const {organization} = useOrganization()
    const [guide, setGuide] = useState({
        activities: [],
        cover: DEFAULT_GUIDE_COVER,
        alias: '',
        name: '',
        city: {},
        google_info: {},
        description: '',
        tip: ''
    })
    const [loading, setLoading] = useState(id ? true : false)

    const save = () => {
        setLoading(true)
        const newGuide = {...guide}

        if (guide._id) {
            http
                .put(`/api/guide/${guide._id}`, newGuide)
                .then(
                    (response) => {
                        setGuide(response.data.guide)
                        if (organization) {
                            logout({preventDefault: () => {}}, false)
                        }
                        if (urlParams.has('redirect_url')) {
                            return window.location.href = urlParams.get('redirect_url')
                        }
                        navigate(`/guide/${response.data.guide._id}`)
                    }
                )
                .catch(e => console.log(e.message || e))
            return
        }

        newGuide.draft = true
        newGuide.alias = guide.name.split(' ').join('-').toLowerCase()
        newGuide._explorer = urlParams.has('explorer_id') ? urlParams.get('explorer_id') : user._id
        http
            .post('/api/guide', newGuide)
            .then(
                (response) => {
                    if (organization) {
                        logout({preventDefault: () => {}}, false)
                    }
                    if (urlParams.has('redirect_url')) {
                        return window.location.href = urlParams.get('redirect_url')
                    }
                    setGuide(response.data.guide)
                    navigate(`/guide/${response.data.guide._id}`)
                }
            )
            .catch(e => console.log(e.message || e))
            .finally(() => setLoading(false))
    }

    const getGuide = () => {
        const hasAccessToEdit = organization && organization.permissions && organization.permissions['editGuide']
        const params = { id: id }
        http
            .get('/api/guide', { params })
            .then(
                (response) => {
                    if ((response.data.guide && response.data.guide._explorer._id === user._id) || hasAccessToEdit) {
                        return setGuide(response.data.guide)
                    }
                    navigate('/404')
                }
            )
            .catch(console.error)
            .finally(() => setLoading(false))
    }

    const checkPermissions = () => {
        if (!user._id && !organization) {
            return togglePermission(false)
        }
        if (user._id) {
            return togglePermission(true)
        }
        if (organization) {
            if (!organization.permissions['editGuide'] && id) {
                alert('Invalid token for editing guide')
                return togglePermission(false)
            }

            if (!organization.permissions['createGuide'] && !id) {
                alert('Invalid token for creating guide')
                return togglePermission(false)
            }

            if (organization.permissions['createGuide'] && !id && !urlParams.has('explorer_id')) {
                alert('Please provide `explorer_id` in url to create guide')
                return togglePermission(false)
            }

            if (!urlParams.has('redirect_url')) {
                alert('Please provide `redirect_url` in url.')
                return togglePermission(false)
            }
        }
        togglePermission(true)
    }

    useEffect(() => {
        checkPermissions()
    }, [])

    useEffect(() => {
        if (!hasPermission || !id) return
        getGuide()
    }, [hasPermission, id])

    if (hasPermission === undefined) {
        return <></>
    }

    if (!hasPermission) {
        navigate('/sign-up')
    }

    return (
        <section>
            <Loading
                status={!loading ? 'loaded' : 'loading'}
            />
            <Helmet>
                <title>Curator | RAD | Create Guide</title>
            </Helmet>
            <Info
                guide={guide}
                save={save}
                loading={loading}
                user={user}
                onChange={(data) => setGuide({...guide, ...data})}
            />
        </section>
    )
}

export default CreateGuide