import { useCallback } from 'react'
import { useFetcher } from '../index'
import * as service from './service'

const useVerify = () => {
    const { fetcher, loading, error, data } = useFetcher()

    const verify = useCallback(
        ({token}) => {
            fetcher(
                service.verify({token})
            )
        },
        []
    )

    return {
        loading,
        error,
        verify,
        data
    }
}

export default useVerify