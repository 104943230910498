import React from 'react'
import styles from './index.module.scss'

const Line = ({
    style = {},
    primary = false,
    className
}) => {
    return (
        <div
            className={`${styles.line} ${className}`}
            data-primary={primary}
            style={style}/>
    )
}

export default Line