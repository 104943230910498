import React from 'react'
import Link from '../Link'
import { Button, Icon } from '../theme'
import { useAuth } from '../../hooks'
import styles from './index.module.scss'

const routes = [
    {value: 'Home', path: '/'},
    {value: 'Join our tribe', path: '/sign-up'},
    {value: 'Sign in', path: '/sign-in'},
]

const Menu = ({
    toggleMenu
}) => {
    const {user, logout} = useAuth()
    const authRoutes = [
        {value: 'Your Profile', path: `/e/${user.email}`},
        {value: 'Create a guide', path: '/create/guide'},
        {value: 'Create an experience', path: '/create/activity'},
    ]
    return (
        <section className={styles.menu}>
            <Button
                type="primary"
                height="4.33rem"
                width="4.33rem"
                icon={true}
                bordered={false}
                circle={true}
                onClick={() => toggleMenu(false)}>
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <div className="container">
                <ul>
                    {user.email ? (
                        <>
                            {authRoutes.map((route, i) => (
                                <li key={`route-${i}`}>
                                    <Link
                                        href={route.path}
                                        onClick={() => toggleMenu(false)}>
                                            {route.value}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                <a
                                    href="/#"
                                    onClick={(e) => {
                                        logout(e)
                                        toggleMenu(false)
                                    }}>
                                        Logout
                                </a>
                            </li>
                        </>
                    ) : (
                        <>
                            {routes.map((route, i) => (
                                <li key={`route-${i}`}>
                                    <Link
                                        href={route.path}
                                        onClick={() => toggleMenu(false)}>
                                            {route.value}
                                    </Link>
                                </li>
                            ))}
                        </>
                    )}
                </ul>
            </div>
        </section>
    )
}

export default Menu