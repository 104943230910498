import React, { useState, useEffect } from 'react'
import { useWindowSize } from '../../hooks'

const Loader = ({
    progress,
    width = 80
}) => {
    const speed = 40
    const [circle, setCircle] = useState({
        diff: 0,
        radius: 0,
        offset: 0,
        circumference: 0,
        width: 0,
        transitionSpeed: 0,
    })

    useEffect(() => {
        const diff = width > 576 ? 0.4 : 0.82
        const w = width * diff / 2
        const radius = w > 0 ? w - 2 : 0
        const circumference = radius * 2 * Math.PI
        const offset = circumference - progress / 100 * circumference

        setCircle({
            diff,
            width: w,
            radius,
            circumference,
            offset,
            transitionSpeed: progress > 20 ? 0.35 : 0.05,
        })
    }, [width, setCircle, progress])

    return (
        <div style={{ transform: 'rotate(-90deg)' }}>
            <svg
                height={width * circle.diff}
                width={width * circle.diff}>
                    <circle
                        stroke="transparent"
                        strokeWidth="1"
                        fill="transparent"
                        cx={circle.width}
                        cy={circle.width}
                        r={circle.radius}/>
                    <circle
                        stroke="#0E0E0E"
                        strokeWidth="1"
                        fill="transparent"
                        style={{transition: `stroke-dashoffset ${circle.transitionSpeed}s`}}
                        strokeDashoffset={circle.offset}
                        strokeDasharray={`${circle.circumference} ${circle.circumference}`}
                        cx={circle.width}
                        cy={circle.width}
                        r={circle.radius}/>
            </svg>
        </div>
    )
}

export default Loader