import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Image from '../../Image'
import Loading from '../../LoadingIcon'
import { Button } from '../../theme'
import { DEFAULT_EXPLORER_COVER } from '../../../constants'
import { withAlert } from '../../../hocs'
import { useUploadImage, useIsMobileDevice } from '../../../hooks'
import styles from './index.module.scss'

const Header = ({
    editable,
    editableState,
    explorer,
    setEditableState,
    Alert
  }) => {
  const [coverLoading, toggleCoverLoading] = useState(false)
  const uploadImage = useUploadImage()
  const isMobileDevice = useIsMobileDevice()

  const onUploadImage = useCallback(
    (acceptedFiles) => {
      toggleCoverLoading(true)
      uploadImage(acceptedFiles[0])
        .then(image => {
          const updatedData = { cover: image }
          setEditableState(editableState => ({ ...editableState, ...updatedData }))
        })
        .catch(e => {
          console.log(e)
          toggleCoverLoading(false)
          Alert({ title: e })
        })
    },
    [coverLoading, toggleCoverLoading, setEditableState]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onUploadImage, multiple: false })
  let headerProps = {
    className: `container-fluid ${styles.header}`
  }

  if (editable) {
    headerProps = { ...headerProps, ...getRootProps() }
  }

  return (
    <header
      {...headerProps}
      data-editable={editable}
      key={`cover-${explorer.cover}-explorer-${explorer._id}`}>
      <input {...getInputProps()}/>
      <Image
        src={editableState.cover ? editableState.cover : (explorer.cover ? explorer.cover : DEFAULT_EXPLORER_COVER)}
        options="w_1700,f_auto"
        alt="Cover"
        onLoad={() => toggleCoverLoading(false)}
        onError={() => toggleCoverLoading(false)}
        fromCloudinary={false}
      />
      {editable && !coverLoading && (
        <Button
          type="secondary"
          hoverEffect={false}
          width="13rem">
          {
            coverLoading ? '' : (
              <>
                {
                  isDragActive ?
                    'Drop the files here ...'
                    :
                    `${isMobileDevice ? 'Click to select files' : `Drag 'n' drop some files here, or click to select files`}`
                }
              </>
            )
          }
        </Button>
      )}
      {coverLoading && <Loading/>}
    </header>
  )
}

export default withAlert(Header)