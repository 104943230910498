import React, { useState, useEffect, useRef } from 'react'
import { Button, Icon, Input } from '../../theme'
import styles from './index.module.scss'

const SocialLinks = ({
    guest,
    explorer,
    onChange,
    editable,
    editableState
}) => {
    const [editing, toggleEditing] = useState(false)
    const [social, setSocial] = useState('')
    const socialSection = useRef(null)

    const editableDataOutsideClick = (e) => {
        if (!socialSection.current) return
        if (e.target === socialSection.current || socialSection.current.contains(e.target)) {
            return
        }
        onChange({ [social.type]: social.value })
        setTimeout(() => {
            toggleEditing(false)
        }, 100)
    }

    const editSocial = (e, type) => {
        if (guest || !editable) {
            return
        }

        e.preventDefault()
        toggleEditing(true)
        setSocial({
            type: `${type}_username`,
            value: editableState ? editableState[type + '_username'] : explorer[type + '_username']
        })
    }

    useEffect(() => {
        if (editable && editing) {
            window.addEventListener('click', editableDataOutsideClick)
            window.addEventListener('touchend', editableDataOutsideClick)
    
            return () => {
                window.removeEventListener('click', editableDataOutsideClick)
                window.removeEventListener('touchend', editableDataOutsideClick)
            }
        }
    }, [editable, editing])

    if (!explorer.facebook_username && !explorer.instagram_username && !explorer.twitter_username && !editable) {
        return <></>
    }

    return (
        <div className={styles.socialSection}>
            <div
                className={styles.socials}
                data-guest={guest}
                data-editable={editable}
                data-editing={editing}>
                    {editing ? (
                        <div ref={socialSection}>
                            <Input
                                value={social.value}
                                placeholder="@username"
                                onChange={(e) => setSocial({...social, value: e.target.value})}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 13) {
                                        onChange({ [social.type]: social.value })
                                        toggleEditing(false)
                                    } 
                                }}/>
                            <button
                                onClick={() => {
                                    onChange({ [social.type]: social.value })
                                    toggleEditing(false)
                                }}>
                                    <Icon
                                        type="arrow-right"/>
                            </button>
                        </div>
                    ) : (
                        <div>
                            {(explorer.facebook_username || editable) && (
                                <a
                                    href={`https://www.facebook.com/${explorer.facebook_username}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => editSocial(e, 'facebook')}>
                                        <Button
                                            type="secondary"
                                            icon={true}
                                            width={30}
                                            height={30}
                                            bordered={false}>
                                                <Icon
                                                    type="facebook"
                                                    width={12}
                                                    height={12}/>
                                        </Button>
                                </a>
                            )}
                            {(explorer.instagram_username || editable) && (
                                <a
                                    href={`https://www.instagram.com/${explorer.instagram_username}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => {
                                        editSocial(e, 'instagram')
                                    }}>
                                        <Button
                                            type="secondary"
                                            icon={true}
                                            width={30}
                                            height={30}
                                            bordered={false}>
                                                <Icon
                                                    type="instagram"
                                                    width={12}
                                                    height={12}/>
                                        </Button>
                                </a>
                            )}
                            {(explorer.twitter_username || editable) && (
                                <a
                                    href={`https://www.twitter.com/${explorer.twitter_username}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={(e) => editSocial(e, 'twitter')}>
                                        <Button
                                            type="secondary"
                                            icon={true}
                                            width={30}
                                            height={30}
                                            bordered={false}>
                                                <Icon
                                                    type="twitter"
                                                    width={12}
                                                    height={12}/>
                                        </Button>
                                </a>
                            )}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default SocialLinks