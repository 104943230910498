import React, { useState } from 'react'
import { Editable, Line } from '../../theme'

const Bio = ({
    explorer,
    onChange,
    editable
}) => {
    const [focused, setFocused] = useState(false)
    return (
        <>
            {editable && (
                <h3 className="sm">My Bio</h3>
            )}
            <Editable
                tag="p"
                className="mt-2 mb-2"
                innerStyles={{ width: '100%', display: 'inline-block', cursor: 'text' }}
                defaultValue={explorer.bio}
                placeholder="Tell us about yourself"
                editable={editable}
                onChange={value => onChange({bio: value})}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            {editable && (
                <Line
                    primary={focused}
                    style={{ opacity: 1 }}
                />
            )}
        </>
    )
}

export default Bio