import React, { useState, useEffect } from 'react'
import { withPlace } from '../../../hocs'
import GoogleAddressSearch from '../GoogleAddressSearch'
import { Editable, DropDown, Input, Button, Icon } from '../../../components/theme'
import http from '../../../http'
import styles from './index.module.scss'

const SearchActivity = ({
    category = '',
    onChange,
    info: i,
    user,
    inputError = '',
    type = 'guide',
    clearValue = false,
    placeChange,
    style = {},
    bordered = true,
    setSuggestionsVisible = () => {}
}) => {
    const [notInList, setNotInList] = useState(false)
    const [activityName, setActivityName] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [info, setInfo] = useState(i)
    const [location, setLocation] = useState(null)
    const [activitySearch, setActivitySearch] = useState('')

    const activityChange = (value) => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const data = info
        if (!data.loc && urlSearchParams.has('coordinates')) {
            data.loc = {
                type: 'Point',
                coordinates: JSON.parse(urlSearchParams.get('coordinates'))
            }
        }
        setActivitySearch(value ? value : ' ')
        placeChange(value, false, data)
            .then(response => setSuggestions(response))
            .catch(e => {
                setSuggestions([])
                console.log(e.message || e)
            })
    }

    const chooseActivity = async (activity, address = false) => {
        try {
            const newActivity = {
                _explorer: user,
                category,
                tags: [],
                name: activity,
                loc: {
                    type: 'Point',
                    coordinates: info.city.location
                },
                google_info: {}
            }
            if (!address) {
                const response = await http.get('/api/place', {
                    params: {
                        id: activity.id,
                        type: activity.type
                    }
                })
                const { place } = response.data
                newActivity.name = place.name
                newActivity.google_info = activity.type === 'foursquare' ? {} : place
                newActivity.loc = {
                    type: 'Point',
                    coordinates: activity.type === 'foursquare' ? [] : [place.geometry.location.lng, place.geometry.location.lat]
                }
                if (activity.type === 'foursquare') {
                    newActivity.google_info = {
                        geometry: {
                            location: {lat: place.location.lat, lng: place.location.lng}
                        },
                        formatted_address: place.location.formattedAddress.join(', '),
                        formatted_phone_number: place.contact.phone,
                        international_phone_number: place.contact.formattedPhone,
                        website: place.url
                    }
                    newActivity.loc = {
                        type: 'Point',
                        coordinates: [place.location.lng, place.location.lat]
                    }
                }
            } else {
                newActivity.name = activity.name
                newActivity.formatted_address = activity.formatted_address
                newActivity.google_info = {
                    geometry: {
                        location: {lat: activity.geometry.location.lat(), lng: activity.geometry.location.lng()}
                    },
                    formatted_address: activity.formatted_address,
                    website: activity.url
                }
                newActivity.loc = {
                    type: 'Point',
                    coordinates: [activity.geometry.location.lng(), activity.geometry.location.lat()]
                }
            }

            if ((!info._id && type === 'activity') || type === 'guide') {
                newActivity._id = new Date().getTime() + (Math.floor(Math.random() * 1000) + 1)
            }

            if (type === 'guide') {
                newActivity._rad = info._id
                newActivity.city = info.city
                newActivity._city = info._city
            }
            if (activityName && notInList) newActivity.name = activityName
            if (!notInList) {
                setActivityName(newActivity.name)
            } else {
                const name = document.querySelector('#activity-name').value
                setActivityName(name)
                newActivity.name = name
            }
            setSuggestions([])
            setNotInList(false)
            onChange({ data: newActivity })
        } catch (e) {
            console.log(e.message || e)
        }
    }

    useEffect(() => {
        setInfo(info)
        if (info.name) setActivityName(info.name)
        if (info.city && info.city.location && info.city.location.length > 0) {
            setLocation(`${info.city.location[0]},${info.city.location[1]}`)
            return
        }
        if (info.loc && info.loc.coordinates) setLocation(`${info.loc.coordinates[0]},${info.loc.coordinates[1]}`)
    }, [info])

    useEffect(() => {
        if (suggestions.length > 0) {
            setSuggestionsVisible(true)
            return
        }
        setSuggestionsVisible(false)
    }, [suggestions])

    return (
        <div
            className={styles.searchActivity}
            data-opened={suggestions.length > 0}
            data-not-in-list={notInList}>
                <h2>Step 1: Add Info</h2>
                <label
                    htmlFor="activity-name"
                    onClick={() => {
                        document.querySelector('#activity-name').focus()
                    }}>
                        What are you reviewing?
                </label>
                {notInList ? (
                    <div className={styles.notInList}>
                        <Button
                            transparent={true}
                            hoverEffect={false}
                            icon={true}
                            onClick={() => {
                                setNotInList(false)
                                setActivitySearch(activityName)
                                activityChange(activityName)
                            }}>
                                <Icon
                                    type="close-dark"
                                />
                        </Button>
                        <Input
                            id="activity-name"
                            value={activityName.trimLeft()}
                            placeholder="Experience Name"
                            onChange={(e) => setActivityName(e.target.value)}
                        />
                        <GoogleAddressSearch
                            location={location}
                            placeholder="What is the address?"
                            value={info.google_info ? info.google_info.formatted_address : ''}
                            onChange={(data) => chooseActivity(data, true)}
                        />
                    </div>
                ) : (
                    <>
                        <Editable
                            tag="span"
                            innerId="activity-name"
                            onChange={activityChange}
                            editable={true}
                            className={info.name ? 'mt-2' : ''}
                            innerStyles={{display: 'inline-block', width: '100%', cursor: 'text'}}
                            placeholder="The business name or name of the experience"
                            defaultValue={type !== 'guide' ? info.name || activityName : ''}
                            bordered={bordered}
                            disableEnter={true}
                            errorAlignment="left"
                            error={inputError}
                        />
                        <DropDown
                            choose={chooseActivity}
                            showNotInList={true}
                            suggestions={suggestions}
                            style={style}
                            width="100%"
                            search={activitySearch}
                            setSearch={setActivitySearch}
                            onOutsideClick={() => {
                                if (!info.name && activitySearch) {
                                    setNotInList(true)
                                    setActivityName(activitySearch)    
                                }
                                setActivitySearch('')
                                setSuggestions([])
                            }}
                            onNotList={() => {
                                setNotInList(true)
                                setActivityName(activitySearch === true ? '' : activitySearch)
                                setSuggestions([])
                            }}
                            clearValue={clearValue}
                            value={activityName}
                            onChange={(value) => activityChange(value)}
                        />
                    </>
                )}
        </div>
    )
}

export default withPlace(SearchActivity)