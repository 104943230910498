import React, { useState } from 'react'
import Alert from '../../../components/Alert'
import { Button, Icon } from '../../../components/theme'
import { useAuth, useNavigate } from '../../../hooks'
import http from '../../../http'

const DeleteGuide = ({guide}) => {
    const navigate = useNavigate()
    const [popConfirm, setPopConfirm] = useState(false)

    const onDelete = () => {
        setPopConfirm(false)
        http
            .delete(`/api/guide/${guide._id}`)
            .then(
                () => {
                    navigate(`/e/${guide._explorer.email}`)
                }
            )
            .catch(e => console.log(e, ' deleting guide error'))
    }

    return (
        <>
            {popConfirm && (
                <Alert
                    title="Are You Sure ?"
                    confirmation={true}
                    onSuccess={onDelete}
                    onCancel={() => setPopConfirm(false)}/>
            )}
            <Button
                type="secondary"
                bordered={false}
                className="ml-2"
                icon={true}
                width={40}
                height={40}
                onClick={() => setPopConfirm(true)}>
                    <Icon
                        type="trash"
                    />
            </Button>
        </>
    )
}

export default DeleteGuide
