import React from 'react'
import http from '../http'

const withErrorHandler = Component => ({
    ...props
}) => {
    const handleErrors = (e) => {
        console.log(e)
        console.log(e.response)
        console.log(e.message && e.message)

        if (e.response && e.response.status === 401) {
            localStorage.removeItem('token')
            Object.assign(http.defaults, { headers: {} })
        }
    }

    return (
        <Component
            {...props}
            handleErrors={handleErrors}/>
    )
}

export default withErrorHandler