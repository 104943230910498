import React from 'react'
import { ourBeliefs } from '../../../content'
import styles from './index.module.scss'

const OurBeliefs = () => {
    return (
        <section className={styles.ourBeliefs}>
            <h3 className="lg text-center mt-0">
                Our Beliefs
            </h3>
            <div className="container">
                <div className="row">
                    {ourBeliefs.map((belief, i) => (
                        <div
                            className={`col-md-4 ${styles.beliefs}`}
                            key={`belief-${i}`}>
                                <div>
                                    <h4>{belief.title}</h4>
                                    <p>{belief.caption}</p>
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OurBeliefs