import React, { useState } from 'react'
import styles from './index.module.scss'
import { Button, Input } from '../../../components/theme'

const PasswordSection = ({
    submit
}) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const validatePassword = (value, type) => {
        return new Promise((resolve, reject) => {
            const password = value
            const errorMessage = 'Password must contain at least one uppercase, lowercase letter(s), number(s) and minimum 8 characters'
            const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
            if (!regex.test(password) && type === 'password') {
                return reject(errorMessage)
            }
            if (!regex.test(confirmPassword) && type === 'confirmPassword') {
                return reject(`Confirm ${errorMessage}`)
            }
            if (type === 'confirmPassword' && password !== confirmPassword) {
                return reject('Passwords don`t match')
            }
            return resolve()
        })
    }

    const onSubmit = async () => {
        try {
            await validatePassword(password, 'password')
            setPasswordError('')
        } catch (message) {
            setPasswordError(message)
            return
        }

        try {
            await validatePassword(password, 'confirmPassword')
            setConfirmPasswordError('')
        } catch (message) {
            setConfirmPasswordError(message)
            return
        }

        submit({password, confirmPassword})
    }

    return (
        <section className={styles.section}>
            <h3 className="mb-5">Choose your password</h3>
            <div className="row pt-1 mb-5 justify-content-center">
                <div className="col-10">
                    <Input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        error={passwordError ? true : false}
                        onKeyUp={(e) => {if (e.keyCode === 13) onSubmit()}}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}/>
                    <span className="error-message">{passwordError}</span>
                    <Input
                        type="password"
                        placeholder="Enter your password again"
                        value={confirmPassword}
                        error={confirmPasswordError ? true : false}
                        className="mt-3"
                        onKeyUp={(e) => {if (e.keyCode === 13) onSubmit()}}
                        onChange={(e) => {
                            setConfirmPassword(e.target.value)
                        }}/>
                    <span className="error-message">{confirmPasswordError}</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-5 pl-1 pr-1">
                    <Button
                        type="primary"
                        className="mt-1"
                        width="100%"
                        onClick={onSubmit}>
                            Join our tribe
                    </Button>
                </div>
            </div>
            {/* <p className="small mt-5">
                By clicking the button you agree to the <a href="/#"> T&C </a> of the products <a href="/#"> *Terms </a> apply
            </p> */}
        </section>
    )
}

export default PasswordSection