import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withAlert } from '../../hocs'
import { useAuth, useNavigate } from '../../hooks'
import { Button, Input, Icon } from '../../components/theme'
import http from '../../http'
import styles from './index.module.scss'

const ResetPassword = ({
    Alert
}) => {
    const {user} = useAuth()
    const [email, setEmail] = useState(user.email ? user.email : '')
    const [emailError, setEmailError] = useState('')
    const [isSent, toggleSent] = useState(false)
    const navigate = useNavigate()
    const history = useHistory()

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const reset = useCallback(
        (email) => {
            if (isSent) return
            toggleSent(true)
            if (!validateEmail(email)) {
                setEmailError('Invalid email address')
                return
            }
            http
                .post('/api/reset/password', { email })
                .then(
                    () => {
                        Alert({title: 'Please check your email'})
                        setTimeout(() => {
                            navigate('/')
                        }, 3000)
                    }
                )
                .catch(
                    e => {
                        Alert({title: 'error'})
                        toggleSent(false)
                        console.log(e)
                    }
                )
        },
        [isSent]
    )

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.has('email')) setEmail(params.get('email'))
    }, [])

    return (
        <section className={`${styles.section} pt-3`} >
            <Helmet>
                <title>Curator | RAD | Reset Password</title>
            </Helmet>
            <Button
                icon={true}
                bordered={false}
                height="4.33rem"
                width="4.33rem"
                onClick={() => history.goBack()}>
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <div className="container position-relative">
                <h1 className="text-center sm mb-5">
                    Enter your email
                </h1>
                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-md-4">
                        <Input
                            value={email}
                            placeholder="Enter your email"
                            secondary={true}
                            error={emailError ? true : false}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyUp={(e) => {if (e.keyCode === 13) reset(email)}}/>
                    </div>
                    <span className="error-message">{emailError}</span>
                </div>
                <div className="d-flex justify-content-center pt-1">
                    <Button
                        type="secondary"
                        width={214}
                        disabled={isSent}
                        onClick={() => reset(email)}>
                            Reset
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default withAlert(ResetPassword)