import React, { useRef, useState } from 'react'
import { useAuth } from '../../../hooks'
import { useWindowSize } from '../../../hooks'
import { Button, Icon } from '../../../components/theme'

import styles from './index.module.scss'

import { HOME_HEADER } from '../../../content'

const Header = () => {
    const {user} = useAuth()
    const header = useRef(null)
    const [videoPlayer, setVideoPlayer] = useState(null)
    const { width } = useWindowSize()

    const scrollToBottom = () => {
        let distance

        if (width > 1200) {
            distance = 7.5 * 24
        } else {
            distance = 7.5 * 18
        }

        window.scrollTo({
            top: header.current.clientHeight + distance,
            behavior: "smooth"
        })
    }

    const initPlayer = (ref) => {
        if (!ref) {
            return
        }

        setVideoPlayer(ref)
        if (videoPlayer) {
            videoPlayer.setAttribute('muted', 'true')
            videoPlayer.play()
        }
    }

    return (
        <header
            className={styles.header}
            ref={header}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <h1>
                                {HOME_HEADER.title}
                            </h1>
                            <p>
                                {HOME_HEADER.description}
                            </p>
                        </div>
                    </div>
                    <div className={`row justify-content-center ${styles.video}`}>
                        <div className="col-md-10 d-flex justify-content-center">
                            <iframe
                                title="Video"
                                src="https://player.vimeo.com/video/290695229?speed=1"
                                frameBorder="0"/>
                        </div>
                    </div>
                </div>
                <Button
                    type="secondary"
                    icon={true}
                    circle={true}
                    onClick={scrollToBottom}>
                        <Icon
                            type="arrow-bottom"/>
                </Button>
        </header>
    )
}

export default Header