import React, { useState } from 'react'
import Alert from '../components/Alert'

const withAlert = Component => (props) => {
    const [showAlert, toggleAlert] = useState(false)

    return (
        <>
            {showAlert && (
                <Alert
                    {...showAlert}
                    close={() => toggleAlert(false)}
                />
            )}
            <Component
                {...props}
                Alert={(props) => toggleAlert(props)}
                alertVisible={showAlert ? true : false}
            />
        </>
    )
}

export default withAlert