import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import Link from '../Link'
import Modal from '../Modal'
import { withAlert } from '../../hocs'
import { useExplorer } from '../../hooks'
import { Button, Icon, Input } from '../../components/theme'
import styles from './index.module.scss'

const ChangePasswordModal = ({
    hide,
    explorer,
    Alert
}) => {
    const {update, updateData, updateError} = useExplorer()
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [error, setError] = useState({which: '', message: ''})

    const submit = () => {
        if (!oldPassword || !newPassword) {
            return setError({which: 'both', message: 'Missing old or/and new password(s)'})
        }
        const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        if (!regex.test(newPassword)) {
            return setError({which: 'new', message: 'New password must contain at least one uppercase, lowercase letter(s), number(s) and minimum 8 characters'})
        }
        if (oldPassword === newPassword) {
            return setError({which: 'both', message: 'Same password'})
        }
        setError({which: '', message: ''})
        update({_id: explorer._id, oldPassword, password: newPassword})
    }

    useEffect(() => {
        const body = document.body
        body.className = ' no-scroll'
        
        return () => [
            body.className = ''
        ]
    }, [])

    useEffect(() => {
        if (!updateData) return
        setTimeout(() => {
            hide()
        }, 3000);
        Alert({
            title: 'Password changed successfully'
        })
    }, [updateData])

    useEffect(() => {
        if (!updateError) return
        if (updateError.response && updateError.response.data.message) {
            return setError({which: '', message: updateError.response.data.message})
        }
        Alert({
            title: 'Network Error'
        })
    }, [updateError])

    return (
        <Modal>
            <section className={styles.section}>
                <Helmet>
                    <title>Curator | RAD | Change Password</title>
                </Helmet>
                <Button
                    type="primary"
                    height="4.33rem"
                    width="4.33rem"
                    icon={true}
                    bordered={false}
                    circle={true}
                    onClick={hide}>
                        <Icon
                            width="1.5rem"
                            type="close"
                        />
                </Button>
                <div className="container">
                    <div>
                        <div className="row align-items-center flex-column">
                            <div className="col-md-8 mb-4">
                                <h1 className="sm">Change your password</h1>
                            </div>
                            <div className="col-md-4 pt-5 text-center">
                                <Input
                                    placeholder="Enter your password"
                                    type="password"
                                    value={oldPassword}
                                    error={error.which === 'both'}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    onKeyUp={(e) => { if (e.keyCode === 13) submit() }}/>
                                <Link href="/reset/password">
                                    I forgot my password
                                </Link>
                                <Input
                                    placeholder="Choose new password"
                                    type="password"
                                    value={newPassword}
                                    error={error.which === 'both' || error.which === 'new'}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    onKeyUp={(e) => { if (e.keyCode === 13) submit() }}/>
                                {error.message && (
                                    <span className="error-message mt-1">{error.message}</span>
                                )}
                                <Button
                                    type="secondary"
                                    width={107}
                                    onClick={submit}>
                                        Done
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Modal>
    )
}

export default withAlert(ChangePasswordModal)