import React, { useState, useEffect } from 'react'
import Link from '../../../components/Link'
import Image from '../../../components/Image'
import { useWindowSize } from '../../../hooks'
import { Button, Icon } from '../../../components/theme'
import Avatar from '../../../components/Avatar'
import DeleteActivity from './DeleteActivity'
import { DEFAULT_ACTIVITY_COVER } from '../../../constants'
import styles from './index.module.scss'

const Header = ({
    activity = {},
    preview,
    byMe
}) => {
    const {width} = useWindowSize()
    const [videoPlayer, setVideoPlayer] = useState(null)
    const [sticky, toggleSticky] = useState(false)
    const [coverUrl, setCoverUrl] = useState('')

    const initPlayer = (ref) => {
        if (!ref) {
            return
        }

        setVideoPlayer(ref)
        if (videoPlayer) {
            videoPlayer.setAttribute('muted', 'true')
            videoPlayer.play()
        }
    }

    const onScroll = () => {
        if (!width) return
        if (window.scrollY > window.innerHeight * (width > 720 ? 0.83 : 0.50)) {
            toggleSticky(true)
            if (videoPlayer) videoPlayer.pause()
        } else {
            toggleSticky(false)
            if (videoPlayer) videoPlayer.play()
        }
    }

    useEffect(() => {
        if (width) {
            window.addEventListener('scroll', onScroll)
        }

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [width])
    
    useEffect(() => {
        if (preview && !activity.name) {
            setCoverUrl(DEFAULT_ACTIVITY_COVER.standard_resolution.url)
            return
        }
        if (!activity || !activity.name) {
            setCoverUrl('')
            return
        }
        if (activity.medias && activity.medias[0] && activity.medias[0].standard_resolution) {
            const cover = activity.medias.find(media => media.cover)
            setCoverUrl(cover ? cover.standard_resolution.url : activity.medias[0].standard_resolution.url)
            return
        } else {
            setCoverUrl(DEFAULT_ACTIVITY_COVER.standard_resolution.url)
        }
    }, [activity, preview])

    return (
        <header
            className={styles.header}
            data-sticky={sticky}>
                {coverUrl && (
                    <Image
                        src={coverUrl}
                        options="w_2000,f_auto"
                        alt={`activity image `}
                        fromCloudinary={false}
                    />
                )}
                {sticky && (
                    <div className={styles.sticky}>
                        <div className="container">
                            <h4>{activity ? activity.name : ''}</h4>
                            {activity && activity.bookingUrl  && (
                                <div>
                                    {activity.asLowAsPrice && !isNaN(activity.asLowAsPrice) ? (
                                        <span className="mr-2">
                                            From <span>${activity.asLowAsPrice}</span> Per person
                                        </span>
                                    ) : ''}
                                    <a
                                        href={activity.bookingUrl}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            <Button
                                                type="secondary"
                                                style={{ width: '8rem' }}>
                                                    Book now
                                            </Button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {activity && activity.activity_video_url && (
                    <div className={styles.videoCoverContainer}>
                        <video
                            playsInline
                            muted
                            autoPlay
                            loop
                            preload="auto"
                            poster={activity.activity_image_url}
                            ref={initPlayer}>
                                <source
                                    src={activity.activity_video_url}
                                    type={'video/mp4'}/>
                        </video>
                    </div>
                )}
                <div
                    className="container"
                    style={{ zIndex: 1 }}>
                        <div className={`row ${styles.info}`}>
                            <div className="col-md-8">
                                <h1 className="sm d-flex">
                                    {activity ? activity.name : ''}
                                    {byMe && activity._explorer && activity._explorer._id && (
                                        <>
                                            <Link
                                                href={`/edit/activity/${activity._id}`}
                                                className="ml-2">
                                                    <Button
                                                        type="secondary"
                                                        bordered={false}
                                                        icon={true}
                                                        width={40}
                                                        height={40}>
                                                            <Icon
                                                                type="edit"
                                                            />
                                                    </Button>
                                            </Link>
                                            <DeleteActivity activity={activity}/>
                                        </>
                                    )}
                                </h1>
                                <div className={styles.activityCreator}>
                                    <Link href={`/e/${activity && activity._explorer && (activity._explorer.email || activity._explorer._id)}`}>
                                        <Avatar
                                            width={60}
                                            height={60}
                                            profilePicture={activity && activity._explorer ? activity._explorer.profile_picture : ''}
                                        />
                                        <span>
                                            by <span>{(activity && activity._explorer) ? activity._explorer.full_name : ''}</span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            {activity && activity.radScore && (
                                <div className={`col-md-3 ${styles.radScore}`}>
                                    <span>RAD</span>
                                    &nbsp;<i>score</i>&nbsp;
                                    <span>{activity.radScore}</span>
                                </div>
                            )}
                            {activity && activity.bookingUrl  && (
                                <div
                                    className={`col-md-3 ${styles.bookSection}`}
                                    data-price={activity.asLowAsPrice && !isNaN(activity.asLowAsPrice) ? true : false}>
                                        {activity.asLowAsPrice && !isNaN(activity.asLowAsPrice) ? (
                                            <>
                                                <span>From</span>
                                                <span className="mt-1">${activity.asLowAsPrice}</span>
                                                <span className="mt-1">Per Person</span>
                                            </>
                                        ) : ''}
                                        <a
                                            href={activity.bookingUrl}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                                <Button
                                                    type="secondary"
                                                    style={{ width: '8.9rem' }}>
                                                        Book Now
                                                </Button>
                                        </a>
                                </div>
                                )}
                        </div>
                </div>
        </header>
    )
}

export default Header