import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import Link from '../../../components/Link'
import Slide from '../../../components/Slide'
import Loader from '../../../components/Loader'
import { Button } from '../../../components/theme'
import { useWindowSize, useNavigate } from '../../../hooks'
import { DEFAULT_ACTIVITY_COVER } from '../../../constants'
import styles from './index.module.scss'

const Slider = ({
    activities: allActivities = [],
    guide,
    activityHeader,
    allActivitiesCount = 0
}) => {
    const navigate = useNavigate()
    const activities = [...allActivities, { medias: [], description: '', name: '', _id: '' }]
    const [swiper, setSwiper] = useState(null)
    const [nextProgress, setNextProgress] = useState(0)
    const [prevProgress, setPrevProgress] = useState(100)
    const [disableSwipe, setDisableSwipe] = useState(false)
    const [index, setIndex] = useState(0)
    const offset = activityHeader.offsetLeft + 30
    const { width } = useWindowSize()
    const params = {
        updateOnWindowResize: true,
        slidesPerView: width > 720 ? 3.8 : 1.5,
        spaceBetween: 30,
        slidesOffsetBefore: offset,
        slidesOffsetBeforeAfter: offset,
        allowSlideNext: width < 720 ? true : activities.length > 3,
        allowSlidePrev: width < 720 ? true : activities.length > 3,
    }

    const goNext = () => {
        if (swiper && !disableSwipe) {
            if (!params.allowSlideNext) return
            const realIndex = swiper.realIndex + 1
            const nextImagesCount = activities.length - 3
            const oneSector = 100 / nextImagesCount
            if (nextImagesCount - realIndex > 0) {
                setNextProgress(100 - (oneSector * (nextImagesCount - realIndex)))
                setPrevProgress(prevProgress - oneSector)
            } else if (nextImagesCount - realIndex === 0) {
                setPrevProgress(0)
                setNextProgress(100)
            }

            swiper.slideNext()
        }
    }
     
    const goPrev = () => {
        if (swiper && !disableSwipe) {
            const realIndex = swiper.realIndex - 1
            const nextImagesCount = activities.length - 3
            const oneSector = 100 / nextImagesCount
    
            if (nextImagesCount - realIndex > 0 && realIndex > 0) {
                setPrevProgress(oneSector * (nextImagesCount - realIndex))
                setNextProgress(nextProgress - oneSector)
            } else if (nextImagesCount - realIndex === nextImagesCount) {
                setNextProgress(0)
                setPrevProgress(100)
            }
            swiper.slidePrev()
        }
    }

    useEffect(() => {
        if (swiper) {
            swiper.on('slideChange', () => {
                setIndex(swiper.realIndex)
                if (swiper.realIndex + 3 === activities.length) {
                    swiper.update()
                    return
                }
            })

            swiper.on('transitionStart', () => setDisableSwipe(true))
            swiper.on('transitionEnd', () => setDisableSwipe(false))
        }
    }, [swiper, setIndex])

    useEffect(() => {
        if (swiper) {
            setTimeout(() => {
                swiper.slideToLoop(index)
            })
        }
    }, [swiper, index])

    return (
        <section className={styles.section}>
            {activities.length === 1 ? (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <span className={styles.nothingShow}>
                                It looks like you haven't added any experiences.
                                <Link
                                    href={
                                        `${allActivitiesCount > 0 ?
                                        `/guide/${guide._id}/activities` :
                                        `/create/activity?city=${JSON.stringify(guide.city)}&_city=${guide._city}&guide=${guide._id}`}`
                                    }>
                                        Click here to add one!
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="row justify-content-center slider w-100"
                    style={{ height: '21.5rem' }}>
                        {width && (
                            <Swiper {...params} getSwiper={setSwiper}>
                                {activities.map((activity, index) => (
                                    <div
                                        className={`slide-container ${styles.slide}`}
                                        key={`activity-image-${index}`}>
                                            {swiper && (
                                                <div onClick={() => navigate(`/activity/${activity._id}`)}>
                                                    {activity.name && (
                                                        <Slide
                                                            index={index}
                                                            lastIndex={activities.length}
                                                            image={
                                                                activity.medias[0] ?
                                                                activity.medias.find(media => media.cover) || activity.medias[0] :
                                                                DEFAULT_ACTIVITY_COVER
                                                            }
                                                            style={{ height: '15rem' }}
                                                            swiper={swiper}
                                                            shadow={true}/>
                                                    )}
                                                    <span className="mt-2 d-block">{activity.name}</span>
                                                    <p className="mt-1">
                                                        {activity.description ? activity.description.substring(0, 140) : ''}
                                                        {activity.description ? activity.description.length > 140 ? ' ...' : '' : ''}
                                                    </p>
                                                </div>
                                            )}
                                    </div>
                                ))}
                            </Swiper>
                        )}
                        {swiper && activities.length > 3 && ([
                            <Button
                                key={`swiper-navigation-0`}
                                className={styles.navigation}
                                icon={true}
                                bordered={false}
                                type="secondary"
                                circle={false}
                                onClick={goPrev}
                                style={{ left: offset - 40 }}>
                                    <Loader
                                        progress={prevProgress}/>
                            </Button>,
                            <Button
                                key={`swiper-navigation-1`}
                                className={styles.navigation}
                                bordered={false}
                                icon={true}
                                type="secondary"
                                circle={false}
                                onClick={goNext}
                                style={{ right: offset - 40 }}>
                                    <Loader
                                        progress={nextProgress}/>
                            </Button>
                        ])}
                </div>
            )}
        </section>
    )   
}

export default Slider