import React, { useState, useRef, useEffect } from 'react'
import { Button, Icon } from '../../../../components/theme'
import { DURATIONS } from '../../../../constants'
import styles from './index.module.scss'

const DurationModal = ({
    duration,
    setDuration = () => {}
}) => {
    const durationSection = useRef(null)
    const [modalVisible, toggleModal] = useState(false)

    const durationSectionOutsideClick = (e) => {
        if (
            e.target === durationSection.current ||
            e.target === document.querySelector('h2') ||
            durationSection.current.contains(e.target)
        ) {
            return
        }

        setTimeout(() => {
            toggleModal(false)
        }, 100)
    }

    useEffect(() => {
        window.addEventListener('click', durationSectionOutsideClick)
        window.addEventListener('touchend', durationSectionOutsideClick)

        return () => {
            window.removeEventListener('click', durationSectionOutsideClick)
            window.removeEventListener('touchend', durationSectionOutsideClick)
        }
    }, [])

    return (
        <div className={styles.heading}>
            <label>
                Avg. Activity Duration (Minutes)
            </label>
            <div
                ref={durationSection}
                data-changable={modalVisible}
                onClick={() => toggleModal(!modalVisible)}>
                    <span>
                        {duration}
                    </span>
                    <Button
                        type={`${modalVisible ? 'primary' : 'secondary'}`}
                        icon={true}
                        width={40}
                        height={40}
                        innerStyle={{ padding: '0 15px' }}>
                            <Icon
                                type="arrow-bottom"
                                width="15"
                                height="18"
                            />
                    </Button>
                    {modalVisible && (
                        <div className={styles.durationDropDown}>
                            {DURATIONS.map((d, i) => (
                                <div key={`select-duration-${i}-${d}`}>
                                    <span
                                        data-active={d === duration}
                                        onClick={() => {
                                            setDuration(d)
                                            toggleModal(false)
                                        }}>
                                            {d}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default DurationModal