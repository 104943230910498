import React from 'react'
import GoogleStreetview from 'react-streetview'
import { Button, Icon } from '../../theme'
import { useWindowSize } from '../../../hooks'
import config from '../../../config'

const StreetView = ({
    mapView,
    selectedActivity,
    sa,
    toggleSupportStreet,
    supportStreet,
    toggleView
}) => {
    const {width} = useWindowSize()

    const checkStreetView = (timeOut = 1200) => {
        setTimeout(() => {
            const mapScene = document.querySelector('.widget-scene-imagery-render')
            toggleSupportStreet(mapScene ? mapScene.style.display === 'none' : false)
        }, timeOut)
    }

    const getStreetViewPosition = () => {
        if (!sa.google_info.coords && !sa.google_info.geometry) return
        const coordinates = sa.google_info.coords || sa.google_info.geometry.location
        const lat = coordinates.lat.toFixed(4) * 1
        const lng = coordinates.lng.toFixed(4) * 1
        return {lat, lng}
    }

    const panoChange = () => {
        setTimeout(() => {
            const fullScreenControl = document.querySelector('.gm-fullscreen-control')
            const bundledControl = document.querySelector('.gm-bundled-control')
            const gmnoprintSvg = document.querySelector('.gmnoprint > svg')
            const gmAddress = document.querySelector('.gm-iv-address')
            const gmStyleFooterIcons = document.querySelectorAll('.gm-style-cc')
            if (mapView && fullScreenControl && bundledControl && gmnoprintSvg && gmAddress && gmStyleFooterIcons.length > 0) {
                gmStyleFooterIcons.forEach(icon => {
                    icon.style.display = 'none'
                })
                fullScreenControl.style.display = 'none'
                bundledControl.style.display = 'none'
                gmnoprintSvg.style.display = 'none'
                gmAddress.style.display = 'none'
            }
            checkStreetView()
        }, 0)
    }

    if (width < 970) {
        toggleSupportStreet(false)
        return <></>
    }

    return (
        <>
            <div
                className="street-view"
                key={`${mapView}-${selectedActivity._id}`}>
                    <GoogleStreetview
                        key={`mapView ${selectedActivity._id}`}
                        apiKey={config.googleApiKey}
                        streetViewPanoramaOptions={{
                            position: getStreetViewPosition(),
                            pov: { heading: 100, pitch: 0 },
                            zoom: 1,
                            scrollwheel: !mapView,
                            streetViewControl: false,
                            motionTrackingControl: false,
                            motionTracking: false,
                            pano_changed: () => panoChange(),
                        }}/>
            </div>
            {supportStreet && (
                <Button
                    type="primary"
                    bordered={false}
                    flex={`${!mapView ? 'end' : 'start'}`}
                    width="12.5rem"
                    onClick={() => toggleView(!mapView)}>
                        <div
                            className="toggle-view-pin"
                            data-end={mapView}>
                                <Icon
                                    type={!mapView ? 'pin' : 'eye'}
                                    width={20}
                                />
                        </div>
                        Switch to {!mapView ? 'map' : 'street'} view
                </Button>
            )}
        </>
    )
}

export default StreetView