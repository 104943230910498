import React, { useEffect, useState, useRef } from 'react'
import Helmet from 'react-helmet'
import Link from '../../components/Link'
import Loading from '../../components/Loading'
import Nav from '../../components/Nav'
import Slider from './Slider'
import Header from './Header'
import { useAuth, useNavigate } from '../../hooks'
import { Button, Line } from '../../components/theme'
import ShareModalButton from '../../components/ShareModal/button'
import Map from '../../components/Map'
import http from '../../http'
import styles from './index.module.scss'

const Guide = ({
    match,
    toggleMenu
}) => {
    const {user} = useAuth()
    const [guide, setGuide] = useState(undefined)
    const [activitiesCount, setActivitiesCount] = useState(0)
    const activityHeader = useRef(null)
    const navigate = useNavigate()

    const getGuide = () => {
        const params = {id: match.params.id, with: '_activities'}
        http
            .get('/api/guide', {params})
            .then(
                (response) => {
                    if (!response.data.guide) {
                        navigate('/404')
                        return
                    }
                    setGuide(response.data.guide)
                }
            )
            .catch(
                (e) => {
                    console.log(e)
                    navigate('/404')
                    setGuide({})
                }
            )
    }

    const getActivitiesCount = () => {
        const params = {_explorer: user._id}
        http
            .get('/api/activities/count', {params})
            .then(response => setActivitiesCount(response.data.count))
    }
    
    useEffect(() => {
        getGuide()
    }, [match.params.id])

    useEffect(() => {
        if (guide && guide._explorer._id === user._id) {
            getActivitiesCount()
        }
    }, [guide])

    return (
        <main className={styles.main}>
            <Loading
                style={{ zIndex: 11 }}
                status={guide === undefined ? 'loading' : 'loaded'}
            />
            <Helmet>
                <title>Curator | RAD | Guide</title>
            </Helmet>
            <Nav toggleMenu={toggleMenu}/>
            <Header
                byMe={guide && guide._explorer._id === user._id}
                guide={guide}/>
            <section className={styles.about}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2">
                            <ShareModalButton
                                width="4.44rem"
                                height="4.44rem"
                                iconWidth={23}
                                iconHeight={23}
                                url={`/guide/${guide && guide._id}`}
                                page="guide"
                                id={guide && guide._id}
                            />
                        </div>
                        <div className="col-md-7">
                            <h2 className="sm">{(guide && guide.city) ? guide.city.name : ''}</h2>
                            {guide && guide.description && (
                                <p className={styles.description}>
                                    {guide ? guide.description : ''}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <div className="container">
                <div className="row justify-content-center">
                    <div
                        className="col-md-8"
                        ref={activityHeader}>
                            <Line/>
                            <div className={styles.activitiesHeading}>
                                {(guide && guide._id && guide._explorer._id === user._id) && (
                                    <Link href={`/guide/${guide._id}/activities`}>
                                        <Button
                                            type="secondary">
                                                Add experiences
                                        </Button>
                                    </Link>
                                )}
                            </div>
                    </div>
                </div>
            </div>
            {activityHeader.current && guide && guide._activities && (
                <Slider
                    allActivitiesCount={activitiesCount}
                    activities={guide._activities}
                    guide={guide}
                    activityHeader={activityHeader.current}/>
            )}
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <Line style={{ marginTop: '3.33rem' }}/>
                    </div>
                </div>
            </div> */}
            {guide && guide._activities && guide._activities.length > 0 && (
                <section className={styles.map}>
                    <Map
                        query={{ dataset: 'raleighanddrake_la' }}
                        activities={guide._activities}
                        selectedActivity={guide._activities[0]}
                        scrollable={true}
                        showRoute={false}
                        height="36rem"
                        streetView={true}
                        activitiesSlider={true}
                        guidePage={true}/>
                </section>
            )}
            {/* <section className={styles.guides}>
                <div>
                    <h3 className="sm">More Guides from Los Angeles</h3>
                    <span>(8 guides)</span>
                </div>
                <img
                    src="/images/guides.png"
                    alt="Guides"/>
            </section> */}
        </main>
    )
}

export default Guide