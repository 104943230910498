import React from 'react'
import Helmet from 'react-helmet'
import Link from '../../components/Link'
import Types from '../../components/YouAreDoneTypes'
import { Button, Icon } from '../../components/theme'
import { useAuth } from '../../hooks'
import styles from './index.module.scss'

const YouAreDone = () => {
    const {user} = useAuth()
    return (
        <section className={styles.section}>
            <Helmet>
                <title>Curator | RAD | You Are Done</title>
            </Helmet>
            <div className={styles.header}>
                <Link href={`/e/${user.email}`}>
                    Go to profile
                </Link>
                <Button
                    icon={true}
                    bordered={false}
                    circle={true}
                    width={80}
                    height={80}>
                        <Icon
                            type="close"/>
                </Button>
            </div>
            <div
                className="container"
                style={{ paddingTop: '3.9rem' }}>
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <h1 className="sm">
                                You’re done! Now share more of your knowledge with the world
                            </h1>
                        </div>
                    </div>
                    <Types/>
            </div>
            <div className="d-flex justify-content-center mt-5">
                <Button
                    type="secondary"
                    width="10rem">
                        Share your guide
                </Button>
            </div>
        </section>
    )
}

export default YouAreDone