import React from 'react'
import styles from './index.module.scss'

const List = ({
    data = []
}) => {
    return (
        <div className={styles.list}>
            <ol>
                {data.map((d, i) => (
                    <li
                        key={i}
                        style={{ marginBottom: '80px' }}>
                            <span>0{i + 1}</span>
                            <div>
                                {d.title && (
                                    <h4>{d.title}</h4>
                                )}
                                <p>{d.description}</p>
                                {d.listItems && (
                                    <ul>
                                        {d.listItems.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default List