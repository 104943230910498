import React, { useState } from 'react'
import ProfilePicture from '../../ProfilePicture'
import { withPlace } from '../../../hocs'
import { Input, DropDown } from '../../theme'
import styles from './index.module.scss'
import FooterButtons from '../FooterButtons'

const SecondStep = ({
    explorer = {},
    onChange,
    placeChange,
    choosePlace,
    nextRoute
}) => {
    const [name, setName] = useState(explorer.full_name)
    const [city, setCity] = useState(explorer.city ? explorer.city.name : '')
    const [citySuggestions, setCitySuggestions] = useState([])

    const onCityChange = (value) => {
        setCity(value)
        placeChange(value)
            .then(response => setCitySuggestions(response))
            .catch(e => {
                setCitySuggestions([])
                console.log(e.message || e)
            })
    }

    const onChooseCity = (data) => {
        choosePlace(data)
            .then(response => {
                setCitySuggestions([])
                setCity(response.placeName)
                onChange({...response})
            })
            .catch(e => console.log(e.message || e))
    }

    return (
        <section className={styles.section}>
            <div className={`container fadeIn ${styles.secondStep}`}>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <h1 className="text-center">
                            Relax, you don’t need your passport for this.
                        </h1>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className={styles.info}>
                            <h2 className="sm">
                                Tell us your name
                            </h2>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => onChange({ full_name: name })}
                                onKeyUp={(e) => { if (e.keyCode === 13) onChange({ full_name: name }) }}/>
                        </div>
                        <div className={styles.info}>
                            <h2 className="sm">
                                and what city you live in
                            </h2>
                            <Input
                                value={city}
                                onChange={(e) => onCityChange(e.target.value)}/>
                            <DropDown
                                suggestions={citySuggestions}
                                choose={onChooseCity}
                                onOutsideClick={() => setCitySuggestions([])}
                                width="20rem"
                            />
                        </div>
                        <div className={styles.profilePicture}>
                            <ProfilePicture
                                onChange={onChange}
                                explorer={explorer}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterButtons
                nextRoute={nextRoute}
            />
        </section>
    )
}

export default withPlace(SecondStep)