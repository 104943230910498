import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
import { Icon, Editable, DropDown, Button } from '../../components/theme'
import { withPlace } from '../../hocs'
import Loading from '../../components/Loading'
import { useAuth, useNavigate, useOrganization } from '../../hooks'
import ActivityPreview from '../Activity'
import UploadImages from './UploadImages'
import Hashtags from './Hashtags'
import Info from './Info'
import http from '../../http'
import config from '../../config'
import styles from './index.module.scss'

const CreateActivity = ({
    placeChange,
    choosePlace,
}) => {
    const urlParams = (new URL(document.location)).searchParams
    const {id} = useParams()
    const history = useHistory()
    const navigate = useNavigate()
    const {user, logout} = useAuth()
    const {organization} = useOrganization()
    const [hasPermission, togglePermission] = useState()
    const [loading, setLoading] = useState(id ? true : false)
    const [imagesProcessing, setImagesProcessing] = useState(false)
    const [cityName, setCityName] = useState('')
    const [fullScreenPreview, setFullScreenPreview] = useState(false)
    const [cities, setCities] = useState([])
    const [cityError, setCityError] = useState('')
    const [activityError, setActivityError] = useState('')
    const [activity, setActivity] = useState({
        tags: [],
        name: '',
        google_info: {},
        city: {},
        cover: {},
        _explorer: user,
        category: 'eat'
    })

    const onCityChange = (value) => {
        placeChange(value)
            .then(response => setCities(response))
            .catch(e => {
                setCities([])
                console.log(e.message || e)
            })
    }

    const toggleFullScreenPreview = () => {
        setFullScreenPreview(!fullScreenPreview)

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const onChooseCity = (data) => {
        setCityError('')
        choosePlace(data)
            .then(response => {
                setCities([])
                if (activity.name) {
                    removeActivityInfo(response)
                    return
                }
                setCityName(response.placeName)
                setActivity({...activity, ...response})
            })
            .catch(e => console.log(e.message || e))
    }


    const removeActivityInfo = (data = {}) => {
        const newActivity = {...activity}
        newActivity.name = ''
        newActivity.google_info = {}
        newActivity.tags = []
        newActivity.category = 'eat'
        newActivity.description = ''
        newActivity.tip = ''
        newActivity.medias = []
        newActivity.tags = []
        newActivity.bookingUrl = ''
        newActivity.asLowAsPrice = ''
        
        if (data._city) {
            newActivity._city = data._city
            newActivity.city = data.city
            newActivity.loc = data.loc
            setCityName(data.placeName)
        }

        setActivity({...newActivity})
    }

    const goBack = () => {
        if (urlParams.has('redirect_url')) {
            return window.location.href = urlParams.get('redirect_url')
        }
        history.goBack()
    }

    const getActivity = () => {
        const hasAccessToEdit = organization && organization.permissions && organization.permissions['editActivity']
        const params = {_id: id}
        http
            .get('/api/activity', {params})
            .then(
                (response) => {
                    if ((response.data.activity && response.data.activity._explorer._id === user._id) || hasAccessToEdit) {
                        return setActivity(response.data.activity)
                    }
                    history.push('/404')
                }
            )
            .catch(e => console.log(e, ' get activity error'))
            .finally(() => setLoading(false))
    }

    const save = () => {
        if (!activity._city) {
            setCityError('Pick Your City')
            return
        }

        if (!activity.name) {
            setActivityError('Please enter the name of the experiences you are reviewing')
            return
        }
        setLoading(true)
        const newActivity = {...activity}
        newActivity.medias.forEach(media => {
            delete media.blobUrl
            delete media.key
        })
        if (newActivity.__v !== undefined) {
            return http
                .put(`/api/activity/${newActivity._id}`, newActivity)
                .then(
                    () => {
                        setLoading(false)
                        if (organization) {
                            logout({preventDefault: () => {}}, false)
                        }
                        if (urlParams.has('prev_path')) {
                            return navigate(`${window.location.search.split('prev_path=')[1]}&activity=${newActivity._id}`)
                        }
                        if (urlParams.has('redirect_url')) {
                            return window.location.href = urlParams.get('redirect_url')
                        }
                        if (urlParams.has('guide')) {
                            return navigate(`/guide/${urlParams.get('guide')}/activities?activity=${newActivity._id}`)
                        }
                        navigate(`/activity/${newActivity._id}`)
                    }
                )
                .catch(
                    (e) => {
                        alert(e.message || 'Network error')
                        setLoading(false)
                    }
                )
        }
        delete newActivity._id
        newActivity._explorer = urlParams.has('explorer_id') ? urlParams.get('explorer_id') : user._id
        newActivity.created_date = new Date()
        newActivity.updated_date = new Date()
        const updateUserCityData = {
            option: 'add',
            city: activity.city
        }
        const userId = organization && urlParams.has('explorer_id') ? urlParams.get('explorer_id') : user._id
        Promise
            .all([
                http.post('/api/activity', newActivity),
                http.put(`/api/explorer/cities/${userId}`, updateUserCityData),
            ])
            .then(
                ([activityResponse]) => {
                    setActivity(activityResponse.data.activity)
                    if (organization) {
                        logout({preventDefault: () => {}}, false)
                    }
                    if (urlParams.has('prev_path')) {
                        return navigate(`${window.location.search.split('prev_path=')[1]}&activity=${activityResponse.data.activity._id}`)
                    }
                    if (urlParams.has('redirect_url')) {
                        return window.location.href = urlParams.get('redirect_url')
                    }
                    if (urlParams.has('guide')) {
                        return navigate(`/guide/${urlParams.get('guide')}/activities?activity=${activityResponse.data.activity._id}`)
                    }
                    navigate(`/activity/${activityResponse.data.activity._id}`)
                }
            )
            .catch(e => alert(e.message || 'Network error'))
            .finally(() => setLoading(false))
    }

    const checkPermissions = () => {
        if (!user._id && !organization) {
            return togglePermission(false)
        }
        if (user._id) {
            return togglePermission(true)
        }
        if (organization) {
            if (!organization.permissions['editActivity'] && id) {
                alert('Invalid token for editing activity')
                return togglePermission(false)
            }

            if (!organization.permissions['createActivity'] && !id) {
                alert('Invalid token for creating activity')
                return togglePermission(false)
            }

            if (organization.permissions['createActivity'] && !id && !urlParams.has('explorer_id')) {
                alert('Please provide `explorer_id` in url to create activity')
                return togglePermission(false)
            }

            if (!urlParams.has('redirect_url') && !urlParams.has('prev_path')) {
                alert('Please provide `redirect_url` in url.')
                return togglePermission(false)
            }
        }
        return togglePermission(true)
    }

    useEffect(() => {
        if (urlParams.has('city') && urlParams.has('_city')) {
            setActivity({
                ...activity,
                city: JSON.parse(urlParams.get('city')),
                _city: urlParams.get('_city')
            })
        }

        checkPermissions()
    }, [])

    useEffect(() => {
        if (!hasPermission || !id) return
        getActivity()
    }, [hasPermission, id])

    if (hasPermission === undefined) {
        return <></>
    }

    if (!hasPermission) {
        navigate('/sign-up')
    }

    return (
        <section className={styles.section}>
            <Loading
                style={{ zIndex: 11 }}
                status={!loading ? 'loaded' : 'loading'}/>
            <Helmet>
                <title>Curator | RAD | {id ? 'Edit' : 'Create'} Activity {id && activity ? `| ${activity.name}` : ''}</title>
            </Helmet>
            <div className={`container ${fullScreenPreview ? '' : 'position-relative'}`}>
                <Button
                    type="secondary"
                    bordered={false}
                    hoverEffect={false}
                    onClick={goBack}>
                        Back
                </Button>
                <div
                    className="position-relative"
                    style={{paddingTop: '4.5rem'}}
                    key={`${cityName}`}>
                        <div className="row">
                        <div className={`col-md-6 ${fullScreenPreview ? 'hidden' : ''}`}>
                                <h1>
                                    Let’s create an experience for
                                    <Editable
                                        tag="span"
                                        placeholder="your city"
                                        defaultValue={activity.city.name}
                                        editable={id ? false : true}
                                        error={cityError}
                                        extendStyle={true}
                                        bordered={true}
                                        onChange={onCityChange}/>
                                    <DropDown
                                        choose={onChooseCity}
                                        suggestions={cities}
                                        width="100%"
                                        onOutsideClick={() => setCities([])}
                                        onChange={(value) => onCityChange(value)}/>
                                </h1>
                            </div>
                        </div>
                </div>
                {activity._city && (
                    <div className="row" style={{paddingTop: 24}}>
                        <div className="col-md-6">
                            <Info
                                user={user}
                                activity={activity}
                                changeData={setActivity}
                                loading={loading}
                                save={save}
                                activityError={activityError}
                                imagesProcessing={imagesProcessing}
                                removeActivityInfo={removeActivityInfo}
                                setActivityError={setActivityError}
                                toggleFullScreenPreview={toggleFullScreenPreview}
                                onChange={data => setActivity(activity => ({...activity, ...data}))}/>
                        </div>
                        {activity.name && (
                            <div className="col-md-6">
                                <label>Step 2: Add Photos</label>
                                <UploadImages
                                    medias={activity.medias}
                                    setProcessing={setImagesProcessing}
                                    onChange={data => setActivity(activity => ({...activity, ...data}))}/>
                                <label className="mt-3">Step 3: Add Hashtags</label>
                                <Hashtags
                                    activity={activity}
                                    onChange={data => setActivity(activity => ({...activity, ...data}))}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={styles.preview}
                    style={{height: document.body.scrollHeight}}
                    data-full-screen-preview={fullScreenPreview}>
                        {fullScreenPreview && (
                            <ActivityPreview
                                preview={activity}
                            />
                        )}
                        <Button
                            type="secondary"
                            circle={true}
                            icon={true}
                            onClick={toggleFullScreenPreview}>
                                <Icon
                                    type="close-dark"
                                    width={20}
                                    height={20}/>
                        </Button>
                </div>
                <div className={styles.mobilePublish}>
                    <Button
                        type="primary"
                        width="7.5rem"
                        circle={true}
                        loading={loading}
                        onClick={save}
                        disabled={imagesProcessing}>
                            {!imagesProcessing ? 'Publish' : 'Processing ...'}
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default withPlace(CreateActivity)