import React from 'react'
import Link from '../../../components/Link'
import { useAuth } from '../../../hooks'
import { Button } from '../../../components/theme'
import styles from './index.module.scss'

const Impact = () => {
    const {user} = useAuth()
    return (
        <section className={styles.section}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 p-0">
                        <h5>
                            Your work can have impact
                        </h5>
                    </div>
                </div>
            </div>
            <div className={styles.impactSection}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={`col-md-8 ${styles.impactInfo}`}>
                            <div className="col-md-6">
                                <span>2.42%</span>
                                <p>
                                    <span>of each booking </span>
                                    fee goes to support local entrepreneurs in-destination via 
                                    <a
                                        href="https://www.kiva.org/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            Kiva.org
                                    </a>.
                                </p>
                                <Link href={`${user.email ? '/create/guide' : '/sign-up'}`}>
                                    <Button
                                        type="primary"
                                        borderDark={true}>
                                            {user.email ? (
                                                'Create a guide'
                                            ) : (
                                                'Join our tribe'
                                            )}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Impact