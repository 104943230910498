import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'
import Loading from '../../components/Loading'
import Nav from '../../components/Nav'
import { useAuth, useNavigate } from '../../hooks'
import Map from '../../components/Map'
import Header from './Header'
import Slider from './Slider'
import SimilarActivities from './SimilarActivities'
import Details from './Details'
import http from '../../http'
import styles from './index.module.scss'

const Activity = ({
    match,
    toggleMenu,
    preview = null,
}) => {
    const {user} = useAuth()
    const [activity, setActivity] = useState(undefined)
    const location = useLocation()
    const navigate = useNavigate()

    const getActivity = async () => {
        try {
            const params = { _id: match.params.id }
            const response = await http.get('/api/activity', { params })
            if (!response.data.activity) {
                return navigate('/404')
            }
            setActivity(response.data.activity)
        } catch (e) {
            // navigate('/404')
            setActivity({})
            console.log('Error getting activity')
            console.log(e)
        }
    }

    useEffect(() => {
        if (!preview) {
            window.scrollTo({top: 0})
            setActivity(undefined)
            getActivity()
        }
    }, [location])

    return (
        <main
            className={styles.main}>
            <Helmet>
                <title>Curator | RAD | Activity</title>
            </Helmet>
            {!preview && (
                <Loading
                    style={{ zIndex: 11 }}
                    status={activity ? 'loaded' : 'loading'}
                />
            )}
            {!preview && <Nav toggleMenu={toggleMenu}/>}
            {(activity || preview) && (
                <>
                    <Header
                        byMe={activity && activity._explorer && activity._explorer._id === user._id}
                        activity={preview || activity}
                        preview={preview}/>
                    <Details
                        activity={preview || activity}
                        preview={preview ? true : false}/>
                    <Slider
                        medias={
                            preview ?
                            preview.medias && preview.medias.length > 0 ? preview.medias : [] :
                            activity.medias && activity.medias.length > 0 ? activity.medias : []
                        }
                    />
                    {!preview && (
                        <>
                            <section style={{ paddingTop: '8rem' }}>
                                {activity._city && (
                                    <Map
                                        query={{ dataset: 'raleighanddrake_la' }}
                                        showRoute={true}
                                        cityAlias="los-angeles"
                                        selectedActivity={activity}
                                        activities={[activity]}
                                        height="30rem"
                                        streetView={activity.google_info ? true : false}/>
                                )}
                            </section>
                            <SimilarActivities activity={activity}/>
                        </>
                    )}
                </>
            )}
        </main>
    )
}

export default Activity