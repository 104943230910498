import React, { useState } from 'react'
import Link from '../../../components/Link'
import { Input, Button } from '../../../components/theme'
import http from '../../../http'
import { SIGN_UP } from '../../../content'
import styles from './index.module.scss'

const EmailSection = ({
    login,
    onChange
}) => {
    const [email, setEmail] = useState({value: '', error: ''})
    const [agree, setAgree] = useState({valid: false, error: false})
    const [formSubmitted, setFormSubmitted] = useState(false)

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const emailChange = (e) => {
        setEmail({
            error: !validateEmail(e.target.value) ? 'Invalid email' : (e.target.value === '') ? 'Email field is required' : '',
            value: e.target.value
        })
    }

    const submit = () => {
        setFormSubmitted(true)
        if (email.error) return

        if (!agree.valid) {
            setAgree({...agree, error: true})
            return
        }
        http
            .get('/api/email', { params: { email: email.value } })
            .then(
                (response) => {
                    if (response.data.exists) {
                        return setEmail({...email, error: 'This email has already been registered'})
                    }
                    onChange({ email: email.value, acceptedTerms: agree.valid })
                }
            )
            .catch(console.error)
            .finally(() => setFormSubmitted(false))
    }

    return (
        <section className={styles.section}>
            <h3>{SIGN_UP.second_title}</h3>
            <div className="row justify-content-center mt-3 mb-4">
                <div className="col-10 col-md-7">
                    <Button
                        type="secondary"
                        width="100%"
                        className="mb-1"
                        onClick={() => { login('google') }}>
                            Sign up WITH GOOGLE
                    </Button>
                    <Button
                        type="secondary"
                        width="100%"
                        onClick={() => { login('facebook') }}>
                            Sign up WITH FACEBOOK
                    </Button>
                </div>
            </div>
            <span>OR</span>
            <Input
                type="email"
                placeholder="Enter your email"
                className="mt-4"
                error={email.error && formSubmitted ? true : false}
                value={email.value}
                onKeyUp={(e) => {if (e.keyCode === 13) submit()}}
                onChange={emailChange}/>
            <p
                className="small text-center w-100"
                style={{ minHeight: 18 }}
                data-exists={email.error ? true : false}>
                    {email.error && formSubmitted && email.error}
            </p>
            <div className={styles.agree}>
                    <input
                        type="checkbox"
                        id="terms-service"
                        checked={agree.valid}
                        onChange={() => setAgree({error: '', valid: !agree.valid})}
                    />
                    <label htmlFor="terms-service">
                        I have read and agree to the <Link href="/terms"> Terms of Service </Link>
                    </label>
            </div>
            {agree.error && (
                <span className="error-message">Please agree terms and conditions to proceed</span>
            )}
            <div className="row justify-content-center">
                <div className="col-5">
                    <Button
                        type="primary"
                        onClick={submit}
                        width="100%"
                        className="mt-4">
                            Next
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default EmailSection