import { useCallback } from 'react'
import { useFetcher } from '../index'
import * as service from './service'

const useUpdate = () => {
    const { fetcher, loading, error, data } = useFetcher()

    const update = useCallback(
        (data) => {
            fetcher(
                service.update(data)
            )
        },
        []
    )

    return {
        loading,
        error,
        update,
        data
    }
}

export default useUpdate