import { useState, useCallback } from 'react'

const useFetcher = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [data, setData] = useState()

    const fetcher = useCallback(
        (promise) => {
            setLoading(true)
            promise
                .then(setData)
                .catch(setError)
                .finally(() => setLoading(false))
        },
        [setLoading, setData, setError]
    )

    return {
        fetcher,
        loading,
        data,
        error
    }
}

export default useFetcher