import React, { useState } from 'react'
import Alert from '../../../components/Alert'
import { Button, Icon } from '../../../components/theme'
import { useAuth, useNavigate } from '../../../hooks'
import http from '../../../http'

const DeleteActivity = ({activity}) => {
    const {updateUser} = useAuth()
    const navigate = useNavigate()
    const [popConfirm, setPopConfirm] = useState(false)

    const onDelete = () => {
        const explorerCityRequestData = {
            option: 'remove',
            city: activity.city
        }
        setPopConfirm(false)
        Promise
            .all([
                http.put(`/api/explorer/cities/${activity._explorer._id}`, explorerCityRequestData),
                http.delete(`/api/activity/${activity._id}`)
            ])
            .then(
                ([userResponse]) => {
                    updateUser(userResponse.data.user)
                    navigate(`/e/${userResponse.data.user.email}`)
                }
            )
            .catch(e => console.log(e, ' deleting activity error'))
    }

    return (
        <>
            {popConfirm && (
                <Alert
                    title="Are You Sure ?"
                    confirmation={true}
                    onSuccess={onDelete}
                    onCancel={() => setPopConfirm(false)}/>
            )}
            <Button
                type="secondary"
                bordered={false}
                className="ml-2"
                icon={true}
                width={40}
                height={40}
                onClick={() => setPopConfirm(true)}>
                    <Icon
                        type="trash"
                    />
            </Button>
        </>
    )
}

export default DeleteActivity
