import React from 'react'
import Link from '../../../components/Link'
import { useAuth } from '../../../hooks'
import { Button } from '../../../components/theme'
import Image from '../../../components/Image'
import List from '../../../components/List'
import { gettingStarted } from '../../../content'
import styles from './index.module.scss'

const HowItWorks = () => {
    const {user} = useAuth()
    return (
        <section className={styles.section}>
            <div className="container">
                <h5 className="lg">
                    How it works
                </h5>
                <h2 className="text-center">
                    Connect your passion for travel to a global travel audience
                </h2>
                <Link href={`/${user.email ? 'create/guide' : 'sign-up'}`}>
                    <Button
                        type="secondary">
                            {user.email ? (
                                'Create a guide'
                            ) : (
                                'Sign up'
                            )}
                    </Button>
                </Link>
                <div className="row justify-content-around">
                    <div className={`col-md-4 ${styles.imageSection}`}>
                        <Image
                            src="https://res.cloudinary.com/dbur3sjwh/image/fetch/w_800,f_auto/https://rad-live.s3.amazonaws.com/2017/4/20/20/d5f720997070049a1a095b07d2e00b21.jpg"
                            alt="How it works cover"
                            options="w_900,f_auto"
                        />
                    </div>
                    <div className="col-md-5">
                        <List
                            data={gettingStarted.data}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorks