import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useParams } from 'react-router-dom'
import Link from '../../components/Link'
import Loading from '../../components/Loading'
import http from '../../http'
import { Button } from '../../components/theme'
import { useAuth, useNavigate, useOrganization } from '../../hooks'
import Card from '../../components/Card'
import styles from './index.module.scss'

const GuideActivities = () => {
    const urlParams = (new URL(document.location)).searchParams
    const {user}  = useAuth()
    const {organization} = useOrganization()
    const {id} = useParams()
    const navigate = useNavigate()
    const [activities, setActivities] = useState(null)
    const [hasPermission, togglePermission] = useState()
    const [guide, setGuide] = useState({})

    const onBack = () => {
        if (urlParams.has('redirect_url')) {
            return window.location.href = urlParams.get('redirect_url')
        }

        navigate(`/guide/${guide._id}`)
    }
    
    const getGuide = () => {
        const params = {id}
        http
            .get('/api/guide', {params})
            .then(
                (response) => {
                    setGuide(response.data.guide)
                    getActivities(response.data.guide)
                    const urlParams = (new URL(document.location)).searchParams

                    if (urlParams.has('activity')) {
                        onChange(response.data.guide, urlParams.get('activity'), true) //justAdd
                        urlParams.delete('activity')
                    }
                }
            )
            .catch(e => console.log(e.message || e))
    }

    const getActivities = (guide) => {
        const params = {
            _city: guide._city,
            _explorer: urlParams.has('explorer_id') ? urlParams.get('explorer_id') : user._id,
            sortDirection: -1,
        }

        http
            .get('/api/activities', {params})
            .then(response => setActivities({limit: 12, all: response.data.activities}))
            .catch(
                (e) => {
                    setActivities({limit: 12, all: []})
                    console.log(e.message || e)
                }
            )
    }

    const onRemove = (id) => {
        if (guide._activities.includes(id)) {
            const newGuide = {...guide, _activities: guide._activities.filter(a => a !== id)}
            setGuide({...newGuide})
            update(newGuide)
        }
        setActivities(activities.filter(activity => activity._id === id))
    }

    const update = (guide) => {
        http
            .put(`/api/guide/${guide._id}`, {...guide})
            .then(
                (response) => {
                    setGuide(response.data.guide)
                    onBack()
                }
            )
            .catch(console.error)
    }

    const onChange = (guide, id, justAdd = false) => {
        if (guide._activities.includes(id) && !justAdd) {
            const newGuide = {...guide, _activities: guide._activities.filter(a => a !== id)}
            setGuide(newGuide)
            return
        }

        if (guide && guide._activities.includes(id)) {
            return
        }
        const newGuide = { ...guide, _activities: [...guide._activities, id] }
        setGuide(newGuide)
    }

    const checkPermissions = () => {
        if (!user._id && !organization) {
            return togglePermission(false)
        }
        if (user._id) {
            return togglePermission(true)
        }
        if (organization) {
            if (!organization.permissions['manageGuideActivities'] && id) {
                alert('Invalid `org_token` for managing guide')
                return togglePermission(false)
            }

            if (!urlParams.has('redirect_url')) {
                alert('Please provide `redirect_url` in url.')
                return togglePermission(false)
            }

            if (!urlParams.has('explorer_id')) {
                alert('Please provide `redirect_url` in url.')
                return togglePermission(false)
            }
        }
        return togglePermission(true)
    }

    useEffect(() => {
        checkPermissions()
    }, [])

    useEffect(() => {
        if (!hasPermission) return
        getGuide()
    }, [hasPermission])

    if (hasPermission === undefined) {
        return <></>
    }

    if (!hasPermission) {
        navigate('/sign-up')
    }

    const editActivityQueryParams = organization && hasPermission
        ? `?guide=${guide._id}&org_token=${urlParams.get('org_token')}&prev_path=${window.location.pathname + window.location.search}`
        : `?guide=${guide._id}`

    const createActivityPath = organization && hasPermission
        ? `/create/activity?guide=${guide._id}&city=${JSON.stringify(guide.city)}&_city=${guide._city}&org_token=${urlParams.get('org_token')}&prev_path=${window.location.pathname + window.location.search}`
        : `/create/activity?guide=${guide._id}&city=${JSON.stringify(guide.city)}&_city=${guide._city}`

    return (
        <section className={styles.section}>
            <Loading
                style={{ zIndex: 11 }}
                status={activities ? 'loaded' : 'loading'}
            />
            <div className="container">
                <div className={styles.headerButtons}>
                    <Button
                        className="mr-1"
                        width="8rem"
                        hoverEffect={false}
                        type="secondary"
                        onClick={onBack}>
                            Back
                    </Button>
                    <Button
                        className="mr-1"
                        width="8rem"
                        bordered={false}
                        hoverEffect={false}
                        type="primary"
                        onClick={() => update(guide)}>
                            Save
                    </Button>
                    
                </div>
                <h1 className="text-center sm">{guide.name}`s - Experiences</h1>
                <div className="d-flex justify-content-center mb-4">
                    {activities && activities.all.length > 0 && (
                        <Link href={createActivityPath}>
                            <Button
                                className="ml-1"
                                type="secondary"
                                width="10rem">
                                    Create new
                            </Button>
                        </Link>
                    )}
                </div>
                {activities && activities.all.length > 0 ? (
                    <>
                        <div className={styles.scrollerParent}>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={() => setActivities({...activities, limit: activities.limit + 6})}
                                hasMore={activities.limit < activities.all.length}
                                useWindow={true}>
                                    {activities.all.slice(0, activities.limit).map(activity => (
                                        <div
                                            key={activity._id}
                                            className="col-md-4 mb-3">
                                                <Card
                                                    type="activity"
                                                    size="sm"
                                                    guideId={guide._id}
                                                    editActivityQueryParams={editActivityQueryParams}
                                                    selectable={true}
                                                    selected={guide._activities.includes(activity._id)}
                                                    remove={onRemove}
                                                    data={activity}
                                                    onChange={(id) => onChange(guide, id)}
                                                    imageFromCloudinary={false}
                                                />
                                        </div>
                                    ))}
                            </InfiniteScroll>
                        </div>
                    </>
                ) : (
                    <span className={styles.nothingShow}>
                        It looks like you haven't created any activities.
                        <Link href={createActivityPath}>
                            Click here to create one!
                        </Link>
                    </span>
                )}
            </div>
        </section>
    )
}

export default GuideActivities