import React from 'react'
import ShareModalButton from '../../../components/ShareModal/button'
import ChangePasswordModalButton from '../../../components/ChangePasswordModal/button'
import { SocialLinks } from '../../../components/Explorer'
import { useWindowSize, useAuth, useOrganization } from '../../../hooks'
import styles from './index.module.scss'

const Sidebar = ({
    explorer,
    guest,
    editable,
    editableState,
    setEditableState
}) => {
    const {organization} = useOrganization()
    const {user} = useAuth()
    const {width} = useWindowSize()
    const colLength = width >= 768 && width < 968 ? 3 : 2

    return (
        <div className={`col-md-${colLength} col-sm-5 ${styles.socialAndButtons}`}>
            <SocialLinks
                onChange={(data) => setEditableState({...editableState, ...data})}
                editableState={editableState}
                explorer={explorer}
                guest={guest}
                editable={editable}/>
            {(user.email === explorer.email || organization) && (
                <div className="mt-2">
                    {editable && (
                        <ChangePasswordModalButton explorer={explorer}/>
                    )}
                    <ShareModalButton
                        width="4.44rem"
                        height="4.44rem"
                        iconWidth="1.5rem"
                        page="explorer"
                        url={`/e/${explorer.email}`}
                        id={explorer._id}
                    />
                </div>
            )}
        </div>
    )
}

export default Sidebar