import React from 'react'
import { useAuth } from '../../../hooks'
import { Button, Icon } from '../../../components/theme'
import styles from './index.module.scss'

const Footer = () => {
    const {user} = useAuth()
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={`row ${styles.infoSection}`}>
                    <div className="col-md-4">
                        <span>©{new Date().getFullYear()} Raleign & Drake</span>
                        <div>
                            <a href="tel: +1 917-432-4078">
                                +1 917-432-4078
                            </a>
                            <a href="mailto: info@rad.travel">
                                info@rad.travel
                            </a>
                        </div>
                    </div>
                    <div>
                        <a
                            href="https://www.facebook.com"
                            target="_blank"
                            rel="noopener noreferrer">
                                <Button
                                    type="secondary"
                                    icon={true}
                                    width={30}
                                    height={30}
                                    bordered={false}>
                                        <Icon
                                            type="facebook"
                                            width={12}
                                            height={12}/>
                                </Button>
                        </a>
                        <a
                            href="https://www.instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ml-2 mr-2">
                                <Button
                                    type="secondary"
                                    icon={true}
                                    width={30}
                                    height={30}
                                    bordered={false}>
                                        <Icon
                                            type="instagram"
                                            width={12}
                                            height={12}/>
                                </Button>
                        </a>
                        <a
                            href="https://www.twitter.com"
                            target="_blank"
                            rel="noopener noreferrer">
                                <Button
                                    type="secondary"
                                    icon={true}
                                    width={30}
                                    height={30}
                                    bordered={false}>
                                        <Icon
                                            type="twitter"
                                            width={12}
                                            height={12}/>
                                </Button>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer