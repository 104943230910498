import React, { useEffect, useState, useRef } from 'react'
import { Marker, GeoJSONLayer } from 'react-mapbox-gl'
import { MAPBOX_ACCESS_TOKEN } from '../../constants'
import { Button, Icon } from '../theme'
import { useScrollPosition, useWindowSize, useAuth } from '../../hooks'
import http from '../../http'
import Pin from './Pin'

export default function MarkersOverlay ({
    currentLocation,
    activities,
    query,
    selectedActivity,
    scrollable,
    calculateDistance,
    showRoute,
    profiles,
    details,
    geometries,
    getDuration,
    loaded,
    pinDataShow,
    streetView,
    guidePage,
    currentView
}) {
    const {user} = useAuth()
    const scrollPosition = useScrollPosition()
    const viewport = useWindowSize()
    const profileDropDown = useRef(null)
    const [duration, setDuration] = useState(null)
    const [changeProfile, toggleChangeProfile] = useState(false)
    const [profile, setProfile] = useState(0)
    const [timeout, changeTimeout] = useState(null)
    const [activeId, setActiveId] = useState(selectedActivity._id)
    const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
    const updateDuration = (coordinates) => {
        if (timeout) {
            clearTimeout(timeout)
            changeTimeout(null)
        }

        changeTimeout(setTimeout(() => {
            getProfile(coordinates)
        }, 500))
    }

    const getProfile = async (coordinates) => {
        try {
            const response = await Promise.all(profiles.map(profile => http.get(`https://api.mapbox.com/directions/v5/mapbox/${profile.name}/${currentLocation.coordinates.join(',')};${coordinates.join(',')}`, {
                params: {
                    access_token: MAPBOX_ACCESS_TOKEN,
                    geometries: 'geojson'
                }
            })))
            if (profile.data) {
                setDuration(response.map(profile => getDuration(profile.data.routes[0].duration)))
            }
        } catch (e) {
            console.log(e)
            console.log('error getting duration')
        }
    }

    const changeActive = (activity) => {
        setSliderActiveIndex(activities.indexOf(activity))
    }

    const dropdownOutsideClick = (e) => {
        if (profileDropDown.current) {
            if (e.target === profileDropDown.current || profileDropDown.current.contains(e.target)) {
                    return
            }
    
            setTimeout(() => {
                toggleChangeProfile(false)
            }, 100)
        }
    }

    useEffect(() => {
        window.addEventListener('click', dropdownOutsideClick)
        window.addEventListener('touchend', dropdownOutsideClick)

        return () => {
            window.removeEventListener('click', dropdownOutsideClick)
            window.removeEventListener('touchend', dropdownOutsideClick)
        }
    }, [])

    const detail = details.find(d => d.id === activeId)
    const filteredProfile = profiles.filter((p, i) => i !== profile && detail && detail.data[i].duration)

    return (
        <div>
            {/* ACTIVITY LOCATION */}
            {activities.filter(activity => activity).map(activity => (
                <Marker
                    coordinates={activity.loc.coordinates}
                    anchor="bottom"
                    className={`activity-marker-${activity._id} cursor-pointer ${activity._id === activeId ? 'active-activity-marker' : ''}`}
                    onClick={() => changeActive(activity)}
                    key={`activity-marker-${activity._id}`}>
                        <Pin
                            data={activity}
                            dataShow={pinDataShow}
                            selectedActivity={selectedActivity}
                            currentLocation={currentLocation}
                            query={query}
                            activity={activity}
                            scrollPosition={scrollPosition}
                            viewport={viewport}
                            scrollable={scrollable}
                            calculateDistance={calculateDistance}
                            updateDuration={updateDuration}
                            activeId={activeId}
                            setActiveId={setActiveId}
                            guidePage={guidePage}/>
                </Marker>
            ))}

            {/* {activitiesSlider && currentView === 'map' && (
                <ActivitiesSlider
                    activities={activities}
                    onChange={setActiveId}
                    streetView={streetView}
                    activeIndex={sliderActiveIndex}/>
            )} */}

            {showRoute && selectedActivity._id && currentLocation && (
                <div>
                    <div
                        className="profiles-container"
                        data-street-view={currentView !== 'map'}>
                            {(
                                currentView === 'map' &&
                                details.find(d => d.id === activeId) &&
                                details.find(d => d.id === activeId).data[profile] &&
                                {/* duration */}
                            ) && (
                                <div
                                    className="container"
                                    key={selectedActivity._id}>
                                        <div className="row">
                                            <span>{duration ? duration[profile] : details.find(d => d.id === activeId).data[profile].duration}</span>
                                            <span
                                                className="profile"
                                                data-show-profile={changeProfile}
                                                data-single={filteredProfile.length === 0}
                                                ref={profileDropDown}
                                                onClick={() => {
                                                    if (filteredProfile.length < 1) return
                                                    toggleChangeProfile(!changeProfile)
                                                }}>
                                                    by {profiles[profile].type}
                                                    {filteredProfile.length > 0 && (
                                                        <Button
                                                            type={changeProfile ? 'primary' : 'secondary'}
                                                            width={40}
                                                            height={40}
                                                            circle={true}
                                                            icon={true}
                                                            style={{ marginLeft: '1rem' }}>
                                                                <Icon
                                                                    type="arrow-bottom"
                                                                    width={12}
                                                                    height={14}/>
                                                        </Button>
                                                    )}
                                                    {changeProfile && (
                                                        <div className="change-profile">
                                                            {filteredProfile.map((profile, index) => (
                                                                <span
                                                                    key={`profile-${index}`}
                                                                    onClick={() => setProfile(profiles.indexOf(profile))}>
                                                                        {profile.type}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                            </span>
                                        </div>
                                </div>
                            )}
                    </div>

                    {showRoute && geometries[0] && geometries[0].data.map((geometry, index) => (
                        <div key={`geometry-${index}-${selectedActivity._id}`}>
                            <GeoJSONLayer
                                data={geometry}
                                lineLayout={{'line-join': 'round', 'line-cap': 'round'}}
                                linePaint={{ 'line-color': profile === index ? '#FAF5F1' : 'transparent', 'line-width': 3 }}/>
                        </div>
                    ))}
                </div>
            )}

            {/* CURRENT LOCATION */}
            {currentLocation && (
                <Marker
                    coordinates={currentLocation.coordinates}
                    anchor="bottom">
                        <Pin
                            data={currentLocation.name}
                            showData={true}
                            active={true}
                            profilePicture={user.profile_picture || '/icons/avatar.svg'}
                            loaded={loaded}/>
                </Marker>
            )}
        </div>
    )    
}