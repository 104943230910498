import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import smoothscroll from 'smoothscroll-polyfill'
import { Switch, Route, useLocation } from 'react-router-dom'
import { withOrganization, withAuthProvider, withError, withNavigateTransition, withRouter } from '../../hocs'
import { useAuth, useNavigate } from '../../hooks'

import Menu from '../../components/Menu'

import Signup from '../../views/Signup'
import Signin from '../../views/Signin'
import Explorer from '../../views/Explorer'
import ResetPassword from '../../views/ResetPassword'
import ForgotPassword from '../../views/ForgotPassword'
import Home from '../../views/Home'
import Activity from '../../views/Activity'
import CreateActivity from '../../views/CreateActivity'
import GuideActivities from '../../views/GuideActivities'
import Guide from '../../views/Guide'
import CreateGuide from '../../views/CreateGuide'
import NotFound from '../../views/NotFound'
import OnBoarding from '../../views/OnBoarding'
import YouAreDone from '../../views/YouAreDone'
import Terms from '../../views/Terms'
import AcceptTerms from '../../views/AcceptTerms'

const routes = [
    { path: '/sign-up', exact: true, component: Signup },
    { path: '/sign-in', exact: true, component: Signin },
    { path: '/e/:email', exact: true, component: Explorer },
    { path: '/activity/:id', exact: true, component: Activity },
    { path: '/create/activity', exact: true, component: CreateActivity },
    { path: '/edit/activity/:id', exact: true, component: CreateActivity },
    { path: '/guide/:id', exact: true, component: Guide },
    { path: '/guide/:id/activities', exact: true, component: GuideActivities },
    { path: '/create/guide', exact: true, component: CreateGuide },
    { path: '/edit/guide/:id', exact: true, component: CreateGuide },
    { path: '/reset/password', exact: true, component: ResetPassword },
    { path: '/forgot/password', exact: true, component: ForgotPassword },
    { path: '/you-are-done', exact: true, component: YouAreDone },
    { path: '/terms', exact: true, component: Terms },
    { path: '/accept-terms', exact: true, component: AcceptTerms },
    { path: '/', exact: true, component: Home },
    { path: '/*', exact: false, component: NotFound }
]

const App = () => {
    const [menu, setMenu] = useState(false)
    const [hasAccess, toggleAccess] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const {login, user, logout, verifyEmail, verified, resendVerificationEmail} = useAuth()
    smoothscroll.polyfill()

    const toggleMenu = (show) => {
        setMenu(show)
        const body = document.querySelector('body')
        if (show) {
            body.classList.add('no-scroll')
        } else {
            body.classList.remove('no-scroll')
        }
    }
    
    useEffect(() => {
        toggleMenu(false)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [location.pathname])

    useEffect(() => {
        if (user.email && !user.acceptedTerms) {
            navigate('/accept-terms')
            return
        }
    }, [user])

    useEffect(() => {
        const hasAccess = window.localStorage.getItem('hasAccess')

        if (hasAccess) {
            toggleAccess(true)
            return
        }
        toggleAccess(false)
    }, [])

    return (
        <div>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
            </Helmet>
            <Switch>
                {routes.map((route, index) => (
                    <Route
                        key={`route-${index}`}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                            <route.component
                                login={login}
                                logout={logout}
                                toggleMenu={toggleMenu}
                                verifyEmail={verifyEmail}
                                resendVerificationEmail={resendVerificationEmail}
                                verified={verified}
                                {...props}/>
                        )}/>
                ))}
            </Switch>
            {menu && (
                <Menu
                    toggleMenu={toggleMenu}
                    logout={logout}/>
            )}
        </div>
    )
}

export default withRouter(
    withNavigateTransition(
        withError(
            withAuthProvider(
                withOrganization(
                    App
                )
            )
        )
    )
)