import React from 'react'
import styles from './index.module.scss'

const Button = ({
    children,
    htmlType = 'button',
    type = '',
    transparent = false,
    borderDark = false,
    hoverEffect = true,
    style,
    innerStyle,
    width,
    height,
    className,
    bordered = true,
    circle = false,
    edit = false,
    beforeTopLess = false,
    flex = 'center',
    beforeOpacity = true,
    disabled = false,
    loading = false,
    icon = false,
    onClick = () => {}
}) => {
    return (
        <button
            type={htmlType}
            style={{width, height, ...style}}
            data-type={type}
            data-icon={icon}
            data-disabled={disabled}
            data-transparent={transparent}
            data-before-opacity={beforeOpacity}
            data-bordered={bordered}
            data-borderdark={borderDark}
            data-circle={circle}
            data-edit={edit}
            data-loading={loading}
            data-before-top-less={beforeTopLess}
            data-hover-effect={hoverEffect}
            className={`${styles.button} ${className} justify-content-${flex}`}
            onClick={(e) => {
                if (loading || disabled) {
                    e.preventDefault()
                    return
                }
                onClick(e)
            }}>
                {loading ? 'Loading ...' : (
                    <>
                        {!circle ? (
                            <div style={{ ...innerStyle }}>
                                {children}
                            </div>
                        ) : children}
                    </>
                )}
        </button>
    )
}

export default Button