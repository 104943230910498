import { useCallback } from 'react'
import {useFetcher} from '../index'
import * as service from './service'

const useGetByExplorer = () => {
    const { fetcher, loading, error, data } = useFetcher()

    const getByExplorer = useCallback(
        ({explorerId, sortDirection = 1}) => {
            fetcher(
                service.getByExplorer({explorerId, sortDirection})
            )
        },
        []
    )

    return {
        getByExplorer,
        loading,
        error,
        data
    }
}

export default useGetByExplorer