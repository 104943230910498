import React, { useState } from 'react'
import { Button } from '../theme'
import ChangeModal from './index'

const ChangePasswordModal = ({explorer}) => {
    const [modalVisible, toggleVisible] = useState(false)
    return (
        <>
            <Button
                type="secondary"
                className="mt-3 mb-5 w-100"
                onClick={() => toggleVisible(true)}>
                    Change Your Password
            </Button>
            {modalVisible && (
                <ChangeModal
                    explorer={explorer}
                    hide={() => toggleVisible(false)}
                />
            )}
        </>
    )
}

export default ChangePasswordModal