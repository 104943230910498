import React, { useEffect, useState, createContext, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export const NavigateTransitionContext = createContext()

export const useNavigate = () => {
    const navigate = useContext(NavigateTransitionContext)

    return navigate
}

const withNavigateTransition = Component => ({
    handleErrors,
    ...props
}) => {
    const [status, setStatus] = useState('loaded')
    const location = useLocation()
    const history = useHistory()

    const navigate = (route) => {
        const current = location.pathname.split('?')[0]

        if (current === route.split('?')[0]) {
            return history.push(route)
        }

        setStatus('loading')
        setTimeout(() => {
            history.push(route)
        }, 0)
    }

    useEffect(() => {
        setTimeout(() => {
            setStatus('loaded')
        }, 500)
    }, [location])

    return (
        <NavigateTransitionContext.Provider value={navigate}>
            <Component {...props}/>
        </NavigateTransitionContext.Provider>
    )
}

export default withNavigateTransition