import React, { useState, useEffect } from 'react'
import copy from 'copy-to-clipboard'
import Modal from '../Modal'
import { Button, Icon } from '../../components/theme'
import { withAlert } from '../../hocs'
import styles from './index.module.scss'

const ShareModal = ({
    page,
    id,
    hide,
    Alert,
    url
}) => {
    const onShare = () => {
        const share = `https://radmin-api.st.rad.travel/api/${page}/share?id=${id}`
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${share}`)
    }

    const onCopy = () => {
        copy(url, {
            onCopy: Alert({title: 'Copied'})
        })
        setTimeout(() => {
            hide()
        }, 900)
    }

    useEffect(() => {
        const body = document.body
        body.className = ' no-scroll'

        return () => {
            body.className = ''
        }
    }, [])

    return (
        <Modal>
            <section
                className={styles.section}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                }}>
                    <Button
                        type="primary"
                        height="4.33rem"
                        width="4.33rem"
                        bordered={false}
                        icon={true}
                        circle={true}
                        onClick={hide}>
                            <Icon
                                width="1.5rem"
                                type="close"
                            />
                    </Button>
                    <div className="container">
                        <div className="row justify-content-center mb-5">
                            <div className="col-10">
                                <h1 className="sm text-center">
                                    Raise your chances of getting paid. Share with your friends and followers
                                </h1>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-7">
                                <Button
                                    type="secondary"
                                    width="100%"
                                    className="mb-2"
                                    onClick={onShare}>
                                        Share To Facebook
                                </Button>
                                <Button
                                    type="secondary"
                                    width="100%"
                                    onClick={onCopy}>
                                        Copy Link
                                </Button>
                            </div>
                        </div>
                    </div>
            </section>
        </Modal>
    )
}

export default withAlert(ShareModal)