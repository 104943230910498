import React, { useState } from 'react'
import AWS from 'aws-sdk'
import randomstring from 'randomstring'
import { withAlert } from '../../hocs'
import Avatar from '../Avatar'
import Loading from '../LoadingIcon'
import config from '../../config'
import { Icon } from '../theme'
import styles from './index.module.scss'

AWS.config.update({
    region: config.aws.region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws.identityPoolId
    })
})

const ProfilePicture = ({
    explorer = {},
    onChange = () => {},
    Alert,
    editable,
    avatar
}) => {
    const [avatarLoading, setAvatarLoading] = useState(false)
    const [image, setImage] = useState(avatar)

    const imageChange = (e) => {
        setAvatarLoading(true)
        const s3 = new AWS.S3({
            apiVersion: config.aws.s3.apiVersion,
            params: {
                Bucket: config.aws.s3.bucket
            }
        })
        if (Math.round(e.target.files[0].size / 1024) > 5000) {
            Alert({
                title: 'File too Big, please select a file less than 5mb'
            })
            setAvatarLoading(false)
            return
        }
        const fileType = e.target.files[0].type.split('/').pop().toLowerCase()
        if (fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png' && fileType !== 'bmp' && fileType !== 'gif') {
            Alert({
                title: 'Please select a valid image file'
            })
            setAvatarLoading(false)
            return
        }
        const random = randomstring.generate({length: 12})
        const photoKey = `radmin/${e.target.files[0].name}-${random}.${e.target.files[0].type.split('/')[1]}`

        s3.upload({
            Key: photoKey,
            Body: e.target.files[0],
            ACL: 'public-read'
        }, (e, data) => {
            if (e) {
                alert('Uploading image error ' + e.message)
                return
            }
            setImage(data.Location)
            onChange({ profile_picture: data.Location })
        })
    }

    return (
        <div className={styles.profilePicture}>
            <label
                htmlFor="profile-image"
                data-editable={editable}>
                    <Avatar
                        profilePicture={image}
                        downloadWidth={500}
                        onLoad={() => setAvatarLoading(false)}
                    />
                    <div>
                        <div>
                            <Icon
                                height="8px"
                                width="8px"
                                type="plus"/>
                        </div>
                        <span>
                            {explorer.profile_picture ? 'Change Your Picture' : 'Add Your Picture'}
                        </span>
                    </div>
            </label>
            {editable && (
                <input
                    type="file"
                    id="profile-image"
                    className="d-none"
                    onChange={imageChange}/>
            )}
            {avatarLoading && (
                <Loading
                    style={{borderRadius: '100%'}}
                />
            )}
        </div>
    )
}

export default withAlert(ProfilePicture)