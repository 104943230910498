import React, { useState, useEffect } from 'react'
import Image from '../Image'
import styles from './index.module.scss'

const Slide = ({
    index,
    lastIndex,
    image,
    swiper,
    style = {},
    shadow = false
}) => {
    const [loaded, load] = useState(false)

    useEffect(() => {
        if (loaded) {
            return
        }

        if (index === 1 || index === lastIndex || index === swiper.realIndex || index === swiper.realIndex + 2) {
            load(true)
        }
    }, [swiper.realIndex])

    return (
        <div
            className={styles.slide}
            style={style}
            data-shadow={shadow && image}>
                {loaded && image && image.standard_resolution && (
                    <Image
                        src={image.standard_resolution.url}
                        options="w_1000,f_auto"
                        alt={`activity image ` + image.caption}
                        fromCloudinary={false}
                    />
                )}
        </div>
    )
}

export default Slide