import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withAlert } from '../../hocs'
import { useAuth, useNavigate } from '../../hooks'
import Link from '../../components/Link'
import { Button, Input, Icon } from '../../components/theme'
import styles from './index.module.scss'

const Signin = ({
    Alert,
}) => {
    const {user, login, verifyEmail, verified = true, resendVerificationEmail} = useAuth()
    const navigate = useNavigate()
    const history = useHistory()
    const [email, setEmail] = useState({value: '', valid: false})
    const [password, setPassword] = useState({value: '', valid: false, visible: false})
    const [formSubmitted, setFormSubmitted] = useState(false)

    const resendEmail = (e) => {
        e.preventDefault()

        resendVerificationEmail(email.value)
            .then((message) => Alert({ title: message }))
    }

    const onLogin = () => {
        setFormSubmitted(true)
        if (!email.valid || !password.valid) return
        login('email', {email: email.value, password: password.value})
            .catch(message => Alert({ title: message }))
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('token')) {
            verifyEmail({token: urlParams.get('token')})
        }
    }, [])

    if (user._id) {
        navigate(`/e/${user.email}`)
    }

    return (
        <section className={styles.section}>
            <Helmet>
                <title>Curator | RAD | Sign In</title>
            </Helmet>
            <Button
                icon={true}
                bordered={false}
                height="4.33rem"
                width="4.33rem"
                onClick={() => history.goBack()}
                >
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <div className="container">
                <div className="row d-flex justify-content-between">
                    <div className={`col-lg-6 ${styles.textInfo}`}>
                        <div>
                            <h1 className="mb-1 mt-0">Join the tribe</h1>
                            <p className="margin-0">Raleigh & Drake’s Curator helps you craft rich experiences for your fellow travelers & followers.</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className={styles.formSection}>
                            <h3 className="mt-0 mb-5">Your journey begins here</h3>
                            <div className="row justify-content-center mt-3 mb-4">
                                <div className={`col-md-7 col-10 ${styles.socialButtons}`}>
                                    <Button
                                        type="primary"
                                        width="100%"
                                        hoverEffect={false}
                                        className={styles.googleButton}
                                        onClick={() => { login('google') }}>
                                            <Icon
                                                type="google-plus"
                                            />
                                            Sign in WITH GOOGLE
                                    </Button>
                                    <Button
                                        type="primary"
                                        hoverEffect={false}
                                        width="100%"
                                        className={styles.facebookButton}
                                        onClick={() => { login('facebook') }}>
                                            <Icon
                                                type="facebook-light"
                                            />
                                            Sign in WITH FACEBOOK
                                    </Button>
                                </div>
                            </div>
                            <Input
                                type="email"
                                placeholder="Enter your email"
                                className="mt-4"
                                value={email.value}
                                error={formSubmitted && !email.valid}
                                onKeyUp={(e) => {if (e.keyCode === 13) onLogin()}}
                                onChange={(e) => setEmail({value: e.target.value, valid: e.target.value !== ''})}/>
                            <div className={styles.passwordSection}>
                                <Input
                                    value={password.value}
                                    placeholder="Enter your password"
                                    type={password.visible ? 'text' : 'password'}
                                    error={formSubmitted && !password.valid}
                                    onChange={(e) => setPassword({value: e.target.value, valid: e.target.value !== ''})}
                                    onKeyUp={(e) => {if (e.keyCode === 13) onLogin()}}/>
                                <Icon
                                    type={`eye${password.visible ? '-close' : ''}`}
                                    onClick={() => setPassword({...password, visible: !password.visible})}
                                />
                            </div>
                            <Link 
                                href={`/reset/password?email=${email.value}`}
                                className="mt-2"> 
                                    I Forgot My Password
                            </Link>
                            {!verified && email && (
                                <a
                                    href="/#"
                                    className="mt-2"
                                    onClick={resendEmail}>
                                        Resend Verification Email
                                </a>
                            )}
                            <div className="row justify-content-center mt-2">
                                <div className="col-5">
                                    <Button
                                        type="primary"
                                        onClick={onLogin}
                                        width="100%">
                                            Sign In
                                    </Button>
                                </div>
                            </div>
                            <span>
                                Don`t have an account?
                                <Link href="/sign-up">
                                    Sign Up
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src="/logo.svg"
                alt="Logo"
            />
        </section>
    )
}

export default withAlert(Signin)