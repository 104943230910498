import React, { useState } from 'react'
import ShareModal from './index'
import { Button, Icon } from '../theme'
import styles from './index.module.scss'

const ShareModalButton = ({
    width,
    height,
    iconWidth,
    iconHeight,
    type = 'secondary',
    centered = true,
    className,
    page,
    url,
    id
}) => {
    const [modalVisible, toggleVisible] = useState(false)
    return (
        <>
            <Button
                type={type}
                style={{width, height}}
                circle={true}
                icon={true}
                className={`${centered ? styles.centered : ''} ${className}`}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    toggleVisible(true)
                }}>
                    <Icon
                        type={`share${type === 'secondary' ? '' : '-light'}`}
                        width={iconWidth}
                        height={iconHeight}/>
            </Button>
            {modalVisible && (
                <ShareModal
                    page={page}
                    id={id}
                    url={`${window.location.protocol}//${window.location.host}${url}`}
                    hide={() => toggleVisible(false)}
                />
            )}
        </>
    )
}

export default ShareModalButton