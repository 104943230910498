import React, { useState, useContext, useEffect, createContext } from 'react'
import jwt from 'jsonwebtoken'
import { useAuth } from '../hooks'
import config from '../config'

export const OrganizationContext = createContext()

export const useOrganization = () => {
    const data = useContext(OrganizationContext)   

    return data
}

const withOrganization = Component => ({
    ...props
}) => {
    const urlParams = (new URL(document.location)).searchParams
    const [organization, setOrganization] = useState()
    const {user, login, logout} = useAuth()

    useEffect(() => {
        (async () => {
            // const data = {
            //     "id": "5dc1f6ce5f731b1bcc2ad096",
            //     "isAdmin": false,
            //     "alias": "a",
            //     "permissions": JSON.stringify({
            //         "createActivity": true,
            //         "editActivity": true,
            //         "deleteActivity": true,
            //         "createGuide": true,
            //         "editGuide": true,
            //         "deleteGuide": true,
            //         "manageGuideActivities": true,
            //         "editExplorer": true,
            //     }),
            //     "iat": 1614764475,
            //     "exp": Math.floor(Date.now() / 1000) + (60 * 60)
            // }
            // const t = jwt.sign(data, config.JWTDecode)
            // console.log(t)
            // logout({preventDefault: () => {}})
            if (!urlParams.has('org_token')) {
                return setOrganization(null)
            }
            const token = await jwt.verify(urlParams.get('org_token'), config.JWTDecode)
            login('email', {organization: token.id})
                .then(org => setOrganization({...org, permissions: JSON.parse(token.permissions)}))
                .catch(message => console.log(message, ' login error'))
        })();
    }, [])

    if (organization === undefined) return <></>

    return (
        <OrganizationContext.Provider value={{organization}}>
            <Component {...props}/>
        </OrganizationContext.Provider>
    )
}

export default withOrganization