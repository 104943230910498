import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import Link from '../Link'
import { useAuth, useNavigate } from '../../hooks'
import Alert from '../Alert'
import Image from '../Image'
import Avatar from '../Avatar'
import http from '../../http'
import ShareModalButton from '../ShareModal/button'
import { DEFAULT_GUIDE_COVER, DEFAULT_ACTIVITY_COVER } from '../../constants'
import { Button, Icon } from '../theme'
import styles from './index.module.scss'

const Card = ({
    type = 'guide',
    data = {},
    imageFromCloudinary = true,
    selectable = false,
    selected = false,
    loadMore = false,
    size = 'lg',
    editActivityQueryParams,
    guideId,
    create = false,
    deleteFromParent = false,
    setDeleteFromParentId,
    deleteFromParentId,
    onLoadMore = () => {
    },
    remove = () => {
    },
    onChange = () => {
    }
  }) => {
  const urlParams = (new URL(document.location)).searchParams
  const { user, updateUser } = useAuth()
  const [cover, setCoverUrl] = useState('')
  const [removeId, setRemoveId] = useState(null)
  const navigate = useNavigate()

  const removeData = async () => {
    remove(removeId)
    setRemoveId(null)
    if (type === 'activity') {
      const explorerCityRequestData = {
        city: data.city,
        option: 'remove'
      }

      const response = await http.put(`/api/explorer/cities/${data._explorer._id}`, explorerCityRequestData)
      updateUser(response.data.user)
    }
    http
      .delete(`/api/${type}/${removeId}`)
      .catch(console.error)
  }

  useEffect(() => {
    if (create || loadMore) {
      return setCoverUrl(type === 'activity' ? DEFAULT_ACTIVITY_COVER.standard_resolution.url : DEFAULT_GUIDE_COVER.standard_resolution.url)
    }

    if (type === 'guide' && data.cover && data.cover.standard_resolution) {
      return setCoverUrl(data.cover.standard_resolution.url)
    }

    if (type === 'activity' && data.medias && data.medias[0] && data.medias[0].standard_resolution) {
      const cover = data.medias.find(media => media.cover)
      return setCoverUrl(cover ? cover.standard_resolution.url : data.medias[0].standard_resolution.url)
    }
    setCoverUrl(type === 'activity' ? DEFAULT_ACTIVITY_COVER.standard_resolution.url : DEFAULT_GUIDE_COVER.standard_resolution.url)
  }, [data, create])

  return (
    <div className={`fadeIn ${styles.cardContainer}`}>
      {removeId && (
        <Alert
          title="Are You Sure ?"
          confirmation={true}
          onSuccess={removeData}
          onCancel={() => setRemoveId(null)}
        />
      )}

      {loadMore ? (
        <div
          className={styles.card}
          data-type={type}
          data-load-more={true}>
          {cover && (
            <Image
              src={cover}
              options="w_800,f_auto"
              alt={`Type-${type}`}
              fromCloudinary={imageFromCloudinary}
            />
          )}

          <Button
            type="secondary"
            bordered={false}
            height={45}
            width="9.8rem"
            style={{ paddingTop: 0 }}
            onClick={onLoadMore}>
            Load More
          </Button>
        </div>
      ) : (
        <>
          {create ? (
            <Link href={`/create/${type}`}>
              <div
                className={styles.card}
                data-type={type}
                data-create={true}>
                {cover && (
                  <Image
                    src={cover}
                    options="w_800,f_auto"
                    alt={`Type-${type}`}
                    fromCloudinary={imageFromCloudinary}
                  />
                )}

                <Button type="secondary">
                  Create a{type === 'activity' ? 'n experience' : ` ${type}`}
                </Button>
              </div>
            </Link>
          ) : (
            <a
              href="/#"
              className={styles.card}
              data-size={size}
              data-selectable={selectable}
              onClick={(e) => {
                e.preventDefault()
                if (selectable) {
                  return onChange(data._id)
                }
                navigate(`/${type}/${data._id}`)
              }}>

              {cover && (
                <LazyLoad>
                  <Image
                    src={cover}
                    options="w_800,f_auto"
                    alt={`Type-${type}`}
                    fromCloudinary={imageFromCloudinary}
                  />
                </LazyLoad>
              )}
              <div
                className={styles.tools}
                data-visible={(data._explorer && (data._explorer._id === user._id || data._explorer._id === urlParams.get('explorer_id'))) ? true : false}
                data-selectable={selectable}>
                {selectable ? (
                  <span className={styles.select}>
                    <span data-selected={selected}/>
                    Select
                  </span>
                ) : (
                  <>
                    <ShareModalButton
                      type="primary"
                      iconWidth={13}
                      iconHeight={13}
                      page={type}
                      url={`/${type}/${data._id}`}
                      id={data._id}
                    />

                    <div data-edit={true}>
                      <Link href={`/edit/${type}/${data._id}${selectable ? editActivityQueryParams : ''}`}>
                        <Icon
                          type="edit-light"
                          width={13}
                          height={13}
                        />
                      </Link>

                      <button
                        style={{ marginLeft: 8 }}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          if (deleteFromParent) {
                            setDeleteFromParentId(data._id)
                            return
                          }
                          setRemoveId(data._id)
                        }}>

                        <Icon
                          type="trash-light"
                          width={17}
                          height={17}
                        />
                      </button>
                    </div>
                  </>
                )}
              </div>

              <div
                className={styles.info}
                data-size={size}
              >
                <h3>
                  {data.name}
                </h3>

                <div className="d-flex align-items-center pt-1">
                  <Avatar
                    width={25}
                    height={25}
                    profilePicture={data._explorer && data._explorer.profile_picture}
                  />

                  <p className={styles.explorerName}>
                    by <span>{data._explorer && data._explorer.full_name}</span>
                  </p>
                </div>
              </div>

              {!selectable && (
                <Link
                  href={`/${type}/${data._id}`}
                  className={styles.viewActivity}
                >
                  <Button
                    width={60}
                    height={60}
                    icon={true}
                    type="secondary"
                    bordered={false}
                  >
                    <Icon type="_eye"/>
                  </Button>
                </Link>
              )}
            </a>
          )}
        </>
      )}
    </div>
  )
}

export default Card