import React from 'react'
import Link from '../../components/Link'
import { Button } from '../../components/theme'
import { useAuth } from '../../hooks'
import styles from './index.module.scss'

const NotFound = () => {
    const {user} = useAuth()
    return (
        <section className={styles.section}>
            <h1>404</h1>
            <Link href={user.email ? `/e/${user.email}` : '/'}>
                <Button
                    type="secondary">
                        Back to homepage
                </Button>
            </Link>
        </section>
    )
}

export default NotFound