import React, { useState, useEffect, useCallback } from 'react'
import { CATEGORIES_NAMES } from '../../../constants'
import CategoryModal from '../CategoryModal'
import Details from '../Details'
import SearchActivity from '../SearchActivity'
import { Button, Icon } from '../../../components/theme'
import styles from './index.module.scss'

const Info = ({
    activity: data,
    user,
    loading,
    imagesProcessing,
    activityError,
    setActivityError,
    removeActivityInfo,
    changeData = () => {},
    onChange = () => {},
    save = () => {},
    toggleFullScreenPreview = () => {}
}) => {
    const [activity, setActivity] = useState(data)

    const searchActivity = useCallback(
        (data) => {
            const newActivity = {...activity, ...Object.values(data)[0]}
            newActivity.description = ''
            newActivity.tip = ''
            newActivity.medias = []
            newActivity.tags = []
            newActivity.bookingUrl = ''
            newActivity.asLowAsPrice = ''
    
            changeData(newActivity)
            setActivityError('')
            setActivity(newActivity)
        },
        [changeData, setActivity, setActivityError, activity]
    )

    useEffect(() => {
        setActivity({...data})
    }, [data])

    return (
        <div className={styles.info}>
            <div
                className={styles.activityHeading}
                data-visible={activity.name ? true : false}>
                    <div
                        key={`${activity._city}-${activity.city.name}-${activity.name}`}
                        data-address={activity.name ? true : false}>
                            <SearchActivity
                                info={activity}
                                user={user}
                                style={{ paddingLeft: 15, paddingRight: 15, }}
                                type="activity"
                                inputError={activityError}
                                bordered={activity.name ? false : true}
                                onChange={searchActivity}
                            />
                            <p className={`${!activity.name ? 'hidden' : 'fadeIn'}`}>
                                {activity.google_info ? activity.google_info.formatted_address : ''}
                            </p>
                    </div>
                    {activity.name && (
                        <Button
                            type="secondary"
                            className="fadeIn"
                            icon={true}
                            hoverEffect={false}
                            bordered={false}
                            onClick={removeActivityInfo}>
                                <Icon
                                    type="trash"/>
                        </Button>
                    )}
            </div>
            {activity.name && (
                <div className={styles.activityInfo}>
                    <CategoryModal
                        activeCategoryIndex={activity.category ? CATEGORIES_NAMES.indexOf(activity.category) : 0}
                        setActiveCategoryIndex={activeCategoryIndex => {
                            changeData(oldData => ({...oldData, category: CATEGORIES_NAMES[activeCategoryIndex]}))
                        }}
                    />
                    <Details
                        activity={activity}
                        onChange={onChange}/>
                    <div className={styles.saveFullScreenButtons}>
                        <Button
                            type="primary"
                            width="7.5rem"
                            className="mr-2"
                            loading={loading}
                            onClick={save}
                            disabled={imagesProcessing}>
                                {!imagesProcessing ? 'Publish' : 'Processing ...'}
                        </Button>
                        {!imagesProcessing && (
                            <Button
                                type="secondary"
                                onClick={toggleFullScreenPreview}>
                                    Fullscreen preview
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Info