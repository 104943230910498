import React from 'react'
import { useNavigate } from '../../hooks'

const Link = ({
    href = '',
    children,
    className,
    onClick = () => {}
}) => {
    const navigate = useNavigate()

    const click = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
        navigate(href)
    }
    return (
        <a
            href={href}
            className={className}
            onClick={click}>
                {children}
        </a>
    )
}

export default Link