import React, { useEffect, useReducer } from 'react'
import ProfilePicture from '../../ProfilePicture'
import ShareModalButton from '../../ShareModal/button'
import { Button, Icon } from '../../theme'
import { useWindowSize } from '../../../hooks'
import styles from './index.module.scss'

const ProfileInfo = ({
    explorer,
    onChange,
    userType,
    editable,
    showProfileShare,
    setProfileEditable,
    profileEditable,
    editableState
}) => {
    const {width} = useWindowSize()
    const firstColLength = width >= 768 && width < 968 ? 3 : 2

    const [variables, set] = useReducer(
        (state, [field, value]) => ({...state, [field]: value}),
        {
            full_name: explorer.full_name,
            descriptor: explorer.descriptor,
            username: explorer.username,
        }
    )

    useEffect(() => {
        onChange({...variables})
    }, [variables])

    return (
        <div className={`row mb-5 ${styles.profileInfo}`}>
            <div className={`col-3 col-sm-2 col-md-${firstColLength} d-flex align-items-center`}>
                <div
                    data-avatar={true}
                    data-exists={explorer.profile_picture ? true : false}>
                        <ProfilePicture
                            onChange={onChange}
                            editable={editable}
                            explorer={explorer}
                            avatar={editableState.profile_picture ? editableState.profile_picture : explorer.profile_picture}
                        />
                </div>
            </div>
            <div className={`col-9 col-sm-9 col-md-8 ${styles.details}`}>
                {userType !== 'guest' && (
                    <Button
                        type={`${profileEditable ? 'primary' : 'secondary'}`}
                        icon={true}
                        hoverEffect={false}
                        width={40}
                        height={40}
                        bordered={false}
                        className="ml-2"
                        edit={true}
                        onClick={() => setProfileEditable(!profileEditable)}>
                            <Icon
                                type={`${profileEditable ? 'save' : 'edit'}`}
                                width={12}
                                height={12}/>
                    </Button>
                )}
                <div className="d-flex">
                    <h1 className="sm">
                        {explorer.descriptor}
                    </h1>
                </div>
            </div>
            {userType === 'guest' && (
                <div className={`col-md-2 col-5 ${styles.shareSection}`}>
                    <ShareModalButton
                        url={`/e/${explorer.email}`}
                        type="secondary"/>
                </div>
            )}
        </div>
    )
}

export default ProfileInfo