import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import http from '../../../http'
import styles from './index.module.scss'

const SimilarActivities = ({
    activity,
    setShare = () => {}
}) => {
    const [similarActivities, setSimilarActivities] = useState({all: [], limit: 4})

    const getSimilarActivities = async (activity) => {
        try {
            const response = await http.get('/api/activities/similar', {
                params: {
                    category: activity.category,
                    tags: activity.tags
                }
            })
            setSimilarActivities({...similarActivities, all: response.data.activities.filter(a => a._id !== activity._id)})
        } catch (e) {
            console.log('Getting similar experiences error')
            console.log(e)
        }
    }

    const loadMoreScrollListener = () => {
        if (similarActivities.limit < similarActivities.all.length) {
            if (window.scrollY > document.body.scrollHeight - window.innerHeight - 100) {
                setSimilarActivities({ ...similarActivities, limit: similarActivities.limit + 4 })
            }
        }
    }

    useEffect(() => {
        if (activity && activity._explorer) {
            setSimilarActivities({all: [], limit: 4})
            getSimilarActivities(activity)
        }
    }, [activity])

    useEffect(() => {
        window.addEventListener('scroll', loadMoreScrollListener)

        return () => {
            window.removeEventListener('scroll', loadMoreScrollListener)
        }
    }, [similarActivities])
    
    return (
        <section className={styles.similarActivitiesSection}>
            <div className="container overflow-hidden">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="d-flex align-items-center mb-3">
                            <h3 className="sm">Similar Experiences</h3>
                            <span data-type="count">({similarActivities.all.length} experiences)</span>
                        </div>
                        <div className="row">
                            {similarActivities.all.slice(0, similarActivities.limit).map(((activity, index) => (
                                <div
                                    className="col-md-6"
                                    style={{ marginBottom: 30 }}
                                    key={`card-${index}`}>
                                        <Card
                                            data={activity}
                                            share={(id, type) => setShare({visible: true, _id: id, type})}
                                            type={'activity'}
                                        />
                                </div>
                            )))}
                        </div>
                        {/* {similarActivities.limit < similarActivities.all.length && (
                            <div className="d-flex justify-content-center">
                                <Button
                                    type="secondary"
                                    width="9rem"
                                    onClick={() => setSimilarActivities({...similarActivities, limit: similarActivities.limit + 4})}>
                                        Load More
                                </Button>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SimilarActivities