import React from 'react'
import Link from '../Link'
import { Button } from '../theme'
import styles from './index.module.scss'

const YouAreDoneTypes = () => {
    return (
        <div className={`row pt-5 ${styles.types}`}>
            <div className="col-md-4">
                {/* <div>
                    <img
                        src="/images/guide.png"
                        alt="Guide"/>
                </div> */}
                <Link href="/create/guide">
                    <Button
                        type="secondary"
                        width="12rem">
                            Make a guide
                    </Button>
                </Link>
            </div>
            <div className="col-md-4 d-flex flex-column justify-content-center">
                <div>
                    {/* <img
                        src="/images/video.png"
                        alt="Video"/> */}
                </div>
                <Link href="/upload/video">
                    <Button
                        type="secondary"
                        width="12rem"
                        className="mt-4">
                            Upload a video
                    </Button>
                </Link>
            </div>
            <div className="col-md-4">
                <div>
                    <img
                        src="/images/experience.jpg"
                        alt="Experience"/>
                </div>
                <Link href="/create/activity">
                    <Button
                        type="secondary"
                        width="12rem">
                            Add an individual experience
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default YouAreDoneTypes