import React, { useEffect, useState } from 'react'
import ReactMapboxGl from 'react-mapbox-gl'
import { LngLatBounds } from 'mapbox-gl'
import { CITIES, CITY_NAMES, MAPBOX_ACCESS_TOKEN } from '../../constants'
import MarkersOverlay from './MarkersOverlay'
import http from '../../http'
import StreetView from './StreetView'
import './map.scss'

const profiles = [
    { name: 'driving', icon: '/icons/driving.svg', alt: 'driving-icon', type: 'car' },
    { name: 'cycling', icon: '/icons/cycling.svg', alt: 'cycling-icon', type: 'bike' },
    { name: 'walking', icon: '/icons/walking.svg', alt: 'walking-icon', type: 'walk' }
]

function MapBox ({
    activities = [],
    showRoute,
    cityAlias,
    query,
    scrollable = false,
    selectedActivity: sa = {},
    height = 0,
    width = '100%',
    streetView = false,
    guidePage = false,
    pinDataShow = true,
    showCurrentLocation = true,
    lightTheme = false
}) {
    const accessToken = 'pk.eyJ1IjoicmlsZXlqYW1lc21pbGhlbSIsImEiOiJjaXVvbDZwYncwMXAzMnlxbWo1dWc1d3p5In0.WZSAaZ_og6vEJuyhwuxzDg'
    const [currentLocation, setCurrentLocation] = useState(null)
    const [selectedActivity, setSelectedActivity] = useState(sa)
    const [bounds, setBounds] = useState([])
    const [geometries, setGeometries] = useState([])
    const [details, setDetails] = useState([])
    const [loading, loaded] = useState(false)
    const [mapView, toggleView] = useState(true)
    const [supportStreet, toggleSupportStreet] = useState(false)

    const Map = ReactMapboxGl({
        accessToken,
        interactive: false,
        scrollZoom: false,
        attributionControl: false,
    })

    const calculateBounds = (coordinates) => {
        const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new LngLatBounds(coordinates[0], coordinates[0]))

        return Object.values(bounds).map(coord => Object.values(coord))
    }

    const calculateDistance = ([lon1, lat1], [lon2, lat2]) => {
        const p = 0.017453292519943295
        const c = Math.cos
        const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) / 2
    
        return 12742 * Math.asin(Math.sqrt(a))
    }

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            navigator.permissions
                .query({name:'geolocation'})
                .then((result) => {
                    console.log(result.state)
                    if (result.state !== 'prompt' && result.state !== 'granted') {
                        return reject({
                            ...new Error('browser not supported'),
                            name: 'Not Supported'
                        })
                    }
                    if ('geolocation' in navigator) {
                        navigator.geolocation.getCurrentPosition(({ coords }) => {
                            return resolve(coords)
                        }, ({ code, message }) => {
                            return reject({
                                ...new Error(message),
                                name: 'Location Rejected',
                                code
                            })
                        })
                    } else {
                        return reject({
                            ...new Error('browser not supported'),
                            name: 'Not Supported'
                        })
                    } 
                })
                .catch(e => {
                    return reject({
                        ...new Error('browser not supported'),
                        name: 'Not Supported'
                    })
                })
        })
    }

    const getCurrentLocation = async () => {
        let coordinates = [sa.airport.longitude, sa.airport.latitude]
        let name = sa.airport.name

        try {
            const userLocation = await getUserLocation()
            const distance = calculateDistance([userLocation.latitude, userLocation.longitude], [selectedActivity.loc.coordinates[1], selectedActivity.loc.coordinates[0]])
            if (distance < 50) {
                coordinates = [userLocation.longitude, userLocation.latitude]
                name = 'Your Location'
            }
        } catch (e) {
            console.log(e)
            console.log('not able to get user location')
        }
        setCurrentLocation({ coordinates, name })
    }

    const getDuration = (detail) => {
        const mins = Math.round(detail / 60)
        let duration = ''

        if (mins > 60) {
            duration = (mins / 60).toFixed(2) + ' Hours'
        } else if (mins > 0) {
            duration = mins + ' Min'
        }

        return duration
    }
    const getProfiles = async (i, newGeometries, newDetails) => {
        return new Promise (async (resolve, reject) => {
            const selectedActivity = activities[i]
            let geometries = newGeometries
            let details = newDetails
            if (!selectedActivity || !selectedActivity.loc || !currentLocation) {
                if (geometries.length > 0) setGeometries([...geometries])
                if (details.length > 0) setDetails([...details])
                loaded(false)
                return reject('')
            }

            try {
                const response = await Promise.all(profiles.map(profile => http.get(`https://api.mapbox.com/directions/v5/mapbox/${profile.name}/${currentLocation.coordinates.join(',')};${selectedActivity.loc.coordinates.join(',')}`, {
                    params: {
                        access_token: MAPBOX_ACCESS_TOKEN,
                        geometries: 'geojson'
                    }
                })))

                if (i === 0) {
                    setBounds(calculateBounds(...response.map(route => route.data.routes[0] && route.data.routes[0].geometry.coordinates).filter(v => v)))
                }

                details = [
                    ...details,
                    {
                        id: selectedActivity._id,
                        data: response.map(geometry => {
                            const route = geometry.data.routes[0]
                            const miles = Math.round(calculateDistance(
                                [currentLocation.coordinates[0], currentLocation.coordinates[1]],
                                [selectedActivity.loc.coordinates[0], selectedActivity.loc.coordinates[1]]
                            ))
        
                            document.querySelectorAll(`.pin.pin-${selectedActivity._id} .distance`).forEach(pin => {
                                pin.innerHTML = `${miles} Miles from ${currentLocation.name === 'Your Location' ? 'you' : currentLocation.name}`
                            })
        
                            return {
                                duration: route && getDuration(route.duration)
                            }
                        })
                    }
                ]

                geometries = [
                    ...geometries,
                    {
                        id: selectedActivity._id,
                        data: response.map(geometry => {
                            const route = geometry.data.routes[0]
                            return {
                                type: 'Feature',
                                geometry: route && route.geometry
                            }
                        })
                    }
                ]

                return resolve(getProfiles(i + 1, geometries, details))
            } catch (e) {
                console.log('Network error')
                console.log(e.message || e)
                return resolve(getProfiles(i + 1, newGeometries, newDetails))
            }
        })
    }

    useEffect(() => {
        if (!selectedActivity.loc) return
        getCurrentLocation()
    }, [selectedActivity])
    
    useEffect(() => {
        if (currentLocation !== null) {
            getProfiles(0, [], [])
            setTimeout(() => {
                loaded(false)
            }, 500)
        }
    }, [currentLocation])

    useEffect(() => {
        setSelectedActivity(sa)
    }, [sa])

    return (
        <div
            id="map"
            className="mapbox"
            data-light-theme={lightTheme}>
                <div className="empty-white-block"/>
                {loading && (
                    <div className="loader">
                        <span className="spinner"></span>
                    </div>
                )}
                <div
                    className="views"
                    data-mapview={mapView}
                    data-mapbox={!supportStreet}
                    style={height ? { height } : {}}>
                        {!guidePage && streetView && sa && (
                            <StreetView
                                sa={sa}
                                mapView={mapView}
                                selectedActivity={selectedActivity}
                                supportStreet={supportStreet}
                                toggleView={toggleView}
                                toggleSupportStreet={toggleSupportStreet}
                            />
                        )}
                        {!loading && (
                            <Map
                                style={`${lightTheme ? 'mapbox://styles/mapbox/light-v10' : 'mapbox://styles/mapbox/dark-v10'}`}
                                fitBounds={calculateBounds([
                                    ...activities.map(activity => activity.loc.coordinates),
                                    // bounds,
                                    currentLocation ? currentLocation.coordinates : null,
                                ].filter(c => c))}
                                fitBoundsOptions={{padding: window.innerWidth <= 960 ? window.innerWidth <= 540 ? 130 : 180 : 200}}
                                containerStyle={{ width, height: '100%' }}
                                key={mapView}>
                                    <MarkersOverlay
                                        currentLocation={currentLocation}
                                        pinDataShow={!pinDataShow ? false : mapView ? true : false}
                                        activities={activities}
                                        selectedActivity={selectedActivity}
                                        query={query}
                                        scrollable={scrollable}
                                        calculateDistance={calculateDistance}
                                        showRoute={showRoute}
                                        profiles={profiles}
                                        details={details}
                                        geometries={geometries}
                                        getDuration={getDuration}
                                        guidePage={guidePage}
                                        loaded={() => { return false }}
                                        streetView={supportStreet}
                                        currentView={mapView ? 'map' : 'street'}
                                    />
                            </Map>
                        )}
                    </div>
                    {currentLocation && (
                        <a
                            href={`https://www.google.com/maps/dir/${currentLocation.coordinates[1]},${currentLocation.coordinates[0]}/${selectedActivity.loc.coordinates[1]},${selectedActivity.loc.coordinates[0]}`}
                            target="_blank"
                            className="google-map-link"
                            data-mapview={mapView}>
                                <img
                                    src="/icons/google-maps.svg"
                                    alt="gmap-logo"
                                    height="49px"/>
                        </a>
                    )}
        </div>
    )
}

export default MapBox