import React, { useState, useEffect } from 'react'
import { Input } from '../../../components/theme'
import DurationModal from './DurationModal'
import BudgetModal from './BudgetModal'
import styles from './index.module.scss'

const Details = ({
    activity,
    onChange
}) => {
    const [bookingUrlFocused, setBookingUrlFocused] = useState(false)
    const [priceFocused, setPriceFocused] = useState(false)
    const [description, setDescription] = useState(null)
    const [tip, setTip] = useState(null)
    const [bookingUrl, setBookingUrl] = useState(null)
    const [groupSize, setGroupSize] = useState(null)
    const [duration, setDuration] = useState(null)
    const [radScore, setRadScore] = useState(null)
    const [price, setPrice] = useState(null)
    const [whatToWear, setWhatToWear] = useState(null)
    const [whatToBring, setWhatToBring] = useState(null)
    const [whereToMeet, setWhereToMeet] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [budgetRange, setBudgetRange] = useState(null)
    const [languages, setLanguages] = useState(null)
    const [ac, setAc] = useState({})

    const update = (info = null) => {
        const data = {}

        if (info.phoneNumber !== undefined) data.phoneNumber = info.phoneNumber
        if (info.budgetRange !== undefined) data.budgetRange = info.budgetRange
        if (info.languages !== undefined) data.languages = info.languages
        if (info.description !== undefined) data.description = info.description
        if (info.tip !== undefined) data.tip = info.tip
        if (info.bookingUrl !== undefined) data.bookingUrl = info.bookingUrl
        if (info.price !== undefined) data.asLowAsPrice = parseInt(info.price)
        if (info.duration !== undefined) data.duration = info.duration
        if (info.groupSize !== undefined) data.groupSize = parseInt(info.groupSize)
        if (info.whatToBring !== undefined) data.whatToBring = info.whatToBring
        if (info.whatToWear !== undefined) data.whatToWear = info.whatToWear
        if (info.whereToMeet !== undefined) data.whereToMeet = info.whereToMeet
        if (info.radScore !== undefined) data.radScore = parseInt(info.radScore)
        if (info.tags !== undefined) data.tags = info.tags
        if (info.medias !== undefined) data.medias = info.medias

        onChange({...data})
    }

    useEffect(() => {
        if (phoneNumber === null || ac.name !== activity.name) setPhoneNumber(activity.phoneNumber)
        if (budgetRange === null || ac.name !== activity.name) setBudgetRange(activity.budgetRange)
        if (languages === null || ac.name !== activity.name) setLanguages(activity.languages)
        if (description === null || ac.name !== activity.name) setDescription(activity.description)
        if (tip === null || ac.name !== activity.name) setTip(activity.tip)
        if (price === null || ac.name !== activity.name) setPrice(activity.asLowAsPrice)
        if (duration === null || ac.name !== activity.name) setDuration(activity.duration)
        if (groupSize === null || ac.name !== activity.name) setGroupSize(activity.groupSize)
        if (whatToBring === null || ac.name !== activity.name) setWhatToBring(activity.whatToBring)
        if (whatToWear === null || ac.name !== activity.name) setWhatToWear(activity.whatToWear)
        if (whereToMeet === null || ac.name !== activity.name) setWhereToMeet(activity.whereToMeet)
        if (radScore === null || ac.name !== activity.name) setRadScore(activity.radScore)
        if (bookingUrl === null || ac.name !== activity.name) setBookingUrl(activity.bookingUrl)

        setAc(activity)
    }, [activity])

    return (
        <div className={styles.details}>
            <div className={styles.info}>
                <div>
                    <label htmlFor="description">
                        Tell us about it!
                    </label>
                    <Input
                        id="description"
                        textarea={true}
                        rows={3}
                        value={description}
                        placeholder="A 3 to 5 sentence review"
                        onChange={(e) => {
                            setDescription(e.target.value)
                            update({description: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="tip">
                        Insider tip
                    </label>
                    <Input
                        id="tip"
                        textarea={true}
                        rows={1}
                        value={tip}
                        placeholder="Enter a tip"
                        onKeyUp={(e) => update({tip: e.target.value})}
                        onChange={(e) => {
                            setTip(e.target.value)
                            update({tip: e.target.value})
                        }}
                    />
                </div>
                <div className={styles.bookingUrl}>
                    <span data-filled={(bookingUrl || bookingUrlFocused) ? true : false}>https://</span>
                    <label htmlFor="booking-url">
                        Booking URL
                    </label>
                    <Input
                        id="booking-url"
                        value={bookingUrl ? bookingUrl.replace('https://', '') : ''}
                        type="text"
                        onClick={() => setBookingUrlFocused(true)}
                        onBlur={() => setBookingUrlFocused(false)}
                        onChange={(e) => {
                            setBookingUrl(e.target.value)
                            update({bookingUrl: `${e.target.value ? `https://${e.target.value.replace('https://', '')}` : ''}`})
                        }}
                    />
                </div>
                <div className={styles.price}>
                    <span data-filled={(price || priceFocused) ? true : false}>$</span>
                    <label htmlFor="price">
                        "As Low As" price
                    </label>
                    <Input
                        id="price"
                        value={price}
                        type="number"
                        onClick={() => setPriceFocused(true)}
                        onBlur={() => setPriceFocused(false)}
                        onChange={(e) => {
                            if (e.target.value.length !== 0 && e.target.value * 1 < 1) return
                            setPrice(e.target.value)
                            update({price: e.target.value})
                        }}
                    />
                </div>
                <div className={styles.duration}>
                    <DurationModal
                        duration={duration}
                        setDuration={(duration) => {
                            setDuration(duration)
                            update({duration})
                        }}/>
                </div>
                <div>
                    <label htmlFor="groupSize">
                        Optimal Group Size (Number)
                    </label>
                    <Input
                        id="groupSize"
                        value={groupSize}
                        type="number"
                        onChange={(e) => {
                            setGroupSize(e.target.value)
                            update({groupSize: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="radScore">
                        RAD Score (1 to 5)
                    </label>
                    <Input
                        id="radScore"
                        value={radScore}
                        type="number"
                        onChange={(e) => {
                            const {value} = e.target
                            const parsedValue = parseInt(value)
                            if ((parsedValue > 5 || parsedValue < 1) && !isNaN(parsedValue)) return
                            setRadScore(e.target.value)
                            update({radScore: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="whatToBring">
                        What to bring
                    </label>
                    <Input
                        id="whatToBring"
                        value={whatToBring}
                        textarea={true}
                        placeholder="A 3 to 5 sentence review"
                        rows={3}
                        type="text"
                        onChange={(e) => {
                            setWhatToBring(e.target.value)
                            update({whatToBring: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="whatToWear">
                        What to wear
                    </label>
                    <Input
                        id="whatToWear"
                        textarea={true}
                        rows={3}
                        value={whatToWear}
                        placeholder="A 3 to 5 sentence review"
                        onChange={(e) => {
                            setWhatToWear(e.target.value)
                            update({whatToWear: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="whereToMeet">
                        Where to meet
                    </label>
                    <Input
                        id="whereToMeet"
                        textarea={true}
                        rows={3}
                        value={whereToMeet}
                        placeholder="A 3 to 5 sentence review"
                        onChange={(e) => {
                            setWhereToMeet(e.target.value)
                            update({whereToMeet: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="whereToMeet">
                        Phone Number
                    </label>
                    <Input
                        id="whereToMeet"
                        rows={3}
                        value={phoneNumber}
                        placeholder="Phone"
                        onChange={(e) => {
                            setPhoneNumber(e.target.value)
                            update({phoneNumber: e.target.value})
                        }}
                    />
                </div>
                <div>
                    <BudgetModal
                        range={budgetRange}
                        setBudget={(budgetRange) => {
                            setBudgetRange(budgetRange)
                            update({budgetRange})
                    }}/>    
                </div>
                <div>
                    <label htmlFor="whereToMeet">
                        Languages
                    </label>
                    <Input
                        id="languages"
                        textarea={true}
                        rows={3}
                        value={languages}
                        placeholder="A 3 to 5 sentence review"
                        onChange={(e) => {
                            setLanguages(e.target.value)
                            update({languages: e.target.value})
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Details