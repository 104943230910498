import React, { useState, useEffect } from 'react'
import Image from '../Image'

const Avatar = ({
    width,
    height,
    downloadWidth = 200,
    profilePicture,
    onLoad
}) => {
    const [explorerImageError, setExplorerImageError] = useState(false)

    useEffect(() => {
        setExplorerImageError(false)
    }, [profilePicture])

    return (
        <div>
            {!explorerImageError && profilePicture && (
                <Image
                    src={profilePicture}
                    className="object-cover"
                    options={`w_${downloadWidth},f_auto`}
                    width={width}
                    height={height}
                    alt="Avatar"
                    onLoad={onLoad}
                    onError={() => setExplorerImageError(true)}/>
            )}
            <img
                src="/icons/avatar.svg"
                alt={`Card User`}
                className={!explorerImageError && profilePicture ? 'd-none' : 'object-cover'}
                width={width}
                height={height}/>
        </div>
    )
}

export default Avatar