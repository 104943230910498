import React from 'react'
import { useHistory } from 'react-router-dom'
import Helmet from 'react-helmet'
import { Button, Icon } from '../../components/theme'
import TermsContent from '../../components/TermsContent'
import styles from './index.module.scss'

const Terms = () => {
    const history = useHistory()
    return (
        <section className={styles.section}>
            <Helmet>
                <title>Curator | RAD | Terms</title>
            </Helmet>
            <Button
                bordered={false}
                icon={true}
                height="4.33rem"
                width="4.33rem"
                onClick={() => history.goBack()}>
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <TermsContent/>
        </section>
    )
}

export default Terms