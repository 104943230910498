import React, { useEffect, useState, useRef } from 'react'
import { COUNTRIES } from '../../../constants'
import styles from './index.module.scss'

const DropDown = ({
    type = 'places',
    choose,
    suggestions = [],
    style,
    width,
    onOutsideClick = () => {},
    value,
    search = '',
    setSearch = () => {},
    showNotInList = false,
    onNotList
}) => {
    const [visible, setVisible] = useState(false)
    const dropDownRef = useRef(null)

    const dropDownOutSideClick = (e) => {
        if (
            dropDownRef.current.contains(e.target) ||
            dropDownRef.current === e.target ||
            e.target.hasAttribute('data-search') ||
            e.target.id === 'activity-name'
        ) {
            return
        }
        onOutsideClick()
        setVisible(false)
    }

    useEffect(() => {
        if (suggestions.length > 0 || search) {
            setVisible(true)
            return
        }
        setVisible(false)
    }, [suggestions, search])

    useEffect(() => {
        window.addEventListener('click', dropDownOutSideClick)
        window.addEventListener('touchend', dropDownOutSideClick)

        return () => {
            window.removeEventListener('click', dropDownOutSideClick)
            window.removeEventListener('touchend', dropDownOutSideClick)
        }
    }, [search])

    return (
        <ul
            className={styles.dropDown}
            data-visible={visible}
            ref={dropDownRef}
            style={{...style, width}}>
                {
                    type === 'country' &&
                    COUNTRIES
                    .filter(country => {
                        const regex = new RegExp(search, 'gi')
                        return regex.test(country)
                    })
                    .map((country, index) => (
                        <li
                            key={`${index}-${country}`}
                            data-active={country === value}
                            onClick={() => {
                                setVisible(false)
                                choose(country)
                            }}>
                                {country}
                        </li>
                ))}
                {showNotInList && (
                    <li
                        data-search={true}
                        onClick={() => {
                            onNotList()
                            setSearch('')
                        }}>
                        <span>Not in this list ?</span>
                    </li>
                )}
                {suggestions.map((suggestion, i) => (
                    <li
                        key={`${suggestion.name}-${i}`}
                        data-active={suggestion.name === value}
                        onClick={() => {
                            setVisible(false)
                            choose(suggestion)
                        }}>
                            {suggestion.name}
                    </li>
                ))}
        </ul>
    )
}

export default DropDown