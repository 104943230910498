import React from 'react'
import { AreaClosed, LinePath, Line, Bar } from '@vx/shape'
import { Group } from '@vx/group'
import { appleStock } from '@vx/mock-data'
import { curveMonotoneX } from '@vx/curve'
import { AxisTop } from '@vx/axis'
import { scaleTime, scaleLinear } from '@vx/scale'
import { withTooltip, Tooltip } from '@vx/tooltip'
import { localPoint } from '@vx/event'
import { bisector } from 'd3-array'
import { timeFormat } from 'd3-time-format'
import styles from './index.module.scss'

const Metrics = ({
    showTooltip,
    width = 584,
    height = 120,
    margin = {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    hideTooltip,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    events
}) => {
    const stock = appleStock.slice(1250)
    
    const gradientColor = '238,231,224'
    const white = '#FAF5F1'
    const black = '#0E0E0E'
    const formatDate = timeFormat("%b %d, '%y")
    const min = (arr, fn) => Math.min(...arr.map(fn))
    const max = (arr, fn) => Math.max(...arr.map(fn))
    const extent = (arr, fn) => [min(arr, fn), max(arr, fn)]
    
    // accessors
    const xStock = d => new Date(d.date)
    const yStock = d => d.close
    const bisectDate = bisector(d => new Date(d.date)).left

    const handleTooltip = ({ event, data, xStock, xScale, yScale }) => {
        const { x } = localPoint(event)
        const x0 = xScale.invert(x)
        const index = bisectDate(data, x0, 1)
        const d0 = data[index - 1]
        const d1 = data[index]
        let d = d0

        if (d1 && d1.date) {
            d = x0 - xStock(d0.date) > xStock(d1.date) - x0 ? d1 : d0
        }

        showTooltip({
            tooltipData: d,
            tooltipLeft: x,
            tooltipTop: yScale(yStock(d))
        })
    }
    
    if (width < 10) return null

    // bounds

    const xMax = width - margin.left - margin.right
    const yMax = 80 - margin.top - margin.bottom

    // scales
    const xScale = scaleTime({
        range: [0, xMax],
        domain: extent(stock, xStock)
    })

    const yScale = scaleLinear({
        range: [yMax, 0],
        domain: [0, max(stock, yStock) + yMax / 3],
        nice: true
    })

    return (
        <div className={styles.metrics}>
            <div>
                <span>Guide Views</span>
                <span>24 592</span>
            </div>
            <div>
                <svg
                    width={width}
                    height={height}>
                        <rect
                            x={0}
                            y={40}
                            width={width}
                            height={160}
                            fill="#FAF5F1"
                            rx={14}/>
                        <defs>
                            <linearGradient
                                id="gradient"
                                x1="30%"
                                y1="0%"
                                x2="70%"
                                y2="100%">
                                    <stop
                                        offset="50%"
                                        stopColor={`rgb(${gradientColor})`}
                                        // stopColor="rgb(255, 255, 255)"
                                        stopOpacity={.6}/>
                                    <stop
                                        offset="90%"
                                        stopColor={`rgba(${gradientColor}, 0.1)`}
                                        stopOpacity={.7}/>
                            </linearGradient>
                        </defs>
                        <AreaClosed
                            data={stock}
                            x={d => xScale(xStock(d))}
                            y={d => yScale(yStock(d))}
                            yScale={yScale}
                            strokeWidth={1}
                            stroke={white}
                            fill={'url(#gradient)'}
                            curve={curveMonotoneX}
                            style={{
                                transform: 'translateY(40px)'
                            }}/>
                        <LinePath
                            data={stock}
                            x={d => xScale(xStock(d))}
                            y={d => yScale(yStock(d))}
                            stroke={"rgb(14,14,14)"}
                            strokeWidth={1}
                            curve={curveMonotoneX}
                            style={{
                                transform: 'translateY(40px)'
                            }}/>
                        <Group>
                            <AxisTop
                                top={-5}
                                left={17}
                                scale={xScale}
                                hideZero
                                numTicks={2}>
                                    {axis => {
                                        const tickLabelSize = 20
                                        return (
                                            <g>
                                                {axis.ticks.map((tick, i) => {
                                                    const tickX = tick.to.x
                                                    const tickY = tick.to.y + tickLabelSize + axis.tickLength
                                                    return (
                                                        <Group
                                                            key={`vx-tick-${tick.value}-${i}`}
                                                            className={styles.axis}>
                                                                <text
                                                                    transform={`translate(${(width / axis.ticks.length) * i}, ${tickY})`}
                                                                    // transform={`translate(${tickX}, ${tickY})`}
                                                                    fontSize={tickLabelSize}
                                                                    textAnchor="middle"
                                                                    fill={black}>
                                                                        {tick.formattedValue}
                                                                </text>
                                                        </Group>
                                                    )
                                                })}
                                            </g>
                                        )
                                    }}
                            </AxisTop>
                        </Group>
                        <Bar
                            x={0}
                            y={40}
                            width={width}
                            height={120}
                            fill="transparent"
                            rx={14}
                            data={stock}
                            onTouchStart={event =>
                                handleTooltip({
                                    event,
                                    xStock,
                                    xScale,
                                    yScale,
                                    data: stock
                                })
                            }
                            onTouchMove={event =>
                                handleTooltip({
                                    event,
                                    xStock,
                                    xScale,
                                    yScale,
                                    data: stock
                                })
                            }
                            onMouseMove={event =>
                                handleTooltip({
                                    event,
                                    xStock,
                                    xScale,
                                    yScale,
                                    data: stock
                                })
                            }
                            onMouseLeave={event => hideTooltip()}/>
                        {tooltipData && (
                            <g 
                                style={{
                                    transform: 'translateY(40px)'
                                }}>
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop}
                                        r={4}
                                        fill={white}
                                        stroke={black}
                                        strokeWidth={1}
                                        style={{ pointerEvents: 'none' }}/>
                            </g>
                        )}
                </svg>
                {tooltipData && (
                    <div>
                        <Tooltip
                            top={tooltipTop - 100}
                            left={tooltipLeft - 30}
                            className={styles.toolTip}>
                                {`${yStock(tooltipData)}`}
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    )
}

export default withTooltip(Metrics)