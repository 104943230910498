import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate, useExplorer } from '../hooks'
import http from '../http'

export const UserContext = React.createContext()

export const useAuth = () => {
    const data = useContext(UserContext)   

    return data
}

const withAuth = Component => ({
    handleErrors,
    ...props
}) => {
    const {verify: verifyEmail, verifyData} = useExplorer()
    const [user, setUser] = useState(null)
    const [verified, setVerified] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()
    const login = (method, data = {}) => {
        if (method === 'email') {
            return new Promise((resolve, reject) => {
                http
                    .post(`/api/auth/${method}`, data)
                    .then(response => {
                        localStorage.setItem('token', response.data.token)
                        Object.assign(http.defaults, { headers: { 'Authorization': `Bearer ${localStorage.token}` } })
                        checkAuth()
                        if (response.data.organization) {
                            return resolve(response.data.organization)
                        }
                        navigate(`/e/${response.data.user.email}`)
                    })
                    .catch(e => {
                        if (e.response && e.response.data.message) {
                            if (e.response.data.error === 'verification') setVerified(false)
                            return reject(e.response.data.message)
                        }
                        return reject('Network Error')
                    })
            })
        }

        http
            .get(`/api/auth/${method}`)
            .then(response => window.location = response.data.redirectUri)
    }

    const logout = (e, redirect = true) => {
        e.preventDefault()
        
        localStorage.removeItem('token')
        Object.assign(http.defaults, { headers: {} })
        setUser(null)
        checkAuth()
        if (redirect) navigate('/')
    }

    const resendVerificationEmail = (email) => {
        return new Promise((resolve) => {
            const params = {email}
            http
                .get('/api/resend/verification', {params})
                .then(
                    () => {
                        setVerified(true)
                        return resolve('Please check your email')
                    }
                )
                .catch((e) => alert(e || e.message))
        })
    }

    const auth = (method, code) => {
        http
            .post(`/api/auth/${method}`, { code }, { headers: { 'X-Authorization': true } })
            .then(response => {
                navigate('/')
                localStorage.setItem('token', response.data.token)
                Object.assign(http.defaults, { headers: { 'Authorization': `Bearer ${localStorage.token}` } })
                checkAuth(false)
            })
            .catch(e => console.log(e.response || e))
    }

    const checkAuth = (useQuery = true) => {
        if (useQuery) {
            const query = {}
            location.search.slice(1).split('&').map(param => param.split('=')).forEach(param => query[param[0]] = param[1])

            if (query.code) {
                if (query.scope) {
                    return auth('google', query.code)
                } else if (query.apple) {
                    return auth('apple', query.code)
                }
            
                return auth('facebook', query.code)
            }
        }

        if (!localStorage.getItem('token')) {
            setUser({})
            return
        }

        http
            .get('/api/auth')
            .then((response) => setUser(response.data.user || {}))
            .catch((e) => setUser({}))
    }

    const updateUser = user => setUser(user)

    useEffect(() => {
        checkAuth()
    }, [])

    useEffect(() => {
        if (!verifyData) return
        window.location = `${window.location.protocol}//${window.location.host}/sign-in`
    }, [verifyData])

    if (user === null) return <></>

    return (
        <div>
            <UserContext.Provider value={{user, login, logout, verifyEmail, resendVerificationEmail, verified, updateUser, handleErrors}}>
                <Component
                    {...props}/>
            </UserContext.Provider>
        </div>
    )
}

export default withAuth