import React, { useState, useEffect } from 'react'
import http from '../../http'
import { useAuth, useNavigate } from '../../hooks'
import TermsContent from '../../components/TermsContent'    
import { Button } from '../../components/theme'
import styles from './index.module.scss'

const AcceptTerms = ({
    Alert
}) => {
    const {user} = useAuth()
    const [agree, toggleAgree] = useState(false)
    const navigate = useNavigate()

    const submit = () => {
        http
            .put('/api/explorer', {acceptedTerms: agree})
            .then(() => navigate(`/e/${user.email}`))
    }

    useEffect(() => {
        if (user.email && user.acceptedTerms) {
            navigate(`/e/${user.email}`)
        }
    }, [user])

    return (
        <main className={styles.main}>
            <header>
                <img
                    className={styles.logo}
                    src="/logo.svg"
                    alt="Logo"/>
            </header>
            <TermsContent/>
            <footer>
                <div className="container text-center d-flex align-items-center justify-content-center">
                    <input
                        type="checkbox"
                        id="agree-checkbox"
                        onChange={() => toggleAgree(!agree)}
                    />
                    <label htmlFor="agree-checkbox" style={{ maxWidth: '60%' }}>
                        By clicking this box the Contributor agrees to all terms and conditions of this contract as outlined above.  The Contributor agrees that the check box on this Raleigh & Drake Curator website is a legally binding form of agreement between both parties.
                    </label>
                    <Button
                        type="secondary"
                        className="ml-3"
                        disabled={!agree}
                        onClick={submit}>
                            I Agree
                    </Button>
                </div>
            </footer>
        </main>
    )
}

export default AcceptTerms