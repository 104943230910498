import React from 'react'
import Link from '../../../components/Link'
import Image from '../../../components/Image'
import Avatar from '../../../components/Avatar'
import DeleteGuide from './DeleteGuide'
import { Button, Icon } from '../../../components/theme'
import { DEFAULT_GUIDE_COVER } from '../../../constants'
import styles from './index.module.scss'

const Header = ({
    guide,
    byMe
}) => {
    return (
        <header className={styles.header}>
            {guide && guide.name && (
                <Image
                    src={guide.cover && guide.cover.standard_resolution ? guide.cover.standard_resolution.url : DEFAULT_GUIDE_COVER.standard_resolution.url}
                    options="w_2000,f_auto"
                    alt={`guide cover`}
                    fromCloudinary={false}
                />
            )}
            <div className="container">
                <div className="row">
                    <div className="col-md-10 position-relative">
                        <h1 className="d-flex">
                            {guide ? guide.name : ''}
                            {byMe && (
                                <>
                                    <Link
                                        href={`/edit/guide/${guide._id}`}
                                        className="ml-2">
                                            <Button
                                                type="secondary"
                                                icon={true}
                                                bordered={false}
                                                width={40}
                                                height={40}>
                                                    <Icon
                                                        type="edit"
                                                    />
                                            </Button>
                                    </Link>
                                    <DeleteGuide guide={guide}/>
                                </>
                            )}
                        </h1>
                        {guide && guide._explorer && (
                            <div className={styles.guideCreator}>
                                <Link href={`/e/${guide && guide._explorer && (guide._explorer.email || guide._explorer._id)}`}>
                                    <Avatar
                                        profilePicture={guide && guide._explorer ? guide._explorer.profile_picture : ''}
                                        width={60}
                                        height={60}
                                    />
                                    <p className="small">
                                        by <span>{guide._explorer ? guide._explorer.full_name : ''}</span>
                                    </p>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header