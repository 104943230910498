import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import './scss/template.scss'

if (process.env.REACT_APP_ENV === 'production') {
    Sentry.init({dsn: "https://a046dcded30a49a1a22c0f5ea2ba81ac@o409898.ingest.sentry.io/5283282"})
}
ReactDOM.render(<App/>, document.getElementById('root'))

serviceWorker.register()
