import React from 'react'
import ShareModalButton from '../../../components/ShareModal/button'
import { Button, Icon, Line } from '../../../components/theme'
import styles from './index.module.scss'

const Details = ({
    activity = {},
    preview
}) => {
    return (
        <section
            className={styles.detailsSection}
            data-bookable={activity.bookingUrl ? true : false}>
                <div className="container-fluid px-4">
                    <div className="row">
                        <div className="col-md-3">
                            {!preview && (
                                <div className={styles.share}>
                                    <ShareModalButton
                                        width="4.44rem"
                                        height="4.44rem"
                                        page="activity"
                                        centered={false}
                                        url={`/activity/${activity._id}`}
                                        id={activity._id}/>
                                </div>
                            )}
                            {activity && activity.tip && (
                                <div className={`${styles.detail} ${styles.insiderTip}`}>
                                    <span>Rad Insider Tip</span>
                                    <p>{activity.tip}</p>
                                </div>
                            )}
                            {activity && activity.whatToBring && (
                                <div className={styles.detail}>
                                    <span>What to bring</span>
                                    <p>{activity.whatToBring}</p>
                                </div>
                            )}
                            {activity && activity.whatToWear && (
                                <div className={styles.detail}>
                                    <span>What to wear</span>
                                    <p>{activity.whatToWear}</p>
                                </div>
                            )}
                            {activity && activity.whereToMeet && (
                                <div className={styles.detail}>
                                    <span>Where to meet</span>
                                    <p>{activity.whereToMeet}</p>
                                </div>
                            )}
                        </div>
                        <div className={`col-md-6 ${styles.detailsInfo}`}>
                            <h2>Experience Details</h2>
                            <Line
                                primary={true}
                                style={{ marginTop: '2.5rem' }}/>
                            <div className={styles.tags}>
                                {activity && activity.category && (
                                    <Button
                                        type="primary"
                                        bordered={false}>
                                            #{activity.category}
                                    </Button>
                                )}
                                {activity && activity.tags && activity.tags.map(
                                    (tag, i) => (
                                        <Button
                                            type="primary"
                                            bordered={false}
                                            key={`tag-${i}`}>
                                                #{tag}
                                        </Button>
                                    )
                                )}
                            </div>
                            {activity && activity.description && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                            <p style="margin: 3.33rem 0 .1.5rem;">
                                                ${activity.description.replace(/\n/g,'<br>')}
                                            </p>
                                        `
                                    }}
                                />
                            )}
                        </div>
                        <div className={`col-md-3 ${styles.rightSideDetails}`}>
                            {activity && activity.duration && (
                                <div className={styles.rightSideDetail}>
                                    <Icon type="time1"/>
                                    <span>{activity.duration}</span>
                                </div>
                            )}
                            {activity && activity.groupSize && (
                                <div className={styles.rightSideDetail}>
                                    <Icon type="members"/>
                                    <span>{activity.groupSize}</span>
                                </div>
                            )}
                            {activity && activity.city && activity.city.country && activity.city.name && (
                                <div className={styles.rightSideDetail}>
                                    <Icon type="globe"/>
                                    <span>{activity.city.country}, {activity.city.name}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Details