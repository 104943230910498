import React, { useState } from 'react'
import { Editable, Line } from '../../theme'

const HeadLine = ({
    explorer,
    onChange,
    editable
}) => {
    const [focused, setFocused] = useState(false)
    return (
        <>
            <h2 className="sm">Headline</h2>
            <Editable
                tag="p"
                className="mt-2 mb-2"
                innerStyles={{ width: '100%', display: 'inline-block', cursor: 'text' }}
                defaultValue={explorer.descriptor}
                placeholder="Go ahead and give yourself a tagline"
                editable={editable}
                onChange={value => onChange({descriptor: value})}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <Line
                primary={focused}
                style={{ opacity: 1 }}
            />
        </>
    )
}

export default HeadLine