import React, { useState, useEffect, useCallback } from 'react'
import AWS from 'aws-sdk'
import randomstring from 'randomstring'
import { useDropzone } from 'react-dropzone'
import { Button, Icon } from '../../../components/theme'
import Image from '../../../components/Image'
import { useIsMobileDevice } from '../../../hooks'
import { Input } from '../../../components/theme'
import config from '../../../config'
import styles from './index.module.scss'

AWS.config.update({
    region: config.aws.region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws.identityPoolId
    })
})

const UploadImages = ({
    medias = [],
    onChange = () => {},
    setProcessing = () => {}
}) => {
    const isMobileDevice = useIsMobileDevice()
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)

    const onCaptionChange = useCallback(
        (index, value) => {
            const newImages = [...images]
            newImages[index].caption = value
            setImages(newImages)
            onChange({medias: newImages})
        },
        [images, setImages]
    )

    const upload = (files, index, uploadedImages = []) => {
        return new Promise((resolve, reject) => {
            if (!files[index]) {
                return resolve(uploadedImages)
            }

            const s3 = new AWS.S3({
                apiVersion: config.aws.s3.apiVersion,
                params: {
                    Bucket: config.aws.s3.bucket
                }
            })
    
            const random = randomstring.generate({length: 12})
            const photoKey = `radmin/${files[index].file.name}-${random}.${files[index].file.type.split('/')[1]}`
    
            s3.upload({
                Key: photoKey,
                Body: files[index].file,
                ACL: 'public-read'
            }, (err, data) => {
                if (err) {
                    alert('Error')
                    return reject(err)
                }
                const newImage = {
                    standard_resolution: {
                        height: 0,
                        width: 0,
                        url: data.Location
                    },
                    thumbnail: {
                        height: 0,
                        width: 0,
                        url: data.Location
                    },
                    low_resolution: {
                        height: 0,
                        width: 0,
                        url: data.Location
                    },
                    caption: '',
                    key: files[index].key,
                    blobUrl: files[index].blobUrl
                }

                if (images.length === 0 && index === 0) {
                    newImage.cover = true
                }

                return resolve(upload(files, index + 1, [...uploadedImages, newImage]))
            })
        })
    }

    const onDrop = useCallback(acceptedFiles => {
        setProcessing(true)
        setLoading(true)
        const newImages = []
        Object.values(acceptedFiles).forEach(file => {
            const blobUrl = URL.createObjectURL(file)
            newImages.push({
                key: new Date().getTime() * Math.random(),
                file,
                blobUrl
            })
        })
        setImages([...images, ...newImages])
        upload(newImages, 0)
            .then(newUploadedImages => {
                const data = [...images, ...newUploadedImages]
                onChange({medias: data})
                setImages(data)
                setLoading(false)
                setProcessing(false)
            })
            .catch(e => {
                setProcessing(true)
                console.log(e.message || e)
            })
    }, [images])

    const removeImage = (image) => {
        const newImages = [...images.filter(i => i !== image)]
        setImages(newImages)

        const data = {medias: newImages}
        onChange({...data})
    }

    const coverChange = (index) => {
        const newImages = [...images]
        newImages.forEach((image, i) => {
            if (index === i) {
                image.cover = true
                return
            }
            image.cover = false
        })
        setImages([...newImages])
        onChange({medias: newImages})
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        if (medias.length === 0 && images.length === 0) return
        if (medias.length === 0) {
            setImages([])
            return
        }
        setImages([...medias])
    }, [medias])

    return (
        <div className={`${styles.images}`}>
            <div className={styles.dragAndDropWrapper} {...getRootProps()}>
                <input {...getInputProps()} />
                <span>
                    {
                        loading ? '' : (
                            <>
                                {
                                    isDragActive ?
                                        'Drop the files here ...'
                                    :
                                        `${isMobileDevice ? 'Click to select files' : `Drag 'n' drop some files here, or click to select files`}`
                                }
                            </>
                        )
                    }
                </span>
                {loading ? (
                    <span>Uploading ...</span>
                ) : (
                    <Button
                        type="primary"
                        width={55}
                        height={55}
                        icon={true}
                        circle={true}
                        className={styles.uploadButton}>
                            <Icon type="upload"/>
                    </Button>
                )}
            </div>
            {images.map((image, i) => (
                <div
                    className={`${styles.imageSection}`}
                    key={`key-${image.key}-${i}`}>
                        <div className={styles.image}>
                            {image.blobUrl ? (
                                <img
                                    src={image.blobUrl}
                                    alt={`blob-${image.blobUrl}`}
                                />
                            ) : (
                                <Image
                                    key={`image-${i}`}
                                    src={image.standard_resolution.url}
                                    options="w_400,f_auto"
                                    alt={`Image ${image}`}/>
                            )}
                            <div className={styles.tools}>
                                <div
                                    className={styles.coverButton}
                                    onClick={() => coverChange(i)}>
                                        <span data-selected={image.cover}/>
                                        <span>Set as a cover</span>
                                </div>
                                {!loading && (
                                    <button
                                        className={styles.deleteButton}
                                        onClick={() => removeImage(image)}>
                                        <Icon
                                            type="trash-light"/>
                                    </button>
                                )}
                            </div>
                        </div>
                        <label
                            htmlFor={`caption-${i}`}
                            className={styles.captionLabel}>
                            Caption
                        </label>
                        <Input
                            id={`caption-${i}`}
                            className={styles.captionInput}
                            placeholder="Caption"
                            value={image.caption}
                            onChange={e => onCaptionChange(i, e.target.value)}
                            />
                        {/* <div className={styles.tools}>
                            <div
                                className={styles.coverButton}
                                onClick={() => coverChange(i)}>
                                    <span data-selected={image.cover}/>
                                    <span>Set as a cover</span>
                            </div>
                            {!loading && (
                                <button onClick={() => removeImage(image)}>
                                    <Icon
                                        type="trash-light"/>
                                </button>
                            )}
                        </div> */}
                </div>
            ))}

        </div>
    )
}

export default UploadImages