import { useState, useEffect } from 'react'

const useScript = (src) => {
    const [loaded, load] = useState(false)

    const onLoad = () => load(true)
    const onError = () => load(false)

    function  initAutocomplete () {
        console.log('Initilizing')
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.addEventListener('load', onLoad)
        script.addEventListener('error', onError)

        document.body.appendChild(script)

        return () => {
            script.removeEventListener('load', onLoad)
            script.removeEventListener('error', onError)
        }
    }, [])

    return loaded
}

export default useScript