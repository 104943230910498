import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withAlert } from '../../hocs'
import { useAuth, useExplorer, useOrganization, useNavigate } from '../../hooks'
import { ProfileInfo, Bio, HeadLine, Name, GuidesSection, ActivitiesSection, Header } from '../../components/Explorer'
import Loading from '../../components/Loading'
import Nav from '../../components/Nav'
import Sidebar from './Sidebar'
import styles from './index.module.scss'

const Explorer = ({
  toggleMenu
}) => {
  const urlParams = (new URL(document.location)).searchParams
  const { email: explorerEmail } = useParams()
  const { getByEmail, getByEmailData, update, updateData } = useExplorer()
  const navigate = useNavigate()
  const { updateUser, user } = useAuth()
  const { organization } = useOrganization()
  const [explorer, setExplorer] = useState(undefined)
  const [editable, setEditable] = useState(false)
  const [userType, setUserType] = useState(null)
  const [editableState, setEditableState] = useState({})
  const history = useHistory()

  const getExplorer = () => {
    if (explorerEmail === user.email) {
      setEditableState(user)
      setExplorer(user)
      setUserType('owner')
      return
    }
    if (!organization) setUserType('guest')
    getByEmail({ email: explorerEmail })
  }

  const checkPermissions = () => {
    if (!organization || !urlParams.has('editable')) return
    if (!organization.permissions['editExplorer']) {
      alert('Invalid token for editing user')
      return navigate('/404')
    }
    if (!urlParams.has('redirect_url')) {
      alert('Please provide `redirect_url` in url.')
      return navigate('/404')
    }
    setUserType('organization')
    setEditable(true)
  }

  useEffect(() => {
    checkPermissions()
  }, [])

  useEffect(() => {
    if (!user) return
    getExplorer()
  }, [explorerEmail, user])

  useEffect(() => {
    if (!getByEmailData) return
    if (!getByEmailData.data.explorer && !organization) {
      setExplorer(null)
      history.push('/404')
      return
    }
    const explorer = getByEmailData.data.explorer || organization
    delete explorer.password
    setExplorer(explorer)
    if (userType === 'organization') {
      setEditableState(explorer)
    }
  }, [getByEmailData, userType])

  useEffect(() => {
    if (!updateData) return
    setExplorer(updateData.data.user)
    updateUser(updateData.data.user)
    if (userType === 'organization') {
      window.location.href = urlParams.get('redirect_url')
    }
  }, [updateData, userType])

  return (
    <section
      className={styles.section}
      data-guest={userType === 'guest'}
    >
      <Helmet>
        <title>Curator | RAD | Explorer</title>
      </Helmet>

      <Loading
        status={explorer === undefined ? 'loading' : 'loaded'}
      />

      {explorer === null ? (
        <div className={styles.notFound}>
          <h1>Not Found</h1>
        </div>
      ) : (
        <>
          {explorer && (
            <>
              <Nav toggleMenu={toggleMenu}/>

              <Header
                editable={editable}
                editableState={editableState}
                setEditableState={setEditableState}
                explorer={explorer}
              />

              <div className={`container-fluid ${styles.profileInfo}`}>
                <div className="container position-relative">
                  <ProfileInfo
                    explorer={explorer}
                    onChange={(data) => setEditableState(editableState => ({ ...editableState, ...data }))}
                    userType={userType}
                    editable={editable}
                    editableState={editableState}
                    setProfileEditable={(bool) => {
                      if (bool) {
                        return setEditable(true)
                      }
                      setEditable(false)
                      update({ ...editableState })
                    }}
                    profileEditable={editable}
                  />

                  <div className={`row ${styles.details}`}>
                    <Sidebar
                      explorer={explorer}
                      guest={userType === 'guest'}
                      editable={editable}
                      editableState={editableState}
                      setEditableState={setEditableState}
                    />

                    <div className="col-md-8">
                      <Name
                        explorer={explorer}
                        onChange={(data) => setEditableState(editableState => ({ ...editableState, ...data }))}
                        editable={editable}
                      />
                      {editable && (
                        <HeadLine
                          explorer={explorer}
                          onChange={(data) => setEditableState(editableState => ({ ...editableState, ...data }))}
                          editable={editable
                          }/>
                      )}

                      <Bio
                        explorer={explorer}
                        onChange={(data) => setEditableState(editableState => ({ ...editableState, ...data }))}
                        editable={editable}
                      />

                      {userType !== 'organization' && !editable && (
                        <>
                          <GuidesSection
                            guest={userType === 'guest'}
                            explorer={explorer}
                          />

                          <ActivitiesSection
                            guest={userType === 'guest'}
                            explorer={explorer}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </section>
  )
}

export default withAlert(Explorer)