import React, { useState } from 'react'
import { Editable, Line } from '../../theme'
import styles from './index.module.scss'

const Name = ({
    explorer,
    onChange,
    editable
}) => {
    const [focused, setFocused] = useState(false)
    return (
        <div className={styles.section}>
            <h2 className="sm m-0">{editable ? 'Your name (or alias)' : (explorer.full_name) && `Who is ${explorer.full_name}?`}</h2>
            {editable && (
                <>
                    <Editable
                        tag="span"
                        className="mt-2 mb-2 d-block"
                        innerStyles={{ width: '100%', display: 'inline-block', cursor: 'text' }}
                        defaultValue={explorer.full_name}
                        placeholder="What should we call you? (aliases are fine!)"
                        editable={editable}
                        onChange={value => onChange({full_name: value})}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                    />
                    <Line
                        primary={focused}
                        style={{ opacity: 1 }}
                    />
                </>
            )}
        </div>
    )
}

export default Name