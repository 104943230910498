import React from 'react'

const Icon = ({
    type,
    width,
    height,
    style,
    onClick = () => {}
}) => {
    return (
        <img
            src={`/icons/${type}.svg`}
            alt={`${type} icon`}
            style={{...style, width, height}}
            onClick={onClick}/>
    )
}

export default Icon