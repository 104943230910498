import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, Input, Icon } from '../../components/theme'
import { withAlert } from '../../hocs'
import { useNavigate } from '../../hooks'
import http from '../../http'
import styles from './index.module.scss'

const ForgotPassword = ({
    Alert
}) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
    const history = useHistory()

    const changePassword = (data) => {
        const params = (new URL(document.location)).searchParams

        if (!params.has('token')) {
            setErrorMessage('Token is required in url')
            return
        }

        if (!password || !confirmPassword) {
            setErrorMessage('Passwords are required')
            return
        }

        const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        if (!regex.test(password)) {
            return setErrorMessage('New password must contain at least one uppercase, lowercase letter(s), number(s) and minimum 8 characters')
        }

        if (password !== confirmPassword) {
            return setErrorMessage(`Passwords don't match`)
        }

        http
            .put('/api/change/password', { ...data, token: params.get('token') })
            .then(
                (response) => {
                    if (!response.data.success) return
                    Alert({title: 'Changed successfully'})
                    setTimeout(() => {
                        navigate('/sign-in')
                    }, 3000)
                }
            )
            .catch(
                e => {
                    console.log(e)
                    if (!e.response || !e.response.data || !typeof e.response.data === 'string') return
                    setErrorMessage(e.response.data)
                }
            )
    }

    return (
        <section className={`${styles.section} pt-3`} >
            <Helmet>
                <title>Curator | RAD | Change Password</title>
            </Helmet>
            <Button
                bordered={false}
                icon={true}
                height="4.33rem"
                width="4.33rem"
                onClick={() => history.goBack()}
                >
                    <Icon
                        width="1.5rem"
                        type="close"
                    />
            </Button>
            <div className="container">
                <div className="row d-flex justify-content-end">
                    <div className="col-md-5 text-center">
                        <div className={styles.card}>
                            <h3 className="mb-5">Set new password</h3>
                            <div className="row pt-1 mb-5 justify-content-center">
                                <div className="col-10">
                                    <Input
                                        type="password"
                                        placeholder="Enter new password"
                                        value={password}
                                        error={errorMessage ? true : false}
                                        onKeyUp={(e) => {if (e.keyCode === 13) changePassword({password, confirmPassword})}}
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                            if (e.target.value === confirmPassword) setErrorMessage('')
                                        }}/>
                                    <Input
                                        type="password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        error={errorMessage ? true : false}
                                        className="mt-3"
                                        onKeyUp={(e) => {if (e.keyCode === 13) changePassword({password, confirmPassword})}}
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value)
                                            if (e.target.value === password) setErrorMessage('')
                                        }}/>
                                    {errorMessage && (
                                        <span className="error-message mt-1">
                                            {errorMessage}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-5 pl-1 pr-1">
                                    <Button
                                        type="primary"
                                        className="mt-1"
                                        width="100%"
                                        onClick={() => changePassword({password, confirmPassword})}>
                                            Change
                                    </Button>
                                </div>
                            </div>
                            <p className="small mt-5">
                                By clicking the button you agree to the <a href="/#"> T&C </a> of the products <a href="/#"> *Terms </a> apply
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    )
}

export default withAlert(ForgotPassword)