import React, { useState, useEffect, useRef } from 'react'
import { useWindowSize } from '../../hooks'
import { CLOUDINARY_URL } from '../../constants'
import Image from '../Image'

function Pin ({
    data,
    scrollPosition,
    viewport,
    currentLocation = null,
    query,
    favorite,
    isLiked,
    scrollable,
    activity,
    calculateDistance,
    updateDuration = () => {},
    loaded = () => {},
    dataShow = true,
    activeId,
    setActiveId,
    guidePage,
    profilePicture = null
}) {
    const pin = useRef(null)
    const [active, setActive] = useState(true)
    const {width} = useWindowSize()
    const element = data._id ? document.querySelector(`.side-activities .activity-container.activity-${data._id}`) : null

    const getFormatedAddress = (google_address) => {
        let address = google_address
        if (address.length > 51) {
            address = address.substring(0, 51) + ' ...'
        }
        return address
    }

    const getFormatedName = (activityName) => {
        let name = activityName
        if (name.length > 15) {
            name = name.substring(0, 15) + ' ...'
        }
        return name
    }

    const getCover = () => {
        const cover = data.medias.find(media => media.cover)
        if (cover && cover.low_resolution && cover.low_resolution.url) {
            return cover.low_resolution.url.replace(/https?/g, "https")
        }
        return data.medias[0].low_resolution.url.replace(/https?/g, "https")
    }

    useEffect(() => {
        if (!element || !viewport || !scrollPosition || !scrollable) return

        let isActive = false
        if (viewport.width > 540) {
            isActive = data._id === activeId
        } else {
            const mapPin = document.querySelector(`.mapboxgl-marker .pin.pin-${data._id} .marker`)
            if (!mapPin) {
                return
            }

            isActive = scrollPosition.x > pin.current.offsetLeft - 100 && scrollPosition.x < pin.current.offsetLeft + pin.current.offsetWidth - 60

            if (isActive) {
                mapPin.style.background = '#00f'
            } else {
                mapPin.style.background = '#000'
            }
        }
        if (isActive && currentLocation) {
            const miles = Math.round(calculateDistance(
                [currentLocation.coordinates[0], currentLocation.coordinates[1]],
                [data.loc.coordinates[0], data.loc.coordinates[1]]
            ))

            document.querySelectorAll(`.pin.pin-${data._id} .distance`).forEach(pin => {
                pin.innerHTML = `${miles} Miles from ${currentLocation.name === 'Your Location' ? 'you' : currentLocation.name}`
            })
            updateDuration(data.loc.coordinates)
        }
    }, [scrollPosition, viewport])

    useEffect(() => {
        loaded()
    }, [])

    useEffect(() => {
        const marker = document.querySelector(`.activity-marker-${data._id}`)
        if (data._id === activeId) {
            if (marker) marker.style.zIndex = 100

            setActive(true)
            return
        }

        if (marker) marker.style.zIndex = 3
        setActive(false)
    }, [activeId])

    return (
        <div
            className={`pin ${data ? 'pin-' + data._id : ''}`}
            data-bottom={`${currentLocation ? currentLocation.coordinates[1] > data.loc.coordinates[1] : false}`}
            ref={pin}>
                <div
                    key={`${activity}-${data}`}
                    className="marker"
                    data-guide={guidePage}
                    data-background-cover={!data._id && data === 'Your Location'}
                    onClick={() => setActiveId(data._id)}
                    style={{
                        backgroundImage: data._id ? 'url(/icons/pin.svg)' : data === 'Your Location' ? `url(${profilePicture})` : 'url(/icons/airport.svg)',
                        backgroundSize: `${!data.id && data === 'Your Location' ? 'cover' : '22px 22px'}`
                    }}
                />
                {dataShow && data && activeId === data._id && (
                    <div>
                        {data._id ? (
                            <>
                                {width < 720 ? (
                                    <div className="title data-sm">
                                        {getFormatedName(data.name)}
                                        <svg viewBox="0 0 20 20">
                                            <path d="M0,0 L20,0 L10,20"/>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className={`activity-pin justify-content-between ${active ? 'is-active' : ''}`}>
                                        {data.medias[0] && (
                                            <Image
                                                src={getCover()}
                                                alt="map-activity-cover"
                                                options="w_400,f_auto"
                                                fromCloudinary={false}
                                            />
                                        )}
                                        <div className="address col">
                                            <h5>{getFormatedName(data.name)}</h5>
                                            <p className="mt-2">
                                                {activity && activity.google_info && getFormatedAddress(activity.google_info.formatted_address)}
                                            </p>
                                            {activity && activity.google_info && activity.google_info.formatted_phone_number && (
                                                <a href={`tel:${activity.google_info.formatted_phone_number}`}>
                                                    {activity.google_info.formatted_phone_number}
                                                </a>
                                            )}
                                        </div>
                                        <svg viewBox="0 0 20 20">
                                            <path d="M0,0 L20,0 L10,20"/>
                                        </svg>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="title">
                                { data }
                                <svg viewBox="0 0 20 20">
                                    <path d="M0,0 L20,0 L10,20"/>
                                </svg>
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}

export default Pin