import React, { useState } from 'react'
import { CLOUDINARY_URL } from '../../constants'
import './index.scss'

const Image = ({
    src,
    alt,
    width,
    height,
    className = '',
    options,
    round = false,
    onError = () => {
    },
    onLoad = () => {
    },
    fromCloudinary = true
  }) => {
  const [loaded, setLoaded] = useState(false)
  const imageSrc = fromCloudinary ? CLOUDINARY_URL + '/w_30,f_auto/' + src : src
  const imageOptionsSrc = fromCloudinary ? CLOUDINARY_URL + '/' + options + '/' + src : src

  return (
    <>
      <img
        src={imageSrc}
        alt={alt}
        height={height}
        width={width}
        data-round={round}
        onError={(e) => onError(e)}
        className={`${loaded ? 'position-absolute' : 'fadeIn'} ${className}`}/>
      <img
        src={imageOptionsSrc}
        alt={alt}
        height={height}
        width={width}
        data-round={round}
        onLoad={() => {
          onLoad()
          setLoaded(true)
        }}
        onError={(e) => onError(e)}
        className={`${loaded ? 'fadeIn' : 'd-none'} ${className}`}/>
    </>
  )
}

export default Image