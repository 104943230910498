export const gettingStarted = {
    data: [
        {
            title: 'Signup',
            description: 'Connect to our platform with Google or Facebook and go through our quick profile curation process'
        },
        {
            title: 'Create',
            description: 'Write city recommendations and upload photos and video to create visceral, unique content that our airline and hospitality partners will want to license'
        },
        {
            title: 'Publish',
            description: 'Grow your personal brand with exposure to up to 5 million travelers on more than 10 major airlines. Our audience will interact with your content before, during, and after their flights—and your Instagram profile is tied to your recommendations.'
        },
        {
            title: 'Make Money',
            description: 'If we love your content, we’ll buy it to license to our airline and boutique hotel partners',
            listItems: [
                '• Regular RAD contributors earn quarterly for creating 10-15 new recommendations across 2 cities',
                '• Submitting high quality video content will earn you more pay per quarter'
            ]
        }
    ]
}

export const ourBeliefs = [
    {
        title: 'Authenticity',
        caption: 'Our content moves travelers to engage in unique, purposeful experiences—allowing them to immerse themselves in local communities in a way that keeps money local, minimizes their environmental footprint, and avoids cultural commodification.',
    },
    {
        title: 'Impact',
        caption: 'If you could use travel to positively impact local communities, would you? A portion of all commission unlocked on our platform is donated to our partner causes, including Kiva, the world’s largest microfinance organization. Kiva helps people escape the cycle of poverty by supporting local entrepreneurship.',
    },
    {
        title: 'Transformation',
        caption: 'All travel is transformative for the one on their journey—together, we can ensure that travel positively impacts local communities and the environment. Your recommendations will help travelers move through the world with deep respect for and desire to understand other cities, cultures, and customs.',
    },
]

export const OnBoadingAboutGuides = [
    {
        description: 'RAD guides have at least 10 to 12 recommandations (the more, the merrier), and the majority of those recommandations are rich, authentic experiences.'
    },
    {
        description: 'The best guide are grounded by experiences and events that involve doing rather than just seeing (although seeing is cool too — hence our category SEE + DO)'
    },
    {
        description: 'Tell us more. No, really: your descriptions should be fiesched out with at least 3 to 4 sentences'
    },
    {
        description: 'A RAD guide should move people to act in two major ways: by booking experiences and building travel plans around those experiences'
    },
    {
        description: 'Bring your guide to life with vivid photos and interactive video (an experience with at least three photos is 1 000 times more engaging than one with none)'
    },
    {
        description: 'Add category tags that are relevant and specific to engage people who are searching for your content (yes, your content — own it!)'
    }
]

export const HOME_HEADER = {
    title: 'Tell the story of your favorite cities',
    description: 'Turn your travel experiences into shareable content for global travelers. RAD pays you to create city experience recommendations, guides, photos, and videos. Bonus? You get exposure on major airlines around the world.'
}

export const SIGN_UP = {
    title: 'Join the tribe',
    second_title: 'Become an Explorer',
    description: 'Create unique, experiential travel content (recommendations + rich photo and video) that connects our audience to the soul of a destination.'
}