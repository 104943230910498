import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {useDropzone} from 'react-dropzone'
import { withPlace, withAlert } from '../../../hocs'
import { useUploadImage, useIsMobileDevice } from '../../../hooks'
import { DEFAULT_GUIDE_COVER } from '../../../constants'
import Image from '../../../components/Image'
import Loading from '../../../components/LoadingIcon'
import { Button, Input, Editable, DropDown } from '../../../components/theme'
import styles from './index.module.scss'

const Info = ({
    guide,
    onChange,
    placeChange,
    choosePlace,
    save,
    Alert
}) => {
    const [cityName, setCityName] = useState('')
    const [cityError, setCityError] = useState('')
    const [cities, setCities] = useState([])
    const [coverLoading, setCoverLoading] = useState(false)
    const [name, setName] = useState('')
    const [namePreview, setNamePreview] = useState('')
    const [nameError, setNameError] = useState('')
    const [description, setDescription] = useState('')
    const uploadCover = useUploadImage()
    const history = useHistory()
    const isMobileDevice = useIsMobileDevice()

    const onUploadCover = useCallback(
        (acceptedFiles) => {
            setCoverLoading(true)
            uploadCover(acceptedFiles[0])
                .then(image => {
                    const cover = {
                        standard_resolution: {
                            height: 0,
                            width: 0,
                            url: image
                        },
                        thumbnail: {
                            height: 0,
                            width: 0,
                            url: image
                        },
                        low_resolution: {
                            height: 0,
                            width: 0,
                            url: image
                        },
                    }

                    onChange({cover})
                })
                .catch(e => {
                    console.log(e)
                    setCoverLoading(false)
                    Alert({title: e})
                })
        },
        [coverLoading, setCoverLoading, onChange]
    )

    const onCityChange = (value) => {
        setCityName(value)
        setCityError('')
        placeChange(value)
            .then(suggestions => setCities(suggestions))
            .catch(e => {
                setCities([])
                console.log(e.message || e)
            })
    }

    const onChooseCity = (data) => {
        setCityError('')

        choosePlace(data)
            .then(response => {
                setCities([])
                setCityName(response.placeName)
                onChange({...response})
            })
            .catch(e => console.log(e.message || e))
    }

    const checkCity = () => {
        if (!guide.city || !cityName) return false
        if (guide.city.name !== cityName) {
            delete guide.city
            return false
        }
        return true
    }

    const saveData = () => {
        onChange({ name })
        if (!guide.city || !checkCity()) {
            setCityError('Don’t forget to pick your city')
            return
        }
        
        if (!name) {
            setNameError('Don’t forget to pick your guide name')
            return
        }

        save()
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: onUploadCover, multiple: false})

    useEffect(() => {
        setCityName(guide.city.name)
        if (!name) {
            setName(guide.name)
            setNamePreview(guide.name)
        }
        setDescription(guide.description)
    }, [guide])

    return (
        <section className={styles.section}>
            <div className="container">
                <Button
                    type="secondary"
                    width="8rem"
                    onClick={() => history.goBack()}>
                        Back
                </Button>
                <div className="row justify-content-between">
                    <div className={`col-md-6 ${styles.leftSide}`}>
                        <h1 className={styles.pickCity}>
                            Let’s create a guide, for
                            <Editable
                                tag="span"
                                extendStyle={true}
                                editable={true}
                                bordered={true}
                                defaultValue={guide ? guide.city.name : ''}
                                onChange={(value) => onCityChange(value)}
                                placeholder={cityName || "city name"}
                                errorAlignment="center"
                                error={cityError}/>,
                            <DropDown
                                choose={onChooseCity}
                                suggestions={cities}
                                width="100%"
                                onOutsideClick={() => setCities([])}
                                value={cityName}
                                onChange={(value) => onCityChange(value)}/>
                        </h1>
                        <h2 className="mt-1">
                            called
                            <Editable
                                tag="span"
                                placeholder={"guide name" || name}
                                defaultValue={name}
                                extendStyle={true}
                                editable={true}
                                bordered={true}
                                onChange={(value) => {
                                    setNamePreview(value)
                                    setNameError('')
                                }}
                                onBlur={(value) => onChange({name: value})}
                                errorAlignment="center"
                                error={nameError}/>
                        </h2>
                        <h2 className={styles.description}>
                            Guide description <span>(optional)</span>
                        </h2>
                        <Input
                            textarea={true}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={1}
                            placeholder="1 or 2 lines about what will be in this guide"
                            onBlur={() => onChange({description})}/>
                    </div>
                    <div className="col-md-5">
                        <div className={styles.rightSide}  {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Image
                                src={guide.cover && guide.cover.standard_resolution && guide.cover.standard_resolution.url ? guide.cover.standard_resolution.url : DEFAULT_GUIDE_COVER.standard_resolution.url}
                                options="w_800,f_auto"
                                alt={`Guide cover`}
                                onLoad={() => setCoverLoading(false)}
                                onError={() => setCoverLoading(false)}
                                fromCloudinary={false}
                            />
                            {coverLoading && (
                                <Loading
                                    style={{zIndex: 11}}
                                />
                            )}
                            <Button
                                type="secondary"
                                transparent={true}
                                hoverEffect={false}
                                className={styles.uploadCoverButton}
                                width="13rem">
                                    {
                                        coverLoading ? '' : (
                                            <>
                                                {
                                                    isDragActive ?
                                                        'Drop the files here ...'
                                                    :
                                                        `${isMobileDevice ? 'Click to select files' : `Drag 'n' drop some files here, or click to select files`}`
                                                }
                                            </>
                                        )
                                    }
                            </Button>
                            <div onClick={e => e.stopPropagation()}>
                                <Editable
                                    id="right-side-name"
                                    tag="h2"
                                    className="sm"
                                    placeholder={name || "Your Guide Name"}
                                    defaultValue={namePreview}
                                    editable={true}
                                    bordered={true}
                                    onChange={(value) => {
                                        setNameError('')
                                        setName(value)
                                    }}
                                    onBlur={(value) => onChange({name: value})}/>
                                <Input
                                    textarea={true}
                                    rows={1}
                                    placeholder="Enter a description"
                                    value={description}
                                    onBlur={() => onChange({description})}
                                    onChange={(e) => setDescription(e.target.value)}>{description}</Input>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <Button
                                type="primary"
                                borderDark={true}
                                width="8rem"
                                disabled={coverLoading}
                                onClick={saveData}>
                                    Publish
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withAlert(withPlace(Info))